import { combineReducers } from 'redux'
import authReducer from './authReducer'
import campaignReducer from './campaignReducer'
import createCampaignReducer from './createCampaignReducer'
import dashboardReducer from './dashboardReducer'
import reportsReducer from './reportsReducer'
import templateReducer from './templateReducer'
import usersReducer from './usersReducer'
import settingsReducer from './settingsReducer'
import analyticsReducer from './analyticsReducer'
import optoutReducer from './optoutReducer'
import auditReducer from './auditReducer'
import reconcileReducer from './reconcileReducer'
import breadcrumbReducer from './breadCrumbReducer'
import instantCampaignReducer from './instantReducer'
import apiLogsReducer from './logReducer'
import billingReducer from './billingReducer'
import twoWayChatReducer from './twoWayChatReducer'

const allReducers = combineReducers({
  auth: authReducer,
  campaign: campaignReducer,
  createCampaign: createCampaignReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  reports: reportsReducer,
  template: templateReducer,
  settings: settingsReducer,
  analytics: analyticsReducer,
  optout: optoutReducer,
  audit: auditReducer,
  reconcile: reconcileReducer,
  breadcrumbs: breadcrumbReducer,
  instantCampaign: instantCampaignReducer,
  apiLogs: apiLogsReducer,
  billing: billingReducer,
  twoWayChat: twoWayChatReducer,
})

export default allReducers
