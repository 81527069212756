/* eslint-disable no-unused-expressions */
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'
import * as camapignConstants from './actionTypes'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export const getCampaignList = (limit, page, search, filter) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.FETCH_CAMPAIGN_LIST,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/campaign/list/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        limit,
        page,
        search,
        filterBy: filter.filterBy,
        sortOrder: filter.sortValue.sortOrder || 'desc',
        sortBy: filter.sortValue.sortBy || 'modifiedTime',
        // startDate: filter.startDate,
        // endDate: filter.endDate,
      },
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_LIST_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_LIST_FAILURE,
          payload: error,
        })
      })
  }
}

export const getCampaignDetails = (campaignId) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.FETCH_CAMPAIGN_DETAILS,
    })
    request({
      method: 'get',
      url: `${config.API_URL}/api/campaign/${userDetails?.accountId}/${userDetails?.userId}/${campaignId}`,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_DETAILS_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_DETAILS_FAILURE,
          payload: error,
        })
      })
  }
}

export const createCampaign = (campaignPreviewData) => {
  const data = new FormData()
  data.append('campaignName', campaignPreviewData?.name)
  data.append('campaignDescription', campaignPreviewData.campaignDescription)
  data.append('channelName', campaignPreviewData.channel)
  // data.append('uploadFiles', campaignPreviewData.uploadedFile);
  data.append('accountId', userDetails?.accountId)
  data.append('userId', userDetails?.userId)
  data.append('createdBy', userDetails?.emailId)
  data.append('billingCodeId', campaignPreviewData?.billingCodeId)

  return (dispatch) => {
    dispatch({
      type: camapignConstants.CREATE_CAMPAIGN,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/campaign/create`,
      data,
    })
      .then((response) => {
        if (response && response.status === 1) {
          dispatch({
            type: camapignConstants.CREATE_CAMPAIGN_SUCCESS,
            payload: response,
          })
        } else {
          dispatch({
            type: camapignConstants.CREATE_CAMPAIGN_FAILURE,
            payload: response,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: camapignConstants.CREATE_CAMPAIGN_FAILURE,
          payload: err,
        })
      })
  }
}

export const createCampaignNew = (campaignPreviewData) => {
  const data = new FormData()
  data.append('campaignName', campaignPreviewData?.title ?? '')
  data.append('campaignDescription', campaignPreviewData?.description ?? '')
  data.append('channelName', campaignPreviewData.channel ?? null)
  data.append('accountId', userDetails?.accountId ?? null)
  data.append('userId', userDetails?.userId ?? null)
  data.append('createdBy', userDetails?.emailId) ?? null
  data.append('campaignType', campaignPreviewData?.campaignType) ?? null
  data.append('country', campaignPreviewData?.country) ?? null
  data.append('campaignPriority', campaignPreviewData?.priority ?? null)
  data.append('uploadFiles', campaignPreviewData?.file ?? null)

  return (dispatch) => {
    dispatch({
      type: camapignConstants.CREATE_CAMPAIGN_NEW,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/campaign/create`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.CREATE_CAMPAIGN_SUCCESS_NEW,
          payload: response,
        })
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 1000,
          text: 'Campaign added Successfully',
        })
        dispatch({
          type: camapignConstants.CREATE_CAMPAIGN_FAILURE_NEW,
          payload: response,
        })
      })
      .catch((err) => {
        dispatch({
          type: camapignConstants.CREATE_CAMPAIGN_FAILURE_NEW,
          payload: err,
        })
      })
  }
}

export const getFilteredMessages = (userId, batchFileId, filterBy) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.GET_FILTERED_MESSAGES,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/messages`,
      params: {
        userId,
        batchFileId,
        filterBy,
      },
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.GET_FILTERED_MESSAGES_SUCCESS,
          payload: response.data.map((message) => {
            return { ...message, ...message.decodedMessage }
          }),
        })
      })
      .catch(() => {
        dispatch({
          type: camapignConstants.GET_FILTERED_MESSAGES_FAILURE,
          payload: [],
        })
      })
  }
}

export const updateCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.UPDATE_CAMPAIGN,
    })
    request({
      method: 'post',
      url: `${config.API_URL}/uploadfile`,
      data,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.UPDATE_CAMPAIGN_SUCCESS,
          payload: response.data,
        })
      })
      .catch(() => {
        dispatch({
          type: camapignConstants.UPDATE_CAMPAIGN_FAILURE,
        })
      })
  }
}

export const cancelCampaign = (fileId) => {
  const data = { fileId, cancelledBy: userDetails?.emailId }

  return (dispatch) => {
    dispatch({
      type: camapignConstants.CANCEL_CAMPAIGN,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/campaign/cancel/${userDetails?.accountId}/${userDetails?.userId}`,
      data,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.CANCEL_CAMPAIGN_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.CANCEL_CAMPAIGN_FAILURE,
          payload: error,
        })
      })
  }
}

export const fetchCampaignSourceFile = (fileName, originalFileName) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.FETCH_CAMPAIGN_SOURCEFILE,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/file/download/${userDetails.accountId}/${userDetails.userId}`,
      params: {
        fileName,
        originalFileName,
      },
    })
      .then((response) => {
        const blob = new Blob([response], { type: 'text/dat' })
        saveAs(blob, originalFileName)
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_SOURCEFILE_FAILURE,
          payload: error,
        })
      })
  }
}

export const uploadFile = (uploadFileData) => {
  const data = new FormData()
  data.append('channelName', uploadFileData?.channel)
  data.append('uploadFiles', uploadFileData.uploadedFile)
  data.append('accountId', userDetails?.accountId)
  data.append('createdBy', userDetails?.emailId)

  return (dispatch) => {
    dispatch({
      type: camapignConstants.UPLOAD_FILE,
    })
    request({
      method: 'post',
      url: `${config.API_URL}/api/file/upload`,
      data,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.UPLOAD_FILE_SUCCESS,
          payload: response,
        })
      })
      .catch((err) => {
        dispatch({
          type: camapignConstants.UPLOAD_FILE_FAILURE,
          payload: err,
        })
      })
  }
}

export const getCampaignFilesList = (filter, search, limit, page) => {
  console.log(filter)
  return (dispatch) => {
    dispatch({
      type: camapignConstants.FETCH_CAMPAIGN_FILES_LIST,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/file/list/${userDetails?.accountId}/${userDetails?.userId}/${filter.campaignId}`,
      params: {
        limit,
        page,
        search,
        filterBy: filter.filterBy,
        startDate: filter.startDate,
        endDate: filter.endDate,
        sortOrder: filter.sortValue.sortOrder || 'desc',
        sortBy: filter.sortValue.sortBy || 'reportGeneratedTime',
      },
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_FILES_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.FETCH_CAMPAIGN_FILES_FAILURE,
          payload: error,
        })
      })
  }
}

export const getFileDetails = (fileId) => {
  return (dispatch) => {
    dispatch({
      type: camapignConstants.FETCH_FILE_DETAILS,
    })
    request({
      method: 'get',
      url: `${config.API_URL}/api/file/${userDetails?.accountId}/${userDetails?.userId}/${fileId}`,
    })
      .then((response) => {
        dispatch({
          type: camapignConstants.FETCH_FILE_DETAILS_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: camapignConstants.FETCH_FILE_DETAILS_FAILURE,
          payload: error,
        })
      })
  }
}
