import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import PropTypes from 'prop-types'
import appConfig from '../config/appConfig'

const ButtonComponent = ({
  filters,
  onClick,
  activeFilter,
  height = '35px',
}) => {
  const buttons = filters.map((filter) => {
    const filteredValue =
      typeof filter === 'string'
        ? filter.toLowerCase().charAt(0).toUpperCase() + filter.slice(1)
        : filter
    return (
      <Button
        sx={{
          textTransform: 'none',
          border: '1px solid lightgray',
          height,
          marginTop: '2px',
          fontFamily: appConfig.fontfamily,
          backgroundColor:
            activeFilter === filter ? appConfig.colors.darkblue : 'white',
          color: activeFilter === filter ? 'white' : 'black',
          '&:hover': {
            backgroundColor: appConfig.colors.darkblue,
            color: 'white',
          },
          ['@media (max-width:545px)']: {
            // eslint-disable-line no-useless-computed-key
            minWidth: '4.3rem !important',
          },
        }}
        key={filter}
        onClick={onClick}
      >
        {filteredValue}
      </Button>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup aria-label="medium button group">{buttons}</ButtonGroup>
    </Box>
  )
}

ButtonComponent.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}
export default ButtonComponent
