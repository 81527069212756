import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, IconButton } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Swal from 'sweetalert2'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import KPICard from '../components/KPICard'
import * as campaignActions from '../store/actions/campaign'

import SpinningLoader from '../components/SpinningLoader'
import appConfig from '../config/appConfig'

ChartJS.register(ArcElement, Tooltip, Legend)

const FileSnapshot = ({
  id,
  campaignDetailsData,
  cancelCampaign,
  getFileDetails,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(
    campaignDetailsData?.fileDetails
  )
  const [isCampaignCancelled, setIsCampaignCancelled] = useState(false)
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  console.log(campaignDetailsData?.fileDetails?.data)
  const data = {
    labels:
      campaignDetailsData?.fileDetails?.data?.templateCode &&
      Object.keys(campaignDetailsData?.fileDetails?.data?.templateCode).map(
        (item) => {
          if (item === 'null') {
            return 'No Template'
          }
          return item
        }
      ),
    datasets: [
      {
        data:
          campaignDetailsData?.fileDetails?.data?.templateCode &&
          Object.values(campaignDetailsData?.fileDetails?.data?.templateCode),
        backgroundColor: [
          '#AF7AC5',
          '#5499C7',
          '#48C9B0',
          '#F4D03F',
          '#6495ED',
          '#DE3163',
          '#DFFF00',
          '#9FE2BF',
          '#79B696',
          '#3BADAA',
          '#6475B9',
          '#854286',
        ],
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        position: 'right',
        padding: 5,
        textAlign: 'center',
        onClick: (e) => e.stopPropagation(),
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset.data
            const total = dataset.reduce((previousValue, currentValue) => {
              return previousValue + currentValue
            })
            const currentValue = dataset[tooltipItem.dataIndex]
            const percentage = Math.floor((currentValue / total) * 100 + 0.5)
            return `${tooltipItem.label}: ${percentage} %`
          },
        },
      },
      datalabels: {
        display: true,
        color: 'black',
        align: 'end',
        labels: {
          value: {
            color: '#000',
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  useEffect(() => {
    setSelectedCampaign(campaignDetailsData?.fileDetails)
  }, [JSON.stringify(campaignDetailsData?.fileDetails)])

  useEffect(() => {
    getFileDetails(id)
  }, [id])

  const handleCancelCampaign = (fileId) => {
    setIsCampaignCancelled(true)
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    })

    swalWithCustompButtons
      .fire({
        title: '',
        text: 'Cancel this broadcast?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'OK',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          cancelCampaign(fileId)
        } else {
          setIsCampaignCancelled(false)
        }
      })
  }

  useEffect(() => {
    if (
      campaignDetailsData?.cancelCampaign?.data?.status === 1 &&
      isCampaignCancelled
    ) {
      setIsCampaignCancelled(false)
      Swal.fire({
        icon: 'success',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: campaignDetailsData?.cancelCampaign?.data?.message,
      })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }, [JSON.stringify(campaignDetailsData.cancelCampaign.data)])

  return (
    <div>
      {campaignDetailsData?.cancelCampaign?.isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={campaignDetailsData?.cancelCampaign?.isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.darkblue}
            label="Loading"
          />
        </Backdrop>
      ) : null}
      {campaignDetailsData?.fileDetails?.isLoading ? (
        <div style={{ marginTop: '20px' }}>
          <SpinningLoader circleColor={appConfig.colors.darkblue} />
        </div>
      ) : (
        <div className="pad-10">
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} />
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              {userDetails?.authorities.includes('CAMCAN') ? (
                <div className="cancel-campaign-btn">
                  <IconButton
                    disabled={
                      campaignDetailsData?.fileDetails &&
                      !campaignDetailsData?.fileDetails?.data?.isCancelAllowed
                    }
                    onClick={() => {
                      handleCancelCampaign(
                        campaignDetailsData?.fileDetails?.data?.fileId
                      )
                    }}
                    title="Cancel Broadcast"
                    aria-label="Cancel"
                    size="small"
                  >
                    <span
                      style={{
                        marginLeft: '10px',
                        fontSize: '13px',
                        color:
                          campaignDetailsData?.fileDetails &&
                          campaignDetailsData?.fileDetails?.data
                            ?.isCancelAllowed
                            ? '#ED3B40'
                            : '#bbb',
                      }}
                    >
                      <b>Cancel Broadcast</b>
                    </span>
                  </IconButton>
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              xl={7}
              lg={7}
              md={7}
              style={{ verticalAlign: 'top' }}
            >
              <h4>Message Statistics</h4>
              <div>
                <KPICard
                  label="Total Count"
                  type="number"
                  value={selectedCampaign?.data?.messages?.totalCount}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Pending"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Pending || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Sent"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Sent || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
              </div>
              <div>
                <KPICard
                  label="Delivered"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Delivered || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Undelivered"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Undelivered || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Failed"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Failed || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
              </div>
              <div>
                <KPICard
                  label="Invalid"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Invalid || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Queued"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Queued || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
                <KPICard
                  label="Cancelled"
                  type="number"
                  value={selectedCampaign?.data?.messages?.Cancelled || 0}
                  padding="10px"
                  width="150px"
                  height="100px"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} xl={5} lg={5} md={5}>
              <h4>By Template Code</h4>
              <div
                className="snapshot-template-container"
                style={{ marginTop: '-30px' }}
              >
                {selectedCampaign?.data?.messages?.totalCount ? (
                  <Pie data={data} options={options} />
                ) : (
                  <div className="blk-center">
                    {' '}
                    <DonutLargeIcon
                      aria-label="campaign pie chart"
                      className="txt-grey"
                      sx={{ fontSize: 100 }}
                    />
                    <h4 className="txt-grey">No Data</h4>{' '}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ campaignDetailsData: state.campaign })

const mapDispatchToProps = (dispatch) => {
  return {
    getFileDetails: (id) => dispatch(campaignActions.getFileDetails(id)),
    cancelCampaign: (fileId) =>
      dispatch(campaignActions.cancelCampaign(fileId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileSnapshot)

FileSnapshot.propTypes = {
  campaignDetailsData: PropTypes.symbol.isRequired,
  getFileDetails: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  cancelCampaign: PropTypes.func.isRequired,
}
