import React from 'react'
import { Badge, Avatar } from '@mui/material';

const AvatarIcon = ({ user }) => {
  return (
    <div>
      {/* <Badge
        color="success"
        variant="dot"
        // invisible={!user?.online}
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      > */}
        <Avatar alt="user_img" />
      {/* </Badge> */}
    </div>
  );
}

export default AvatarIcon;