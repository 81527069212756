import * as actionTypes from '../actions/actionTypes'

const initialState = {
  campaigns: {
    campaigns: [],
    count: '',
    isLoading: false,
    isError: false,
  },
  campaignDetails: [{}],
  fileDetails: {
    isLoading: false,
    isError: false,
    data: {},
  },
  filteredMessages: {
    isLoading: false,
    isError: false,
    messages: [],
  },
  createCampaignData: {},
  updateCampaignData: {
    status: false,
  },
  isLoading: false,
  isError: false,
  cancelCampaign: {
    data: {},
    isLoading: false,
    isError: false,
  },
  uploadFile: {
    isLoading: false,
    isError: false,
    data: {},
  },
  campaignFiles: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
}

const campaignReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGN_DETAILS:
      return { ...newState, isLoading: true }

    case actionTypes.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...newState,
        isLoading: false,
        campaignDetails: { ...action.payload },
      }

    case actionTypes.FETCH_CAMPAIGN_DETAILS_FAILURE:
      return {
        ...newState,
        isLoading: false,
        campaignDetails: { ...newState.campaignDetails },
        isError: true,
      }

    case actionTypes.FETCH_CAMPAIGN_LIST:
      return {
        ...newState,
        campaigns: { ...newState.campaigns, isLoading: true },
      }

    case actionTypes.FETCH_CAMPAIGN_LIST_SUCCESS:
      return {
        ...newState,
        campaigns: {
          campaigns: [...action.payload.data],
          count: action.payload.totalCount,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_CAMPAIGN_LIST_FAILURE:
      return {
        ...newState,
        campaigns: {
          count: action.payload.totalCount,
          isLoading: false,
          isError: true,
        },
      }

    // case actionTypes.CREATE_CAMPAIGN:
    //   return { ...newState, isLoading: true };

    // case actionTypes.CREATE_CAMPAIGN_SUCCESS:
    //   return { ...newState, isLoading: false, createCampaignData: { ...action.payload } };

    // case actionTypes.CREATE_CAMPAIGN_FAILURE:
    //   return { ...newState, isLoading: false, isError: true };

    case actionTypes.GET_FILTERED_MESSAGES:
      return {
        ...newState,
        filteredMessages: { ...newState.filteredMessages, isLoading: true },
      }

    case actionTypes.GET_FILTERED_MESSAGES_SUCCESS:
      return {
        ...newState,
        filteredMessages: {
          ...newState.filteredMessages,
          isLoading: false,
          isError: false,
          messages: [...action.payload],
        },
      }

    case actionTypes.GET_FILTERED_MESSAGES_FAILURE:
      return {
        ...newState,
        filteredMessages: {
          ...newState.filteredMessages,
          isLoading: false,
          isError: true,
          messages: [...action.payload],
        },
      }

    case actionTypes.UPDATE_CAMPAIGN:
      return {
        ...newState,
        isLoading: true,
        updateCampaignData: { status: false },
      }

    case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...newState,
        isLoading: false,
        updateCampaignData: { ...action.payload },
      }

    case actionTypes.UPDATE_CAMPAIGN_FAILURE:
      return { ...newState, isLoading: false, isError: true }

    case actionTypes.CANCEL_CAMPAIGN:
      return {
        ...newState,
        cancelCampaign: { isLoading: true, isError: false },
      }

    case actionTypes.CANCEL_CAMPAIGN_SUCCESS:
      return {
        ...newState,
        cancelCampaign: {
          isLoading: false,
          isError: false,
          data: { ...action.payload },
        },
      }

    case actionTypes.CANCEL_CAMPAIGN_FAILURE:
      return {
        ...newState,
        cancelCampaign: {
          isLoading: false,
          isError: true,
          data: { ...action.payload },
        },
      }

    case actionTypes.UPLOAD_FILE:
      return { ...newState, uploadFile: { isLoading: true, isError: false } }

    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...newState,
        uploadFile: {
          isLoading: false,
          isError: false,
          data: { ...action.payload },
        },
      }

    case actionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...newState,
        uploadFile: {
          isLoading: false,
          isError: true,
          data: { ...action.payload },
        },
      }

    case actionTypes.FETCH_CAMPAIGN_FILES_LIST:
      return {
        ...newState,
        campaignFiles: { ...newState.campaigns, isLoading: true },
      }

    case actionTypes.FETCH_CAMPAIGN_FILES_SUCCESS:
      return {
        ...newState,
        campaignFiles: {
          files: [...action.payload.filesDetails],
          count: action.payload.totalCount,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_CAMPAIGN_FILES_FAILURE:
      return { ...newState, campaignFiles: { isLoading: false, isError: true } }

    case actionTypes.FETCH_FILE_DETAILS:
      return { ...newState, fileDetails: { isLoading: true } }

    case actionTypes.FETCH_FILE_DETAILS_SUCCESS:
      return {
        ...newState,
        fileDetails: { data: action.payload, isLoading: false, isError: false },
      }

    case actionTypes.FETCH_FILE_DETAILS_FAILURE:
      return {
        ...newState,
        fileDetails: {
          ...newState.fileDetails,
          isLoading: false,
          isError: true,
        },
      }

    default:
      return { ...newState }
  }
}

export default campaignReducer
