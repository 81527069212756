import * as reconcileConstants from './actionTypes'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export function fetchReconcileList(data, search, limit, page) {
  return (dispatch) => {
    dispatch({
      type: reconcileConstants.FETCH_RECONCILE_LIST,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/campaign/reconciliation/list/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        filterBy: data.filterBy,
        startDate: data.startDate,
        endDate: data.endDate,
        search,
        limit,
        page,
      },
    })
      .then((response) => {
        dispatch({
          type: reconcileConstants.FETCH_RECONCILE_LIST_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: reconcileConstants.FETCH_RECONCILE_LIST_FAILURE,
          payload: error,
        })
      })
  }
}

export function fetchReconcileStatus(fileId, channel) {
  const data = new FormData()
  data.append('fileId', fileId)
  data.append('channel', channel)

  return (dispatch) => {
    dispatch({
      type: reconcileConstants.FETCH_RECONCILE_STATUS,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/file/reconcileStatus/`,
      data,
    })
      .then((response) => {
        dispatch({
          type: reconcileConstants.FETCH_RECONCILE_STATUS_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: reconcileConstants.FETCH_RECONCILE_STATUS_FAILURE,
          payload: error,
        })
      })
  }
}

export function regenerateFile(fileId, channel) {
  const data = new FormData()
  data.append('fileId', fileId)
  data.append('channel', channel)

  return (dispatch) => {
    dispatch({
      type: reconcileConstants.REGENERATE_STATUS,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/file/regenerateReport/`,
      data,
    })
      .then((response) => {
        dispatch({
          type: reconcileConstants.REGENERATE_STATUS_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: reconcileConstants.REGENERATE_STATUS_FAILURE,
          payload: error,
        })
      })
  }
}
