/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Link as MaterialLink,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material'
import { useMsal } from '@azure/msal-react'
import { connect } from 'react-redux'
import styles from './index.module.css'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import Loginimage from '../../assets/Group.svg'
import { ReactComponent as Mobile } from '../../assets/mobile.svg'
import { ReactComponent as Laptop } from '../../assets/laptop.svg'
import { ReactComponent as Language } from '../../assets/language.svg'
import { ReactComponent as Twoway } from '../../assets/twoway.svg'
import { ReactComponent as Cloud } from '../../assets/cloud.svg'
import { ReactComponent as Message } from '../../assets/message.svg'
import { ReactComponent as Seamless } from '../../assets/seamlass.svg'
import { ReactComponent as Scalable } from '../../assets/scalable.svg'
import { ReactComponent as Schedule } from '../../assets/schedule.svg'
import { ReactComponent as AI } from '../../assets/ai.svg'
import { useNavigate } from 'react-router-dom'

// Azure

import * as signInActions from '../../store/actions/auth'

// Azure Config

import { loginRequest } from '../../config/config'
import { setCookie, getCookie } from '../../components/Helper/cookies'
import { useForm } from 'react-hook-form'
import CoTextField from '../../controllers/CoTextField'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const SignIn = ({ authDetails, azureLogOut, getTenantInfo, getClientInfo }) => {
  const { instance } = useMsal()
  const [userStatus, setUserStatus] = useState({
    isLoginError: false,
    isUserResetPassword: false,
  })
  const [checked, setChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const clearCookies = () => {
    setCookie('isLoginFailed', '')
    setCookie('isUserResetPassword', '')
    setUserStatus({
      isLoginError: false,
      isUserSignUp: false,
      isUserResetPassword: false,
    })
  }

  const routeUser = (preSignInData) => {
    if (preSignInData.isSuperAdmin) {
      // window.location.href = '/tenantselection';
      navigate('/tenantselection')
    } else {
      if ((instance, preSignInData?.tenant)) {
        getClientInfo(instance)
      }
    }
  }

  useEffect(() => {
    if (getCookie('isLoginFailed') === 'true') {
      setUserStatus({ ...userStatus, isLoginError: true })
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false,
      })
      swalWithCustompButtons
        .fire({
          title: '',
          text: 'Authentication (login or password) has failed. Please try again',
          showCancelButton: false,
          confirmButtonText: 'Ok',
        })
        .then((result) => {
          if (result.isConfirmed) {
            clearCookies()
          }
        })
    }
    if (getCookie('isUserResetPassword') === 'true') {
      setUserStatus({ ...userStatus, isUserResetPassword: true })
    }

    if (window.sessionStorage.getItem('isLogout') === 'true') {
      sessionStorage.clear()
      localStorage.clear()
    }

    instance.addEventCallback(
      async (event) => {
        if (
          event.eventType === 'msal:loginSuccess' ||
          event.eventType === 'msal:acquireTokenSuccess'
        ) {
          if (event?.payload?.idTokenClaims?.isForgotPassword) {
            setCookie('isUserResetPassword', true)
            azureLogOut(instance)
          }
          console.log(event.eventType)

          const myAccount = instance.getAccountByHomeId(
            event.payload.account.homeAccountId
          )
          instance.setActiveAccount(myAccount)
          window.sessionStorage.setItem(
            'home_account',
            JSON.stringify(myAccount)
          )
          window.sessionStorage.setItem(
            'user_name',
            event.payload.account.idTokenClaims.preferred_username
          )
          window.sessionStorage.setItem('id_token', event.payload.idToken)
          const oauth = {
            token_type: 'Bearer',
            access_token: event.payload.idToken,
          }
          window.sessionStorage.setItem('oauthToken', JSON.stringify(oauth))
          //  getClientInfo(instance);
          getTenantInfo(instance)
        }
        if (
          event.eventType === 'msal:loginFailure' ||
          event.eventType === 'msal:acquireTokenFailure'
        ) {
          azureLogOut(instance)
        }
      },
      (error) => {
        console.log('error', error)
      }
    )
  }, [])

  useEffect(() => {
    if (authDetails.isLoginSuccessfull) {
      navigate('/dashboard')
    }
  }, [JSON.stringify(authDetails.loginDetails)])

  useEffect(() => {
    const { preSignIn } = authDetails
    // console.log("use Effect",preSignIn);
    console.log('use Effect auth', authDetails)

    if (authDetails.isPreLoginSuccessful) {
      routeUser(preSignIn)
    }
  }, [JSON.stringify(authDetails.preSignIn)])

  const handleAzureLogin = (msalIns) => {
    clearCookies()
    msalIns
      .loginRedirect(loginRequest)
      .then()
      .catch((e) => {
        console.error(e)
      })
  }

  const byPassLogin = (msalIns) => {
    const ha = {
      homeAccountId:
        '659ea999-fd94-42b1-ad41-bfb67fdd257b.3e861d16-48b7-4a0e-9806-8c04d81b7b2a',
      environment: 'login.windows.net',
      tenantId: '3e861d16-48b7-4a0e-9806-8c04d81b7b2a',
      username: 'Aravinth.Sridharan@kyyba.gov',
      localAccountId: '659ea999-fd94-42b1-ad41-bfb67fdd257b',
      name: 'Sridharan, Aravinth (DTA)',
      idTokenClaims: {
        aud: '44327953-b778-4885-b8bc-e94aa9014baa',
        iss: 'https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a/v2.0',
        iat: 1692168378,
        nbf: 1692168378,
        exp: 1692172278,
        aio: 'AWQAm/8UAAAASNQwJAYGILK+xCo9VVa0f/GzhS5HcyHRH0dT8T0/1gJHTIb8eVRo1igqz/XTC7h6/64CZ9Og8rwyz780EaxQh9tr0X5n9NUSxYAyfzQpLk++1B6KZvPUMEBcBwU/QZL5',
        email: 'Aravinth.Sridharan@kyyba.gov',
        name: 'Sridharan, Aravinth (DTA)',
        nonce: '2fabccb9-06b1-4670-aea8-129282e593bc',
        oid: '659ea999-fd94-42b1-ad41-bfb67fdd257b',
        preferred_username: 'Aravinth.Sridharan@kyyba.gov',
        rh: '0.ARwAFh2GPrdIDkqYBowE2Bt7KlN5MkR4t4VIuLzpSqkBS6ocABc.',
        sub: 'naDq_UiLoNjMTRjDoIBQPdYY0gTHSg0CdqjceZCtSQY',
        tid: '3e861d16-48b7-4a0e-9806-8c04d81b7b2a',
        uti: '_4W8cBWvAUiqpNNxyHkDAQ',
        ver: '2.0',
        firstname: 'Aravinth',
        lastname: 'Sridharan',
      },
    }
    const userInfo = {
      id: 0,
      userId: 139,
      emailId: 'Aravinth.Sridharan@kyyba.gov',
      firstName: 'aravinth',
      lastName: 'sridharan',
      statusDetails: { id: 702, status: 'Verified', description: null },
      accountId: 1,
      role: 'NMSADN',
      authorities: [
        'DSHBRD',
        'CAMVEW',
        'CAMCRE',
        'CAMCAN',
        'TEMVEW',
        'TEMUPT',
        'SETVEW',
        'SETUPT',
        'USRVEW',
        'USRCRE',
        'USRDEL',
        'OPTACS',
        'LOGACS',
        'RPTACS',
        'RECACS',
        'INVACS',
        'TEMDEL',
      ],
    }
    const token = {
      token_type: 'Bearer',
      access_token:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI0NDMyNzk1My1iNzc4LTQ4ODUtYjhiYy1lOTRhYTkwMTRiYWEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE3MDIzMDczNjAsIm5iZiI6MTcwMjMwNzM2MCwiZXhwIjoxNzAyMzExMjYwLCJhaW8iOiJBV1FBbS84VkFBQUEzOUNhVmExdHhibzFHODVucE91Nk1pbE8rMHpQWWFlang4THU3ZjJodU5ndUkzNGtYS0hVa2JjQnlmRGVsdGpJdVY5dVVtaGdWNERWZ1I0UE9kbVhHSjZKdHlIdUdUMUliZDhnaGVpMkJXaUNzL0wyTERjbGRBRlpzSjU2bmgyYyIsImVtYWlsIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwibmFtZSI6IlNyaWRoYXJhbiwgQXJhdmludGggKERUQSkiLCJub25jZSI6ImUzMmFmNDkwLWZmMTktNGU1My05MzU5LTNiNGJhMzg2MzhhYyIsIm9pZCI6IjY1OWVhOTk5LWZkOTQtNDJiMS1hZDQxLWJmYjY3ZmRkMjU3YiIsInByZWZlcnJlZF91c2VybmFtZSI6IkFyYXZpbnRoLlNyaWRoYXJhbkBtYXNzLmdvdiIsInJoIjoiMC5BUndBRmgyR1ByZElEa3FZQm93RTJCdDdLbE41TWtSNHQ0Vkl1THpwU3FrQlM2b2NBQmMuIiwic3ViIjoibmFEcV9VaUxvTmpNVFJqRG9JQlFQZFlZMGdUSFNnMENkcWpjZVpDdFNRWSIsInRpZCI6IjNlODYxZDE2LTQ4YjctNGEwZS05ODA2LThjMDRkODFiN2IyYSIsInV0aSI6ImxnMWRNR3o3RkVLRWZfT3BBLTZBQUEiLCJ2ZXIiOiIyLjAiLCJmaXJzdG5hbWUiOiJBcmF2aW50aCIsImxhc3RuYW1lIjoiU3JpZGhhcmFuIn0.aOvsVwmLJghsOdWu8GqYSZRvmYXL3PF7qYkOVqVJZmxw63OTr5ryGDMA920nEAymcBmb_yEhMxZNLqSyll_UAxrOwL140dQ1PPlw08D1eBQila63Ht4dcco5SDfddxV1yBXy5qhCjTNZvzNyXEJ1xjS6CBpPqcwQBtLVIXFezLr5N4aWLrbs8Kcq1pQoSEOy8TL-T0way0p-LwVIQeQcyc1M2woBVWZUwPSUjkrLCHQ1b4sGx_0OmbM1vo9toW3A5yQKh-MQeGCrIkvNLkVtUFx-DQ1Fq3spHnBR-8_u2uKjASxWvk8_QYnZ5zzcOSFnfcL3OHHo4zdNUMuBAH81CQ',
    }
    window.sessionStorage.setItem('user_name', 'Aravinth.Sridharan@kyyba.gov')
    window.sessionStorage.setItem('home_account', JSON.stringify(ha))
    window.sessionStorage.setItem('oauthToken', JSON.stringify(token))
    window.sessionStorage.setItem(
      'id_token',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI0NDMyNzk1My1iNzc4LTQ4ODUtYjhiYy1lOTRhYTkwMTRiYWEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2U4NjFkMTYtNDhiNy00YTBlLTk4MDYtOGMwNGQ4MWI3YjJhL3YyLjAiLCJpYXQiOjE3MDIzMDczNjAsIm5iZiI6MTcwMjMwNzM2MCwiZXhwIjoxNzAyMzExMjYwLCJhaW8iOiJBV1FBbS84VkFBQUEzOUNhVmExdHhibzFHODVucE91Nk1pbE8rMHpQWWFlang4THU3ZjJodU5ndUkzNGtYS0hVa2JjQnlmRGVsdGpJdVY5dVVtaGdWNERWZ1I0UE9kbVhHSjZKdHlIdUdUMUliZDhnaGVpMkJXaUNzL0wyTERjbGRBRlpzSjU2bmgyYyIsImVtYWlsIjoiQXJhdmludGguU3JpZGhhcmFuQG1hc3MuZ292IiwibmFtZSI6IlNyaWRoYXJhbiwgQXJhdmludGggKERUQSkiLCJub25jZSI6ImUzMmFmNDkwLWZmMTktNGU1My05MzU5LTNiNGJhMzg2MzhhYyIsIm9pZCI6IjY1OWVhOTk5LWZkOTQtNDJiMS1hZDQxLWJmYjY3ZmRkMjU3YiIsInByZWZlcnJlZF91c2VybmFtZSI6IkFyYXZpbnRoLlNyaWRoYXJhbkBtYXNzLmdvdiIsInJoIjoiMC5BUndBRmgyR1ByZElEa3FZQm93RTJCdDdLbE41TWtSNHQ0Vkl1THpwU3FrQlM2b2NBQmMuIiwic3ViIjoibmFEcV9VaUxvTmpNVFJqRG9JQlFQZFlZMGdUSFNnMENkcWpjZVpDdFNRWSIsInRpZCI6IjNlODYxZDE2LTQ4YjctNGEwZS05ODA2LThjMDRkODFiN2IyYSIsInV0aSI6ImxnMWRNR3o3RkVLRWZfT3BBLTZBQUEiLCJ2ZXIiOiIyLjAiLCJmaXJzdG5hbWUiOiJBcmF2aW50aCIsImxhc3RuYW1lIjoiU3JpZGhhcmFuIn0.aOvsVwmLJghsOdWu8GqYSZRvmYXL3PF7qYkOVqVJZmxw63OTr5ryGDMA920nEAymcBmb_yEhMxZNLqSyll_UAxrOwL140dQ1PPlw08D1eBQila63Ht4dcco5SDfddxV1yBXy5qhCjTNZvzNyXEJ1xjS6CBpPqcwQBtLVIXFezLr5N4aWLrbs8Kcq1pQoSEOy8TL-T0way0p-LwVIQeQcyc1M2woBVWZUwPSUjkrLCHQ1b4sGx_0OmbM1vo9toW3A5yQKh-MQeGCrIkvNLkVtUFx-DQ1Fq3spHnBR-8_u2uKjASxWvk8_QYnZ5zzcOSFnfcL3OHHo4zdNUMuBAH81CQ'
    )
    getTenantInfo(msalIns)
  }

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  const {
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  return (
    <>
      <Box>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background: `url(${Loginimage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            backgroundPosition: 'center',
            backgroundColor: '#003B65',
            padding: { xs: '1rem', sm: '2rem' },
            display: 'flex',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Paper
            sx={{
              padding: 2,
              width: 500,
              paddingBottom: 5,
              boxShadow: 3,
              height: 'fit-content',
            }}
          >
            <Grid item xs={12} textAlign="center">
              <Typography variant="h5" className={styles.label}>
                Login to Account
              </Typography>
            </Grid>

            <Grid item xs={12} mt="2rem">
              <Typography variant="h6" className={styles.label}>
                Email or Phone
              </Typography>
              <CoTextField
                name="email"
                control={control}
                placeholder="Email or Phone*"
                sx={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={12} mt="2rem">
              <Typography variant="h6" className={styles.label}>
                Password
              </Typography>
              <CoTextField
                name="pass"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                sx={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
              />
            </Grid>

            <Grid item xs={12} mt="2rem">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  control={<Checkbox name="rememberMe" control={control} />}
                  label="Remember me"
                />
                <Typography className={styles.forgetPassword}>
                  forgot Password?
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} mt="1rem">
              <Button
                variant="contained"
                onClick={() => {
                  byPassLogin(instance)
                }}
                sx={{
                  width: '100%',
                  padding: '15px',
                  background: '#004873',
                  '&:hover': {
                    backgroundColor: '#003e73',
                  },
                  '&:active': {
                    backgroundColor: '#003e73',
                  },
                  fontWeight: 600,
                  fontSize: '18px',
                  textTransform: 'capitalize',
                }}
              >
                Login
              </Button>
            </Grid>
          </Paper>
        </Grid>

        <Box className={styles.secondframe}>
          <Box
            mt={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" className={styles.start}>
              Start your Communication Now
            </Typography>
            <Typography variant="h5" className={styles.notification}>
              A Mass Notification Platform that supports Voice, Text and Email
              Campaigns
            </Typography>
          </Box>
          <Grid container className={styles.gridContainer}>
            <Grid item className={styles.gridItem}>
              <Mobile />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Less than $0.01 per Message
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Laptop />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Business User Friendly Design
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Twoway />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Two-Way Text Messaging
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Cloud />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Secure Cloud Based Solution
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Message />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Personalized Messages
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={styles.gridContainer}>
            <Grid item className={styles.gridItem}>
              <Seamless />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Seamless Integration
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Schedule />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Instant & Scheduled Communication
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <AI />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                AI Driven Reports
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Scalable />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Scalable Solution
              </Typography>
            </Grid>
            <Grid item className={styles.gridItem}>
              <Language />
              <Typography
                variant="subtitle2"
                sx={{ width: '150px', textAlign: 'center' }}
              >
                Multi-Language Support
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({ authDetails: state.auth })

const mapDispatchToProps = (dispatch) => {
  return {
    azureLogOut: (instance) => dispatch(signInActions.azureLogOut(instance)),
    getClientInfo: (msal) => dispatch(signInActions.getClientInfo(msal)),
    getTenantInfo: (msal) => dispatch(signInActions.getTenantDetails(msal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

SignIn.propTypes = {
  azureLogOut: PropTypes.func.isRequired,
  getClientInfo: PropTypes.func.isRequired,
  authDetails: PropTypes.arrayOf(PropTypes.array).isRequired,
  getTenantInfo: PropTypes.func.isRequired,
}
