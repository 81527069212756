import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../Layout/Header';
import SideMenu from '../../Layout/SideMenu';
import styles from './index.module.css';
import LeftNav from '../../Layout/LeftNav';
import TopNav from '../../Layout/TopNav';

const Layout = () => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        <div className={styles.leftSection}>
          {/* <SideMenu /> */}
          <LeftNav openNav={openNav} setOpenNav={setOpenNav} />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.topNav}>
            {/* <Header /> */}
            <TopNav setOpenNav={setOpenNav} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
