/* eslint-disable no-unused-expressions */
import React from 'react'
import * as scheduleConstants from './actionTypes'
import Swal from 'sweetalert2'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'
import dayjs from 'dayjs'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

const scheduleCampaign = (schData, watchOfPossible, campaignId) => {
  const date = schData?.date ? dayjs(schData.date).format('YYYY-MM-DD') : null
  const time = watchOfPossible
    ? dayjs().format('HH:mm')
    : schData?.hours && schData?.minutes && schData?.timeFormat
    ? dayjs(`${schData?.hours}:${schData?.minutes} ${schData?.timeFormat}`, [
        'h:m a',
      ]).format('HH:mm')
    : null
  const scheduleDate = watchOfPossible
    ? `${dayjs().format('YYYY-MM-DD')} ${time}`
    : `${date} ${time}`

  const data = {
    scheduleType: schData?.onetimeRecurring ?? '',
    scheduleDate: scheduleDate,
    campaignId: campaignId ?? null,
    createdBy: userDetails?.emailId ?? null,
    modifiedBy: userDetails?.emailId ?? null,
    isActive: true,
    templateCode: schData?.templateCode,
    isInstant: watchOfPossible,
    dirtyStatus: 'INSERT',
  }

  return (dispatch) => {
    dispatch({
      type: scheduleConstants.CREATE_SCHEDULE,
    })
    request({
      method: 'post',
      url: `${config.API_URL}/api/campaign/schedule`,
      data,
    })
      .then((response) => {
        if (response) {
          dispatch({
            type: scheduleConstants.CREATE_SCHEDULE_SUCCESS,
            payload: response,
          })
          Swal.fire({
            icon: 'success',
            button: false,
            showConfirmButton: false,
            timer: 1000,
            text: 'Campaign Scheduled Successfully',
          })
        } else {
          dispatch({
            type: scheduleConstants.CREATE_SCHEDULE_FAILURE,
            payload: response,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: scheduleConstants.CREATE_SCHEDULE_FAILURE,
          payload: err,
        })
      })
  }
}

export default scheduleCampaign
