import request from '../../components/Helper/Request'
import * as logConstants from './actionTypes'
import { config } from '../../config/config'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export const getAdhocLogsData = (limit, page, data, search = '') => {
  return (dispatch) => {
    dispatch({
      type: logConstants.FETCH_ADHOC_LOGS,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/adhocapi/list`,
      data: {
        limit,
        page,
        search,
        ...data,
        userId: userDetails.userId,
        accountId: userDetails.accountId,
      },
    })
      .then((response) => {
        dispatch({
          type: logConstants.FETCH_ADHOC_LOGS_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: logConstants.FETCH_ADHOC_LOGS_FAILURE,
          payload: error,
        })
      })
  }
}

export const getExternalAPILogs = (limit, page, data, search = '') => {
  console.log('++hello', data)
  return (dispatch) => {
    dispatch({
      type: logConstants.FETCH_EXTERNAL_LOGS,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/externalapi/list`,
      data: {
        limit,
        page,
        search,
        ...data,
        userId: userDetails.userId,
        accountId: userDetails.accountId,
      },
    })
      .then((response) => {
        dispatch({
          type: logConstants.FETCH_EXTERNAL_LOGS_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: logConstants.FETCH_EXTERNAL_LOGS_FAILURE,
          payload: error,
        })
      })
  }
}
