import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, Fade, Button, Paper, Popper, Typography } from '@mui/material'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import './style.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import calenderIcon from '../../assets/icons/calendar-days.svg'
import * as Styled from '../styled-components/Campaign'
import { formatDTADateForPicker } from '../Utils'

const DateRangePickerComponent = ({
  selectedDateObj,
  cssStyle,
  label: buttonLabel = 'Select Date',
  staticDateRange,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: endOfDay(new Date()),
    },
  ])

  const {
    yesterday = true,
    sevenDays = true,
    thirtyDays = true,
    threeMonths = false,
    sixMonths = false,
    oneYear = false,
  } = staticDateRange || {}

  const [selectedDateLabel, setSelectedDateLabel] = useState(buttonLabel)

  const [anchorEl, setAnchorEl] = useState(null)
  const [dateOpen, setDateOpen] = useState(false)
  const [placement, setPlacement] = useState()

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setDateOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }

  const selectedDates = selectedDateLabel.split(' - ')

  const calDays = (days) => {
    const date1 = days[0]
    const date2 = days[1]

    if (date1 === date2) {
      return 1
    }
    return 2

    // const diffTime = Math.abs(date2 - date1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // return diffDays;
  }

  const staticDateRangeArr = [
    {
      label: 'Today',
      range: () => ({
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      }),
    },
  ]

  const dynamicRanges = [
    { label: 'Yesterday', days: 1, condition: yesterday },
    { label: 'Last 7 days', days: 7, condition: sevenDays },
    { label: 'Last 30 days', days: 30, condition: thirtyDays },
    { label: 'Last 3 Months', days: 90, condition: threeMonths },
    { label: 'Last 6 Months', days: 180, condition: sixMonths },
    { label: 'Last year', days: 365, condition: oneYear },
  ]

  dynamicRanges.forEach(({ label, days, condition }) => {
    if (condition) {
      staticDateRangeArr.push({
        label,
        range: () => ({
          startDate: startOfDay(addDays(new Date(), -days)),
          endDate: endOfDay(new Date()),
        }),
      })
    }
  })

  return (
    <>
      <Button
        sx={cssStyle}
        color="primary"
        onClick={handleClick('bottom')}
        style={{
          textOverflow: 'ellipsis',
          border: `1px solid #002a57`,
          minWidth: '220px',
          textTransform: 'none',
        }}
        endIcon={
          <img
            style={{ position: 'absolute', right: 10, top: 10 }}
            alt="calender icon"
            src={calenderIcon}
          />
        }
      >
        <Typography variant="body2">
          {calDays(selectedDates) > 1
            ? `${formatDTADateForPicker(
                selectedDates[0]
              )} - ${formatDTADateForPicker(selectedDates[1])}`
            : `${formatDTADateForPicker(selectedDates[0])}`}
        </Typography>
      </Button>
      <Popper
        style={{ zIndex: '1' }}
        open={dateOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ border: '1px solid #ccc' }}>
                <DateRangePicker
                  className="datepicker"
                  onChange={(item) => {
                    setState([item.range1])
                  }}
                  // maxDate={new Date().setDate(new Date().getDate() + 30)}
                  // maxDate={new Date()}
                  rangeColors={['#002a57']}
                  showSelectionPreview
                  dragSelectionEnabled
                  moveRangeOnFirstSelection={false}
                  showDateDisplay={false}
                  months={2}
                  color="f6be00"
                  retainEndDateOnFirstSelection
                  ranges={state}
                  direction={
                    window.innerWidth > 796 ? 'horizontal' : 'vertical'
                  }
                  staticRanges={createStaticRanges(staticDateRangeArr)}
                  inputRanges={[]}
                />
                <br />
                <Box
                  sx={{
                    display: 'flex',
                    width: '98%',
                    flexDirection: 'row',
                    alignItems: 'right',
                    justifyContent: 'right',
                    m: 1,
                    '& .css-iajp3t-MuiButtonGroup-root .MuiButtonGroup-grouped':
                      {
                        minWidth: '80px',
                      },
                  }}
                >
                  <Styled.CancelButton
                    onClick={() => {
                      setDateOpen(null)
                    }}
                  >
                    Cancel
                  </Styled.CancelButton>
                  &nbsp;&nbsp;
                  <Styled.CampaignButton
                    variant="contained"
                    onClick={() => {
                      setDateOpen(null)
                      selectedDateObj(state)
                      if (state && state[0]?.startDate && state[0]?.endDate) {
                        const d1 = moment(state[0].startDate).format(
                          'MM-DD-YYYY'
                        )
                        const d2 = moment(state[0].endDate).format('MM-DD-YYYY')
                        setSelectedDateLabel(`${d1} - ${d2}`)
                      } else {
                        setSelectedDateLabel('Select Date')
                      }
                    }}
                  >
                    OK
                  </Styled.CampaignButton>
                </Box>
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

DateRangePickerComponent.propTypes = {
  selectedDateObj: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  cssStyle: PropTypes.object.isRequired,
  staticDateRange: PropTypes.object.isRequired,
}

export default DateRangePickerComponent
