/* eslint-disable */
import React from 'react'
import Bowser from 'bowser'
import axios from 'axios'
import { useEffect } from 'react'
import AppRoutes from './routes'

const App = () => {
  let systemInfo = {
    ip: '',
    deviceId: '',
  }
  const browser = Bowser.getParser(navigator.userAgent)
  useEffect(() => {
    if (browser) {
      systemInfo.deviceId =
        browser.getPlatform().type +
          ' - ' +
          browser.getBrowser().name +
          ' ' +
          browser.getBrowser().version +
          ' - ' +
          browser.getOS().name +
          ' ' +
          browser.getOS().version || ''
    }
    axios
      .get('https://api.ipify.org')
      .then((response) => {
        systemInfo.ip = response.data
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo))
      })
      .catch((error) => {
        console.log(error)
        systemInfo.ip = ''
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo))
      })
  })

  return <AppRoutes />
}

export default App
