import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
// import { normalizeFormField } from '../../utils/formUtils';
// import IMask from 'react-input-mask';
import PropTypes from 'prop-types';
import Visibility from '@mui/icons-material/Visibility';
import { noop } from 'lodash';

const CoTextField = ({
  control,
  name,
  sx,
  placeholder,
  type,
  disabled,
  onChangeProp,
  // normalizers,
  maskProps,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, ref, ...rest } = field;
        // eslint-disable-next-line no-underscore-dangle
        const _onChange = (e) => {
          if (onChangeProp) {
            onChangeProp(e);
          } else {
            onChange(e.target.value);
          }
        };
        // if (maskProps) {
        //   return (
        //     <IMask
        //       disabled={disabled}
        //       {...maskProps}
        //       {...rest}
        //       onChange={_onChange}
        //     >
        //       {(inputProps) => (
        //         <TextField
        //           disabled={disabled}
        //           {...inputProps}
        //           {...props}
        //           inputRef={ref}
        //           sx={sx}
        //           placeholder={placeholder}
        //           type={type}
        //         />
        //       )}
        //     </IMask>
        //   );
        // }
        return (
          <TextField
            {...rest}
            {...props}
            onChange={_onChange}
            inputRef={ref}
            sx={sx}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
          />
        );
      }}
    />
  );
};

CoTextField.propTypes = {
  control: PropTypes.func,
  name: PropTypes.string,
  sx: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maskProps: PropTypes.string,
};

CoTextField.defaultProps = {
  control: noop,
  name: '',
  sx: '',
  placeholder: '',
  type: '',
  disabled: false,
  maskProps: '',
};

export default CoTextField;
