import React from 'react'
import PropTypes from 'prop-types'
import brandLogo from '../assets/mass-logo.svg'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error: ', error, '\n', 'Error Info: ', errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10%' }}>
          {/* <img height={48} src={brandLogo} alt="logo" /> */}
          <h2>Something went wrong. Please contact administrator.</h2>
        </div>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}
