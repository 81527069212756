import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import Swal from 'sweetalert2'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import KPICard from '../components/KPICard'
import * as campaignActions from '../store/actions/campaign'

import SpinningLoader from '../components/SpinningLoader'
import appConfig from '../config/appConfig'

ChartJS.register(ArcElement, Tooltip, Legend)

const CampaignSnapshot = ({
  id,
  campaignDetailsData,
  fetchCampaignDetails,
  desc,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(
    JSON.stringify(campaignDetailsData?.campaignDetails)
  )
  const [isCampaignCancelled, setIsCampaignCancelled] = useState(false)
  // const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));
  const data = {
    labels:
      selectedCampaign?.templateCode &&
      Object.keys(selectedCampaign?.templateCode).map((item) => {
        if (item === 'null') {
          return 'No Template'
        }
        return item
      }),
    datasets: [
      {
        data:
          selectedCampaign?.templateCode &&
          Object.values(selectedCampaign?.templateCode),
        backgroundColor: [
          '#AF7AC5',
          '#5499C7',
          '#48C9B0',
          '#F4D03F',
          '#6495ED',
          '#DE3163',
          '#DFFF00',
          '#9FE2BF',
          '#79B696',
          '#3BADAA',
          '#6475B9',
          '#854286',
          '#923eb4',
          '#b09e6e',
          '#c4d4ee',
          '#a8d94f',
          '#6aaeb0',
          '#2dcf95',
          '#068b94',
          '#92a6d6',
          '#988f1d',
          '#6e6682',
          '#5459e8',
          '#254f8f',
          '#e23176',
          '#544ce6',
          '#5b7f54',
          '#817b1d',
          '#b25da6',
        ],
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        position: 'right',
        padding: 5,
        textAlign: 'left',
        // onClick: (e) => e.stopPropagation()
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset.data
            const total = dataset.reduce((previousValue, currentValue) => {
              return previousValue + currentValue
            })
            const currentValue = dataset[tooltipItem.dataIndex]
            console.log('currentValue', tooltipItem)
            const percentage = Math.floor((currentValue / total) * 100 + 0.5)
            return `${tooltipItem.label}: ${percentage} %`
          },
        },
      },
      datalabels: {
        display: false,
        color: 'black',
        borderWidth: 0,
        align: 'end',
        labels: {
          value: {
            color: '#000',
          },
        },
        //   formatter: (value, ctx) => {
        //     let sum = 0;
        //     const dataArr = ctx.chart.data.datasets[0].data;
        //     dataArr.forEach(d => {
        //         sum += d;
        //     });
        //     const percentage = `${(value*100 / sum).toFixed(2)} %`;
        //     console.log("percentage",percentage)
        //     return percentage;
        // },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  useEffect(() => {
    setSelectedCampaign(campaignDetailsData?.campaignDetails)
  }, [JSON.stringify(campaignDetailsData?.campaignDetails)])

  useEffect(() => {
    fetchCampaignDetails(id)
  }, [id])

  useEffect(() => {
    if (
      campaignDetailsData?.cancelCampaign?.data?.status === 1 &&
      isCampaignCancelled
    ) {
      setIsCampaignCancelled(false)
      Swal.fire({
        icon: 'success',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: campaignDetailsData?.cancelCampaign?.data?.message,
      })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }, [JSON.stringify(campaignDetailsData.cancelCampaign.data)])

  return (
    <div>
      {campaignDetailsData?.isLoading ? (
        <div style={{ marginTop: '20px' }}>
          <SpinningLoader circleColor={appConfig.colors.darkblue} />
        </div>
      ) : (
        <div className="pad-10">
          <Grid container>
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
              <div className="data-grid">
                <span className="inline-blk mar-r-10">
                  <b>Description:</b>
                </span>
                <span>{desc}</span>
              </div>
              {selectedCampaign.billingCode && (
                <div className="data-grid">
                  <span className="inline-blk mar-r-10">
                    <b>Associated Billing Code:</b>
                  </span>
                  <span>{`${selectedCampaign.billingCode} - ${selectedCampaign.billingCodeDescription}`}</span>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              xl={6}
              lg={6}
              md={6}
              style={{ verticalAlign: 'top' }}
            >
              <h4>Message Statistics</h4>
              <div>
                <KPICard
                  label="Total Count"
                  type="number"
                  value={selectedCampaign?.messages?.totalCount}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Pending"
                  type="number"
                  value={selectedCampaign?.messages?.Pending || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Queued"
                  type="number"
                  value={selectedCampaign?.messages?.Queued || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
              </div>
              <div>
                <KPICard
                  label="Sent"
                  type="number"
                  value={selectedCampaign?.messages?.Sent || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Delivered"
                  type="number"
                  value={selectedCampaign?.messages?.Delivered || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Undelivered"
                  type="number"
                  value={selectedCampaign?.messages?.Undelivered || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
              </div>
              <div>
                <KPICard
                  label="Failed"
                  type="number"
                  value={selectedCampaign?.messages?.Failed || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Invalid"
                  type="number"
                  value={selectedCampaign?.messages?.Invalid || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
                <KPICard
                  label="Cancelled"
                  type="number"
                  value={selectedCampaign?.messages?.Cancelled || 0}
                  padding="10px"
                  width="144px"
                  height="100px"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              xl={6}
              lg={6}
              md={6}
              style={{ verticalAlign: 'top' }}
            >
              <h4>By Template Code</h4>
              <div
                className="snapshot-template-container chart-full-width"
                style={{ marginTop: '-30px' }}
              >
                {selectedCampaign?.messages?.totalCount ? (
                  <Pie data={data} options={options} />
                ) : (
                  <div className="blk-center">
                    {' '}
                    <DonutLargeIcon
                      aria-label="campaign pie chart"
                      className="txt-grey"
                      sx={{ fontSize: 100 }}
                    />
                    <h4 className="txt-grey">No Data</h4>{' '}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ campaignDetailsData: state.campaign })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignDetails: (id) =>
      dispatch(campaignActions.getCampaignDetails(id)),
    cancelCampaign: (campaignId, fileId) =>
      dispatch(campaignActions.cancelCampaign(campaignId, fileId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSnapshot)

CampaignSnapshot.propTypes = {
  campaignDetailsData: PropTypes.symbol.isRequired,
  fetchCampaignDetails: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  cancelCampaign: PropTypes.func.isRequired,
}
