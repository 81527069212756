/* eslint-disable no-restricted-syntax */
import { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { curry, first, isArray, isObject, map } from 'lodash';
import LookUpPayload from '../Constants/lookup';
import { config } from '../config/config';
import request from './Helper/Request';

function useLookup(pageName = '', alterProps = {}) {
  const [lookup, setLookup] = useState([]);
  const [lookupFailed, setLookupFailed] = useState(false);

  const fetchLookup = async () => {
    const url = `${config.API_URL}/api/lookup`;
    try {
      const res = await request({
        method: 'get',
        url: url,
        headers: {
          Role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
          uid: 'localUser',
          Uniqueid: 'mydhr30bf2179ad6f4d50837443ab3',
          'Content-Type': 'application/json',
        },
      });
      if (res) {
        setLookup(res);
      } else {
        throw new Error('Lookup fetch failed');
      }
    } catch (error) {
      setLookupFailed(true);
    }
  };

  const flatten = useMemo(() => {
    const { keyProp = 'lkpDesc', valueProp = 'lookupLongVal' } = alterProps;
    const resultObj = {};
    for (const key of Object.keys(
      // LookUpPayload[pageName]
      lookup
    )) {
      const lookupMatches = {};

      for (const lkp of lookup[key] ?? []) {
        lookupMatches[lkp[keyProp]] = lkp[valueProp];
      }
      resultObj[key] = lookupMatches;
    }

    return resultObj;
  }, [alterProps, lookup, pageName]);

  useEffect(() => {
    if (pageName) {
      fetchLookup();
    }
  }, []);

  const getLookup = useCallback(
    (lkpName, key) => {
      const match = flatten[lkpName]?.[key] ?? key;
      return match;
    },
    [flatten]
  );

  const getOptions = (lkpName, _alterProps = {}) => {
    const { keyProp = 'lkpDesc', valueProp = 'lookupLongVal' } = _alterProps;
    const lkpValue = lookup[lkpName];
    if (!lkpValue) return [];

    if (isArray(lkpValue) && isObject(first(lkpValue))) {
      return map(lkpValue, (lkp) => ({
        label: lkp[valueProp],
        value: lkp[keyProp],
      }));
    }
    return lkpValue;
  };

  return {
    islkpFailed: lookupFailed,
    lkpRaw: lookup,
    lkpFlatten: flatten,
    getOptions,
    getLkpMatch: curry(getLookup),
  };
}

export default useLookup;
