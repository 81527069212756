import React, { useState, useEffect } from 'react'
import { Grid, Autocomplete, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Pie, Bar, Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  BarElement,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent'
import * as Styled from '../components/styled-components/Campaign'
import KPICard from '../components/KPICard'
import {
  getDashboardDetails,
  getDashboardFiltersData,
} from '../store/actions/dashboard'
import SpinningLoader from '../components/SpinningLoader'
import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
} from '../components/Utils'
import appConfig from '../config/appConfig'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  Title
)

const pieOptions = {
  plugins: {
    legend: {
      position: 'right',
      padding: 5,
      textAlign: 'center',
      // onClick: (e) => e.stopPropagation(),
      family: 'Source Sans Pro',
    },
    datalabels: {
      display: false,
      color: 'black',
      align: 'end',
      labels: {
        value: {
          color: '#000',
        },
      },
      formatter: (value) => {
        return value !== 0 ? value : null
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
}

const doughnutOptions = {
  plugins: {
    legend: {
      position: 'right',
      padding: 5,
      textAlign: 'center',
      // onClick: (e) => e.stopPropagation()
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          const dataset = tooltipItem.dataset.data
          const total = dataset.reduce((previousValue, currentValue) => {
            return previousValue + currentValue
          })
          const currentValue = dataset[tooltipItem.dataIndex]
          const percentage = Math.floor((currentValue / total) * 100 + 0.5)
          return `${tooltipItem.label}: ${percentage} %`
        },
      },
    },
    datalabels: {
      display: false,
      color: 'black',
      align: 'center',
      labels: {
        value: {
          color: '#000',
        },
      },
      formatter: (value) => {
        return value !== 0 ? value : null
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  },
}
const dropDownlistCss = {
  borderRadius: '5px',
  border: `1px solid ${appConfig.colors.blueprimary}`,
  width: '200px',
  marginTop: '-6px',
  marginLeft: '10px',
  '.MuiAutocomplete-input': { height: '5px', outline: 'none' },
  '.MuiAutocomplete-input:hover': { border: 'none', outline: 'none' },
  marginBottom: '10px',
}

export const options = {
  plugins: {
    legend: {
      position: 'right',
      padding: 5,
      textAlign: 'center',
      // onClick: (e) => e.stopPropagation()
    },
    datalabels: {
      color: 'black',
      display: (context) => {
        return context.dataset.data[context.dataIndex] !== 0 // or >= 1 or ...
      },
      formatter: (value) => {
        let val
        if (value > 1000 && value < 1000000) {
          val = `${(value / 1000).toFixed(0)}k`
        } else if (value > 1000000) {
          val = `${(value / 1000000).toFixed(0)}m`
        } else {
          val = value
        }
        return val
      },
      labels: {
        value: {
          color: '#000',
        },
        title: {
          font: {
            size: 12,
          },
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => {
          let val
          if (value > 1000 && value < 1000000) {
            val = `${(value / 1000).toFixed(1)}k`
          } else if (value > 1000000) {
            val = `${(value / 1000000).toFixed(1)}m`
          } else {
            val = value
          }
          return val
        },
        stepSize: 1,
      },
      scaleLabel: {
        display: true,
        labelString: '1k = 1000',
      },
    },
  },
}

const barData = {
  labels: ['SMS', 'Email', 'Voice'],
  datasets: [
    {
      label: 'Failed',
      data: [12, 19, 3],
      backgroundColor: '#DA0600',
    },
    {
      label: 'Invalid',
      data: [12, 19, 3],
      backgroundColor: '#CA6F1E',
    },
    {
      label: 'Pending',
      data: [12, 19, 3],
      backgroundColor: '#58D68D',
    },
    {
      label: 'Sent',
      data: [12, 19, null],
      backgroundColor: '#FFB1C1',
    },
    {
      label: 'Delivered',
      data: [12, null, null],
      backgroundColor: '#A559C8',
    },
    {
      label: 'Undelivered',
      data: [12, null, null],
      backgroundColor: '#E74C3C',
    },
    {
      label: 'Queued',
      data: [12, null, 3],
      backgroundColor: '#3498DB',
    },

    {
      label: 'Completed',
      data: [null, null, 3],
      backgroundColor: '#ABABAB',
    },
    {
      label: 'In-Progress',
      data: [null, null, 3],
      backgroundColor: '#F1C40F',
    },
    {
      label: 'Initiated',
      data: [null, null, 3],
      backgroundColor: '#FFF59D',
    },
    {
      label: 'Ringing',
      data: [null, null, 3],
      backgroundColor: '#9AD0F5',
    },
  ],
}

const datePickerStyle = {
  background: '#FFF',
  marginLeft: '5px',
  marginRight: '5px',
  textTransform: 'capitalize',
  borderColor: '#005974',
  color: '#000',
  height: '38px',
  padding: '0 30px 0 10px',
}

const DashboardV2 = ({
  fetchDashboardDetails,
  dashboardDetails,
  fetchFiltersData,
}) => {
  const [dashboardInfo, setDashboardDetails] = useState(
    dashboardDetails.dashboardDetails
  )
  const [filterBarData, setFilterBarData] = useState(barData)
  const filterInitialState = {
    channel: { key: 'SMS', value: 'SMS' },
    templateCode: { key: 'All Templates', value: '' },
    campaignName: { key: 'All Campaigns', value: '' },
    startDate: CalculateUserTzToUTCTz(startOfDay(new Date()).toISOString()),
    endDate: CalculateUserTzToUTCTz(endOfDay(new Date()).toISOString()),
  }
  const [filterData, setFilterData] = useState(filterInitialState)
  const [selectedCampaign, setSelectedCampaign] = useState('')

  const pieData = {
    labels: ['In Progress', 'Processed', 'Completed', 'Failed', 'Cancelled'],
    datasets: [
      {
        data: [
          dashboardInfo?.campaign?.inprogress,
          dashboardInfo?.campaign?.processed,
          dashboardInfo?.campaign?.completed,
          dashboardInfo?.campaign?.failed,
          dashboardInfo?.campaign?.cancelled,
        ],
        backgroundColor: [
          '#AF7AC5',
          '#569DF7',
          '#27AE60',
          '#F44747',
          '#BFC9CA',
        ],
      },
    ],
  }

  const doughnutData = {
    labels: [
      'English',
      'Spanish',
      'Vietnamese',
      'Portuguese',
      'Haitian Creole',
      'Chinese',
    ],
    datasets: [
      {
        data: [
          dashboardInfo?.language?.en,
          dashboardInfo?.language?.es,
          dashboardInfo?.language?.vi,
          dashboardInfo?.language?.pt,
          dashboardInfo?.language?.ht,
          dashboardInfo?.language?.zh,
        ],
        backgroundColor: [
          '#AED6F1',
          '#9B59B6',
          '#F7DC6F',
          '#3190BF',
          '#ACD475',
          '#DB6DBB',
        ],
      },
    ],
  }

  const filterHandleChange = (data, val, id) => {
    switch (id) {
      case 'channel':
        setFilterData({
          ...filterData,
          channel: { key: val.label, value: val.id },
          templateCode: { key: 'All Templates', value: '' },
          campaignName: { key: 'All Campaigns', value: '' },
        })

        break
      case 'date':
        setFilterData({
          ...filterData,
          templateCode: { key: 'All Templates', value: '' },
          campaignName: { key: 'All Campaigns', value: '' },
          endDate: CalculateUserTzToUTCTz(endOfDay(data?.[0]?.endDate)),
          startDate: CalculateUserTzToUTCTz(data?.[0]?.startDate),
        })
        break
      case 'template':
        setFilterData({
          ...filterData,
          templateCode: { key: val.label, value: val.id },
        })
        break
      case 'campaign':
        setSelectedCampaign(val.id)
        setFilterData({
          ...filterData,
          campaignName: { key: val.label, value: val.id },
        })
        break
      default:
        console.log(data)
    }
  }

  const ChannelList = [
    { key: 'SMS', value: 'SMS' },
    { key: 'Email', value: 'Email' },
  ]
  let TemplateCodesData = []
  if (selectedCampaign !== '') {
    TemplateCodesData = [
      ...(dashboardDetails?.filters?.data?.templates.filter(
        (tem) => tem.campaignId === selectedCampaign
      ) || ''),
    ]
  } else {
    TemplateCodesData = [...(dashboardDetails?.filters?.data?.templates || '')]
  }

  const TemplateCodes = []
  if (TemplateCodesData.length > 0) {
    TemplateCodes.push({ value: 'All Templates', key: '' })
    TemplateCodesData.forEach((option) => {
      TemplateCodes.push({
        value: `${option.value} (${option.key})`,
        key: option.key,
      })
    })
  }

  const campaignNames = [...(dashboardDetails?.filters?.data?.campaigns || '')]
  if (campaignNames.length > 0) {
    campaignNames.unshift({ value: 'All Campaigns', key: '' })
  }

  const filterValues = {
    filterBy: filterData.channel.value,
    startDate: filterData.startDate.toISOString(),
    endDate: filterData.endDate.toISOString(),
    campaignId: '',
    filterByCampaign: filterData.campaignName.value || '',
    fileId: '',
    language: '',
    statusId: '',
    templateCode: filterData.templateCode.value
      ? filterData.templateCode.value
      : '',
    campaignName: filterData.campaignName.value
      ? filterData.campaignName.value
      : '',
  }

  useEffect(() => {
    setFilterData(filterInitialState)
  }, [])

  useEffect(() => {
    fetchFiltersData(filterValues)
    fetchDashboardDetails(filterValues)
    setDashboardDetails(dashboardDetails.dashboardDetails)
  }, [
    JSON.stringify(filterData.channel),
    JSON.stringify(filterData.startDate),
    JSON.stringify(filterData.endDate),
  ])

  useEffect(() => {
    setDashboardDetails(dashboardDetails.dashboardDetails)
    if (
      filterData.channel.key &&
      filterData.channel.key.toLowerCase() === 'sms'
    ) {
      const smsDataSet = []
      const smsDashboardData = dashboardDetails?.dashboardDetails

      smsDataSet.push({
        label: 'Pending',
        data: [
          smsDashboardData?.sms?.pending ? smsDashboardData?.sms?.pending : 0,
        ],
        backgroundColor: '#58D68D',
      })
      smsDataSet.push({
        label: 'Queued',
        data: [
          smsDashboardData?.sms?.queued ? smsDashboardData?.sms?.queued : 0,
        ],
        backgroundColor: '#3498DB',
      })
      smsDataSet.push({
        label: 'Sent',
        data: [smsDashboardData?.sms?.sent ? smsDashboardData?.sms?.sent : 0],
        backgroundColor: '#FFB1C1',
      })
      smsDataSet.push({
        label: 'Delivered',
        data: [
          smsDashboardData?.sms?.delivered
            ? smsDashboardData?.sms?.delivered
            : 0,
        ],
        backgroundColor: '#A559C8',
      })
      smsDataSet.push({
        label: 'Undelivered',
        data: [
          smsDashboardData?.sms?.undelivered
            ? smsDashboardData?.sms?.undelivered
            : 0,
        ],
        backgroundColor: '#E74C3C',
      })

      smsDataSet.push({
        label: 'Failed',
        data: [
          smsDashboardData?.sms?.failed ? smsDashboardData?.sms?.failed : 0,
        ],
        backgroundColor: '#DA0600',
      })
      smsDataSet.push({
        label: 'Invalid',
        data: [
          smsDashboardData?.sms?.invalid ? smsDashboardData?.sms?.invalid : 0,
        ],
        backgroundColor: '#CA6F1E',
      })
      smsDataSet.push({
        label: 'Cancelled',
        data: [
          smsDashboardData?.sms?.cancelled
            ? smsDashboardData?.sms?.cancelled
            : 0,
        ],
        backgroundColor: '#FE8544',
      })

      setFilterBarData(() => ({
        labels: [''],
        datasets: smsDataSet,
      }))
    }

    if (
      filterData.channel.key &&
      filterData.channel.key.toLowerCase() === 'email'
    ) {
      const emailDataSet = []
      const emailDashboardData = dashboardDetails?.dashboardDetails

      emailDataSet.push({
        label: 'Pending',
        data: [
          emailDashboardData?.email?.pending
            ? emailDashboardData?.email?.pending
            : 0,
        ],
        backgroundColor: '#58D68D',
      })
      emailDataSet.push({
        label: 'Queued',
        data: [
          emailDashboardData?.email?.queued
            ? emailDashboardData?.email?.queued
            : 0,
        ],
        backgroundColor: '#3498DB',
      })
      emailDataSet.push({
        label: 'Sent',
        data: [
          emailDashboardData?.email?.sent ? emailDashboardData?.email?.sent : 0,
        ],
        backgroundColor: '#FFB1C1',
      })
      emailDataSet.push({
        label: 'Delivered',
        data: [
          emailDashboardData?.email?.delivered
            ? emailDashboardData?.email?.delivered
            : 0,
        ],
        backgroundColor: '#A559C8',
      })
      emailDataSet.push({
        label: 'Undelivered',
        data: [
          emailDashboardData?.email?.undelivered
            ? emailDashboardData?.email?.undelivered
            : 0,
        ],
        backgroundColor: '#E74C3C',
      })
      emailDataSet.push({
        label: 'Failed',
        data: [
          emailDashboardData?.email?.failed
            ? emailDashboardData?.email?.failed
            : 0,
        ],
        backgroundColor: '#DA0600',
      })
      emailDataSet.push({
        label: 'Invalid',
        data: [
          emailDashboardData?.email?.invalid
            ? emailDashboardData?.email?.invalid
            : 0,
        ],
        backgroundColor: '#CA6F1E',
      })
      emailDataSet.push({
        label: 'Cancelled',
        data: [
          emailDashboardData?.email?.cancelled
            ? emailDashboardData?.email?.cancelled
            : 0,
        ],
        backgroundColor: '#EC7063',
      })
      setFilterBarData(() => ({
        labels: [''],
        datasets: emailDataSet,
      }))
    }
  }, [
    JSON.stringify(dashboardDetails.dashboardDetails),
    filterData.channel.key,
  ])

  const chartData =
    filterData.channel.key === 'SMS'
      ? dashboardDetails?.dashboardDetails?.sms
      : dashboardDetails?.dashboardDetails?.email

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <div className="flt-r">
            <div className="inline-blk mar-r-10">
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  className="autocomplete-class"
                  disablePortal
                  disableClearable
                  openOnFocus
                  clearOnBlur
                  defaultValue="SMS"
                  options={ChannelList.map((item) => ({
                    label: item.key,
                    id: item.value,
                  }))}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'channel',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                  onChange={(e, val) => {
                    filterHandleChange(e, val, 'channel')
                  }}
                />
              </FormControl>
            </div>
            <div className="inline-blk">
              <DateRangePickerComponent
                label={`${moment(
                  CalculateUTCTzToUserTz(filterData.startDate)
                ).format('MM-DD-YYYY')} - ${moment(
                  CalculateUTCTzToUserTz(filterData.endDate)
                ).format('MM-DD-YYYY')}`}
                cssStyle={datePickerStyle}
                selectedDateObj={(date) => {
                  filterHandleChange(date, '', 'date')
                }}
              />
            </div>

            <div className="inline-blk">
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  disabled={
                    dashboardDetails?.filters?.isLoading ||
                    campaignNames.length === 0
                  }
                  openOnFocus
                  className="autocomplete-class"
                  value={filterData.campaignName.key}
                  noOptionsText="Templates not found"
                  options={campaignNames.map((item) => ({
                    label: item.value,
                    id: item.key,
                  }))}
                  onChange={(e, val) => {
                    filterHandleChange(e, val, 'campaign')
                  }}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'campaign name',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                />
              </FormControl>
            </div>
            <div className="inline-blk">
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  disabled={
                    dashboardDetails?.filters?.isLoading ||
                    TemplateCodes.length === 0
                  }
                  openOnFocus
                  className="autocomplete-class"
                  value={filterData.templateCode.key}
                  noOptionsText="Templates not found"
                  options={TemplateCodes.map((item) => ({
                    label: item.value,
                    id: item.key,
                  }))}
                  onChange={(e, val) => {
                    filterHandleChange(e, val, 'template')
                  }}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'template code',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                />
              </FormControl>
            </div>
            <div className="inline-blk" style={{ marginLeft: '10px' }}>
              <Styled.CampaignButton
                disabled={
                  dashboardDetails.isLoading ||
                  dashboardDetails.filters.isLoading
                }
                onClick={() => fetchDashboardDetails(filterValues)}
              >
                Apply
              </Styled.CampaignButton>
            </div>
            <div className="inline-blk">
              <IconButton
                disabled={
                  dashboardDetails.isLoading ||
                  dashboardDetails.filters.isLoading
                }
                onClick={() => fetchDashboardDetails(filterValues)}
                sx={{ background: appConfig.colors.grey, marginLeft: '20px' }}
                aria-label="refresh"
              >
                <RefreshIcon />
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>
      {dashboardDetails.isLoading || dashboardDetails?.filters?.isLoading ? (
        <div style={{ marginTop: '10%' }}>
          <SpinningLoader circleColor={appConfig.colors.darkblue} />
        </div>
      ) : (
        <div>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
              <div className="dashboard-heading">
                <h1 style={{ fontSize: '16px', margin: 0 }}>
                  <b>Files</b>
                </h1>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} xl={7} lg={7} md={7}>
              <div className="dashboard-data-block">
                <KPICard
                  label="Total"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.totalCount
                      ? dashboardInfo?.campaign?.totalCount
                      : 0
                  }
                />
                <KPICard
                  label="In Progress"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.inprogress
                      ? dashboardInfo?.campaign?.inprogress
                      : 0
                  }
                />
                <KPICard
                  label="Processed"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.processed
                      ? dashboardInfo?.campaign?.processed
                      : 0
                  }
                />
                <KPICard
                  label="Completed"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.completed
                      ? dashboardInfo?.campaign?.completed
                      : 0
                  }
                />
                <KPICard
                  label="Failed"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.failed
                      ? dashboardInfo?.campaign?.failed
                      : 0
                  }
                />
                <KPICard
                  label="Cancelled"
                  type="number"
                  width="175px"
                  value={
                    dashboardInfo && dashboardInfo?.campaign?.cancelled
                      ? dashboardInfo?.campaign?.cancelled
                      : 0
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} xl={4.9} lg={4.9} md={4.9}>
              <div className="dashboard-chart-block">
                <div className="snapshot-template-container">
                  {dashboardInfo && dashboardInfo?.campaign?.totalCount ? (
                    <Pie
                      aria-label="campaign pie chart"
                      data={pieData}
                      options={pieOptions}
                    />
                  ) : (
                    <div className="blk-center">
                      {' '}
                      <DonutLargeIcon
                        className="txt-grey"
                        sx={{ fontSize: 100 }}
                      />
                      <h4 className="txt-grey">No Data</h4>{' '}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="dashboard-heading">
            <span>
              <b>
                {filterData.channel.value.toLowerCase() === 'email'
                  ? 'Email'
                  : 'SMS'}{' '}
                Statistics
              </b>
            </span>
          </div>
          <Grid container className="dashboard-data-block">
            <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
              <KPICard
                label="Total"
                width="175px"
                type="number"
                value={chartData?.totalCount ? chartData?.totalCount : 0}
              />
              <KPICard
                label="Pending"
                width="175px"
                type="number"
                value={chartData?.pending ? chartData?.pending : 0}
              />
              <KPICard
                label="Queued"
                width="175px"
                type="number"
                value={chartData?.queued ? chartData?.queued : 0}
              />
              <KPICard
                label="Sent"
                width="175px"
                type="number"
                value={chartData?.sent ? chartData?.sent : 0}
              />
              <KPICard
                label="Delivered"
                width="175px"
                type="number"
                value={chartData?.delivered ? chartData?.delivered : 0}
              />
              <KPICard
                label="Undelivered"
                width="175px"
                type="number"
                value={chartData?.undelivered ? chartData?.undelivered : 0}
              />
              <KPICard
                label="Failed"
                width="175px"
                type="number"
                value={chartData?.failed ? chartData?.failed : 0}
              />
              <KPICard
                label="Invalid"
                width="175px"
                type="number"
                value={chartData?.invalid ? chartData?.invalid : 0}
              />
              <KPICard
                label="Cancelled"
                width="175px"
                type="number"
                value={chartData?.cancelled ? chartData?.cancelled : 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  marginLeft: '-10px',
                }}
              >
                {chartData?.totalCount ? (
                  <Bar
                    aria-label="messages bar chart"
                    data={filterBarData}
                    options={options}
                  />
                ) : (
                  <div className="blk-center">
                    {' '}
                    <StackedBarChartIcon
                      className="txt-grey"
                      sx={{ fontSize: 100 }}
                    />
                    <h4 className="txt-grey">No Data</h4>{' '}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
              <div className="dashboard-heading">
                <span>
                  <b>Language Statistics</b>
                </span>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} xl={7} lg={7} md={7}>
              <div className="dashboard-data-block">
                <KPICard
                  label="English"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.en
                      ? dashboardInfo?.language?.en
                      : 0
                  }
                />
                <KPICard
                  label="Spanish"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.es
                      ? dashboardInfo?.language?.es
                      : 0
                  }
                />
                <KPICard
                  label="Vietnamese"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.vi
                      ? dashboardInfo?.language?.vi
                      : 0
                  }
                />
                <KPICard
                  label="Portuguese"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.pt
                      ? dashboardInfo?.language?.pt
                      : 0
                  }
                />
                <KPICard
                  label="Haitian Creole"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.ht
                      ? dashboardInfo?.language?.ht
                      : 0
                  }
                />
                <KPICard
                  label="Chinese"
                  width="175px"
                  type="number"
                  value={
                    dashboardInfo?.language?.zh
                      ? dashboardInfo?.language?.zh
                      : 0
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} xl={4.9} lg={4.9} md={4.9}>
              <div className="dashboard-chart-block">
                <div className="snapshot-template-container">
                  {dashboardInfo && dashboardInfo?.language?.totalCount ? (
                    <Doughnut
                      aria-label="language donut chart"
                      data={doughnutData}
                      options={doughnutOptions}
                    />
                  ) : (
                    <div className="blk-center">
                      {' '}
                      <DonutLargeIcon
                        className="txt-grey"
                        sx={{ fontSize: 100 }}
                      />
                      <h4 className="txt-grey">No Data</h4>{' '}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ dashboardDetails: state.dashboard })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardDetails: (filter, date) =>
      dispatch(getDashboardDetails(filter, date)),
    fetchFiltersData: (data) => dispatch(getDashboardFiltersData(data)),
  }
}
DashboardV2.propTypes = {
  dashboardDetails: PropTypes.object.isRequired,
  fetchDashboardDetails: PropTypes.func.isRequired,
  fetchFiltersData: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardV2)
