import React, { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import MainLayout from '../Layout/MainLayout'
import TenantLayout from '../Layout/TenantLayout'
import SignIn from '../pages/Login/SignIn'
import SignInn from '../pages/SignIn'
import Campaign from '../pages/Campaign'
import CampaignSnapshot from '../pages/CampaignSnapshot'
// import OptOut from "../pages/Optout";
import CreateCampaign from '../pages/CreateCampaign'
import CreateCampaignNew from '../pages/Dashboard/CreateCampaign/index'
import Users from '../pages/Users'
import Template from '../pages/Template'
import CreateTemplate from '../pages/CreateTemplate'
import EditTemplate from '../pages/EditTemplate'
import EmailConfiguration from '../pages/EmailConfiguration'
import Reports from '../pages/Reports'
import OptoutList from '../pages/OptoutList'
import Audits from '../pages/Audits'
import Reconcile from '../pages/Reconcile'
import ErrorLogs from '../pages/ErrorLogs'
import Dashboard from '../pages/Dashboard'
// import DashboardV3 from "../pages/Dashboardv3";
import CreateUser from '../pages/CreateUser'
import EditUser from '../pages/EditUser'
import Broadcast from '../pages/instant-notifications/Broadcast'
import InstantMessages from '../pages/instant-notifications/InstantMessages'
import UploadFile from '../pages/UploadFile'
import CampaignFiles from '../pages/CampaignFiles'
import Gloassary from '../pages/Gloassary'
import TenantSelection from '../pages/TenantSelection'
import AdhocLogs from '../pages/AdhocLogs'
import ExternalLogs from '../pages/ExternalLogs'
import { hasAccess } from '../components/Utils'
import Billing from '../pages/Billing'
import Layout from '../Containers/Layout/index'
import DashboardNew from '../pages/DashboardNew'
import TwoWayChat from '../pages/TwoWayChat'
import Chat from '../pages/Chat/Chat'
import Group from '../pages/Group'

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Oh ho! 404, Page Not Found</h2>
      <span>
        Click to return <a href="/">Home Page</a>
      </span>
    </div>
  )
}

const wrapWithLayout = (comp) => {
  return <MainLayout>{comp}</MainLayout>
}
const wrapWithTenantLayout = (comp) => {
  return <TenantLayout>{comp}</TenantLayout>
}

const AppRoutes = () => {
  const auth = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const tenant = JSON.parse(window.sessionStorage.getItem('tenantInfo'))
  const [componentShow, setComponentShow] = useState(false)

  const hasCampaignAccess = hasAccess('CAM')
  const hasTemplateAccess = hasAccess('TEM')
  const hasOptOutAccess = hasAccess('OPT')
  const hasUserAccess = hasAccess('USR')
  const hasLogAccess = hasAccess('LOG')
  const hasReportAccess = hasAccess('RPT')
  const hasReconcileAccess = hasAccess('REC')
  const hasInvalidReportAccess = hasAccess('INV')
  const hasInstantCampaignAccess = hasAccess('INC')
  const hasSettingsAccess = hasAccess('SET')
  const hasInboundAccess = hasAccess('ADH')
  const hasOutboundAccess = hasAccess('EXT')
  const hasBillingAccess = hasAccess('BIL')
  // To fine grain access control please add your authority code and manage access here

  if (!auth || !tenant) {
    return (
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<SignIn />} />
        {/* <Route exact path="/optout" element={<OptOut/>} /> */}
        <Route path="*" element={<Navigate to="/login" />} />
        <Route
          path="/tenantselection"
          element={wrapWithTenantLayout(<TenantSelection />)}
        />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="" element={<Layout />}>
        {hasCampaignAccess && <Route path="/campaign" element={<Campaign />} />}
        {/* <Route exact path="/dashboard" element={<DashboardV3/>)} /> */}
        {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
        <Route exact path="/dashboard" element={<DashboardNew />} />

        {hasTemplateAccess && (
          <Route path="/templates" element={<Template />} />
        )}
        <Route exact path="/" element={<Dashboard />} />
        {hasCampaignAccess && (
          <Route
            exact
            path="/campaigndetails/:id"
            element={<CampaignSnapshot />}
          />
        )}
        <Route exact path="usermanagement" element={<Users />} />
        {hasCampaignAccess && (
          <Route exact path="/createcampaign" element={<CreateCampaign />} />
        )}
        {hasCampaignAccess && (
          <Route
            exact
            path="/campaigncreation"
            element={<CreateCampaignNew setComponentShow={setComponentShow} />}
          />
        )}
        {hasTemplateAccess && (
          <Route exact path="/createtemplate" element={<CreateTemplate />} />
        )}
        {hasTemplateAccess && (
          <Route exact path="/edittemplate" element={<EditTemplate />} />
        )}
        {hasUserAccess && (
          <Route exact path="/createuser" element={<CreateUser />} />
        )}
        {hasUserAccess && (
          <Route exact path="/edituser" element={<EditUser />} />
        )}
        {hasInstantCampaignAccess && (
          <Route exact path="/staffalerts" element={<Broadcast />} />
        )}
        {hasInstantCampaignAccess && (
          <Route exact path="/instant" element={<InstantMessages />} />
        )}
        {hasSettingsAccess && (
          <Route path="/emailconfiguration" element={<EmailConfiguration />} />
        )}
        {hasReportAccess && <Route path="/reports" element={<Reports />} />}
        {hasInvalidReportAccess && (
          <Route path="/errorlog" element={<ErrorLogs />} />
        )}
        {hasOptOutAccess && (
          <Route path="/optoutlist" element={<OptoutList />} />
        )}
        {hasLogAccess && <Route path="/audits" element={<Audits />} />}
        {hasReconcileAccess && (
          <Route path="/reconcile" element={<Reconcile />} />
        )}
        {hasCampaignAccess && (
          <Route path="/uploadfile" element={<UploadFile />} />
        )}
        {hasCampaignAccess && (
          <Route path="/campaignfiles" element={<CampaignFiles />} />
        )}
        {hasInboundAccess && (
          <Route path="/externallogs" element={<ExternalLogs />} />
        )}
        {hasOutboundAccess && (
          <Route path="/adhoclogs" element={<AdhocLogs />} />
        )}
        {hasBillingAccess && <Route path="/billing" element={<Billing />} />}

        <Route path="/glossary" element={<Gloassary />} />
        {/*<Route path="/billing" element={<Billing />} />
        <Route path="/adhoclogs" element={<AdhocLogs />} /> */}
        <Route path="/externallogs" element={<ExternalLogs />} />
        <Route
          path="/tenantselection"
          element={wrapWithTenantLayout(<TenantSelection />)}
        />
        <Route path="/signout" element={<Navigate to="/login" />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/group" element={<Group />} />
        <Route path="/two-way-chat" element={<TwoWayChat />} />
        <Route exact path="/chat" element={<Chat />} />
        <Route path="/emailconfiguration" element={<EmailConfiguration />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
