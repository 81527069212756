import React from 'react';
import AvatarIcon from './Avatar';
import Badge from '@mui/material/Badge';
import dayjs from 'dayjs';
import moment from 'moment';

const UserCard = ({ user, children, msg }) => {
  const lastMessage = user?.lastMessage;
  let recentMsg = '';
  if (lastMessage) {
    if (lastMessage.body.length > 25) {
      recentMsg = `${lastMessage.body.slice(0, 25)}...`;
    } else {
      recentMsg = lastMessage.body;
    }
  }

  const time = moment(lastMessage?.timestamp);
  const relativeTime = time.fromNow();
  return (
    <div className="usercard">
      <div className="usercard_info">
        <AvatarIcon user={user} />
        <div className="username" style={{ transform: 'translateY(-2px)' }}>
          <span>{user?.mobileNumber}</span>
          {msg ? '' : <small style={{ opacity: 0.7 }}>{recentMsg}</small>}
        </div>
      </div>
      {msg ? (
        ''
      ) : (
        <div className="usercard-timeinfo">
          <small>{relativeTime && relativeTime}</small>
          <Badge
            color="secondary"
            badgeContent={user?.unreadMsgCount}
            sx={{
              position: 'relative',
              left: '-10px',
              marginTop: '4px',
              '& .MuiBadge-badge': {
                color: '#fff',
                backgroundColor: '#578ad6',
              },
            }}
          />
        </div>
      )}
      {/* {children} */}
    </div>
  );
};

export default UserCard;
