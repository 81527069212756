/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { useNavigate, useLocation } from 'react-router-dom'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import appConfig from '../../config/appConfig'

import TableCell from '@mui/material/TableCell'

import DefaultTable from '../../components/Table'

import * as instantCampaignActions from '../../store/actions/instantCampaign'
import * as menuActions from '../../store/actions/breadcrumb'

import * as Styled from '../../components/styled-components/Campaign'
import SkeletonGridLoader from '../../components/SkeletonGridLoader'
import '../../styles/App.scss'
import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
  splitDate,
  splitTime,
  formatDTADate,
  getChannelLabel,
} from '../../components/Utils'
import { config } from '../../config/config'
import dayjs from 'dayjs'

const Campaign = ({
  fetchInstantCampaignList,
  instantCampaignDetails,
  setBreadcrumb,
}) => {
  const params = useLocation()
  const [campaignId, setCampaignId] = useState(null)
  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [campaignFilter, setCampaignFilter] = useState(
    params && params.state && params.state.dropdownSelected
      ? params.state.dropdownSelected
      : { key: 'All Communication Channels', value: 'all' }
  )
  const [selected, setSelected] = useState(null)
  const navigate = useNavigate()
  const [enableRowDetails, setEnableRowDetails] = useState(false)
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const [searchText, setSearchText] = useState('')
  const selectedRef = useRef()
  const isMounted = useRef(false)
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })

  useEffect(() => {
    setBreadcrumb('Instant Notifications')
    if (params && params.state && params.state.dropdownSelected) {
      setCampaignFilter(params.state.dropdownSelected)
    }
  }, [params])

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const getFilterValues = (filter) => {
    setPagination((prevState) => ({
      ...prevState,
      offset: 1,
    }))
    setCampaignId(null)
    setCampaignFilter(filter)
  }

  const filterValues = {
    filterBy: campaignFilter.value,
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    endDate: CalculateUserTzToUTCTz(
      endOfDay(selectedDate?.endDate)
    ).toISOString(),
  }

  useEffect(() => {
    isMounted.current = true
    fetchInstantCampaignList(
      pagination.limit,
      pagination.offset,
      searchText,
      filterValues
    )
  }, [JSON.stringify(pagination), campaignFilter, JSON.stringify(selectedDate)])

  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchInstantCampaignList(pagination.limit, 1, searchText, filterValues)
    }
  }, [searchText])

  const loadRowDetails = () => {
    return (
      <div>
        {selectedRef.current.channel.toLowerCase() === 'email' ? (
          <Grid container spacing={2} padding={1}>
            <Grid
              item
              xs={1}
              sm={1}
              xl={1}
              lg={1}
              md={1}
              sx={{ fontFamily: appConfig.fontfamily }}
            >
              <b>Subject:</b>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              xl={10}
              lg={10}
              md={10}
              sx={{ fontFamily: appConfig.fontfamily }}
            >
              {selectedRef.current.subject}
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} padding={1}>
          <Grid
            item
            xs={1}
            sm={1}
            xl={1}
            lg={1}
            md={1}
            sx={{ fontFamily: appConfig.fontfamily }}
          >
            <b>Content:</b>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            xl={10}
            lg={10}
            md={10}
            sx={{ fontFamily: appConfig.fontfamily }}
          >
            <div style={{ width: '90%', wordBreak: 'break-word' }}>
              {selectedRef.current.content}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} padding={1}>
          <Grid
            item
            xs={1}
            sm={1}
            xl={1}
            lg={1}
            md={1}
            sx={{ fontFamily: appConfig.fontfamily }}
          >
            <b>Recipients:</b>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            xl={4}
            lg={4}
            md={4}
            sx={{ fontFamily: appConfig.fontfamily }}
          >
            {selectedRef.current.sendTo.map((row) => (
              <div className="mar-b-10">{row}</div>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderDetailButton = (row) => {
    return (
      <div>
        <IconButton
          className="pad-0"
          onClick={() => {
            setEnableRowDetails(
              campaignId !== row.id ? true : !enableRowDetails
            )
            setCampaignId(row.id)
            selectedRef.current = row
            setSelected(row.id)
          }}
          title="View Details"
          aria-label="view details"
          size="small"
        >
          {campaignId === row.id && enableRowDetails ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
              fontSize="inherit"
            />
          )}
        </IconButton>
      </div>
    )
  }

  const handleSearch = (search) => {
    isMounted.current = false
    setSearchText(search)
  }

  const onDateChange = (date) => {
    setSelectedDate({
      startDate: date?.[0]?.startDate || date?.startDate,
      endDate: date?.[0]?.endDate || date?.endDate,
    })
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.name}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.channel ? getChannelLabel(row.channel) : '-'}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.totalCount ? row.totalCount : 0}
        </TableCell>
        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{formatDTADate(CalculateUTCTzToUserTz(row.createdTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {dayjs(row.modifiedTime).format('MM-DD-YYYY')}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.createdTime))}
        </TableCell>

        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{splitTime(CalculateUTCTzToUserTz(row.createdTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.createdBy}
        </TableCell>
      </>
    )
  }

  const onRefresh = () => {
    setPagination({ offset: 1, limit: 10 })
    setSearchText('')
    setCampaignFilter({ key: 'All Communication Channels', value: 'all' })
    setSelectedDate({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    })
  }

  const handleSort = (sortValue) => {
    console.log('sort', sortValue)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>Instant Notifications</b>
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          xl={6}
          lg={6}
          md={6}
          sx={{
            textAlign: {
              xs: 'left',
              sm: 'right',
              lg: 'right',
              xl: 'right',
              md: 'right',
            },
          }}
        >
          {userDetails?.authorities.includes('INCMCR') ? (
            <Styled.CampaignButton
              variant="contained"
              onClick={() =>
                navigate('/staffalerts', { state: { initState: true } })
              }
            >
              Create Instant Notification
            </Styled.CampaignButton>
          ) : null}
        </Grid>
      </Grid>
      <hr className="blue-border" />
      {instantCampaignDetails?.isLoading ? (
        <SkeletonGridLoader />
      ) : (
        <DefaultTable
          filterAction={(filter) => {
            getFilterValues(filter)
          }}
          title="Campaigns"
          handlePagination={handlePagination}
          // headers={['Name', 'Channel', 'Total Recipients', 'Created Date', 'Created Time', 'Created By']}
          headers={[
            { name: 'Name', sortEnabled: false },
            { name: 'Channel', sortEnabled: false },
            { name: 'Total Recipients', sortEnabled: false },
            { name: 'Created Date & Time', sortEnabled: false },
            { name: 'Created By', sortEnabled: false },
          ]}
          hasDetails
          data={instantCampaignDetails?.campaigns}
          totalCount={instantCampaignDetails?.count}
          filterOptions={config.CHANNEL_LIST}
          renderDetailsButton={renderDetailButton}
          isFilterEnabled
          isSearchEnabled
          currentPage={pagination.offset}
          currentLimit={pagination.limit}
          defaultFilter={campaignFilter}
          selected={selected}
          loadRowDetails={enableRowDetails && loadRowDetails()}
          enableRowDetails={enableRowDetails}
          renderCustomCells
          renderCells={renderCells}
          searchKey={searchText}
          searchCallback={(text) => handleSearch(text)}
          searchPlaceholderText="Search by name"
          isDateFilter
          selectedDateObj={(date) => onDateChange(date)}
          dateFilterLabel={`${moment(
            CalculateUTCTzToUserTz(selectedDate.startDate)
          ).format('MM-DD-YYYY')} - ${moment(
            CalculateUTCTzToUserTz(selectedDate.endDate)
          ).format('MM-DD-YYYY')}`}
          isRefreshEnabled
          refreshCallback={() => {
            onRefresh()
          }}
          isSortEnabled
          handleSort={handleSort}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  instantCampaignDetails: state.instantCampaign.campaigns,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInstantCampaignList: (limit, offset, search, filter) =>
      dispatch(
        instantCampaignActions.getInstantCampaignList(
          limit,
          offset,
          search,
          filter
        )
      ),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)

Campaign.propTypes = {
  instantCampaignDetails: PropTypes.object.isRequired,
  fetchInstantCampaignList: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}
