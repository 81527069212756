import request from '../../components/Helper/Request'
import * as billingConstants from './actionTypes'
import { config } from '../../config/config'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export const createBilling = (data) => {
  return (dispatch) => {
    dispatch({
      type: billingConstants.CREATE_BILLING,
    })
    request({
      method: 'post',
      url: `${config.API_URL}/api/billingcode/create`,
      data: {
        ...data,
        createdBy: userDetails.emailId,
        modifiedBy: userDetails.emailId,
      },
    })
      .then((response) => {
        dispatch({
          type: billingConstants.CREATE_BILLING_SUCCESS,
          payload: response,
        })
        dispatch({
          type: billingConstants.RESET_CREATE_BILLING_STATE,
        })
      })
      .catch((error) => {
        dispatch({
          type: billingConstants.CREATE_BILLING_FAILURE,
          payload: error,
        })
      })
  }
}

export const getBillingDashboardData = (data) => {
  return (dispatch) => {
    dispatch({
      type: billingConstants.FETCH_BILLING_DATA,
    })
    request({
      method: 'post',
      url: `${config.API_URL}/api/billingcode/dashboard`,
      data,
    })
      .then((response) => {
        dispatch({
          type: billingConstants.FETCH_BILLING_DATA_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: billingConstants.FETCH_BILLING_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export const getBillingDashboardFilters = () => {
  return (dispatch) => {
    dispatch({
      type: billingConstants.FETCH_BILLING_DASHBOARD_FILTERS,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/billingcode/dashboard/lov`,
    })
      .then((response) => {
        function addAllOption(array) {
          return array ? [{ value: 'All', key: null }, ...array] : array
        }

        if (response) {
          response.billingCodes = addAllOption(response.billingCodes)
          response.campaigns = addAllOption(response.campaigns)
          response.templates = addAllOption(response.templates)
        }

        dispatch({
          type: billingConstants.FETCH_BILLING_DASHBOARD_FILTERS_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: billingConstants.FETCH_BILLING_DASHBOARD_FILTERS_FAILURE,
          payload: error,
        })
      })
  }
}

export const getBillingCodeLov = () => {
  return (dispatch) => {
    dispatch({
      type: billingConstants.FETCH_BILLING_LOV,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/billingcode/lov`,
    })
      .then((response) => {
        dispatch({
          type: billingConstants.FETCH_BILLING_LOV_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: billingConstants.FETCH_BILLING_LOV_FAILURE,
          payload: error,
        })
      })
  }
}
