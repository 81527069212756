import React, { useState, useMemo } from 'react';
import {
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import request from '../../components/Helper/Request.js';
import { config } from '../../config/config.js';
import Swal from 'sweetalert2';
import Field from '../../components/Field.js';
import CoTextField from '../../controllers/CoTextField/index.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  getPhoneNumberList,
  getChatList,
} from '../../store/actions/twoWayChat';

const AddNewChatModal = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    mobileNo: yup
      .string()
      .required('Enter Mobile Number')
      .matches(/^\+1\d{10}$/, 'Invalid Mobile Number'),
  });

  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mobileNo: '',
      messageContent: '',
    },
  });

  const handleClose = () => {
    onClose();
  };

  const addNewChat = async (data) => {
    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/optout/addNewNumber`,
        data: {
          mobileNo: data.mobileNo,
          messageContent: data.messageContent,
        },
      });
      if (res) {
        handleClose();
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 1000,
          text: 'New Chat Added Successfully',
        });
        dispatch(getPhoneNumberList());
        dispatch(getChatList());
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="view"
      aria-describedby="viewDescription"
    >
      <form onSubmit={handleSubmit(addNewChat)}>
        <DialogTitle id="view">New Text Message</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div
            style={{
              border: '1px solid #C2C2B7',
              padding: '2rem',
              minHeight: '15rem',
            }}
          >
            <Box>
              <Field
                label="To"
                titleVariant="h7"
                required
                error={errors?.mobileNo?.message}
                showError={errors?.mobileNo?.message}
              >
                <CoTextField
                  name="mobileNo"
                  control={control}
                  placeholder="Enter Mobile Number"
                />
              </Field>
            </Box>
            <Box mt={2}>
              <Field
                label="Text"
                titleVariant="h7"
                // required
                // error={errors?.messageContent?.message}
                // showError={errors?.messageContent?.message}
              >
                <CoTextField
                  name="messageContent"
                  control={control}
                  placeholder="Enter Content here"
                  multiline
                  rows={4}
                />
              </Field>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Discard
          </Button>
          <Button variant="contained" endIcon={<SendIcon />} type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddNewChatModal;
