import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import { useMsal } from '@azure/msal-react'

import Header from './Header'
import idleTimer from '../components/Helper/idleTimer'

const Content = styled.div``

const ContentWrapper = styled.div`
  background: #ecf6f4;
`

const TenantLayout = (props) => {
  const { children } = props
  const { instance } = useMsal()

  useEffect(() => {
    idleTimer(instance)
  }, [])

  return (
    <ContentWrapper>
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <Header showLogo isTenant />
          <div className="content-wrapper">
            <Content role="main">{children}</Content>
          </div>
        </Grid>
      </Grid>
    </ContentWrapper>
  )
}

TenantLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default TenantLayout
