import React, { useEffect, useState, useRef } from 'react'
import { Grid, TextField, Autocomplete, IconButton } from '@mui/material'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

import TableCell from '@mui/material/TableCell'
import { MultiSelect } from 'react-multi-select-component'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import FormControl from '@mui/material/FormControl'

import * as Styled from '../components/styled-components/Campaign'
import DefaultTable from '../components/Table'
import SkeletonGridLoader from '../components/SkeletonGridLoader'

import * as analyticsActions from '../store/actions/analytics'
import * as menuActions from '../store/actions/breadcrumb'

import SpinningLoader from '../components/SpinningLoader'
import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent'

import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
  splitDate,
  splitTime,
  formatDTADate,
} from '../components/Utils'
import appConfig from '../config/appConfig'

const dropDownlistCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  border: `1px solid ${appConfig.colors.darkblue}`,
  borderRadius: '5px',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px' },
}
const calenderCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px' },
}

const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } }

const mb = {
  marginBottom: 0,
  fontWeight: 'bold',
  color: appConfig.colors.darkgray,
}

const InvalidReports = ({
  analyticsData,
  fetchInvalidReportData,
  fetchInvalidFiltersData,
  exportInvalidReportsData,
  setBreadcrumb,
}) => {
  const { handleSubmit } = useForm()
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })

  const filterInitialState = {
    channels: { key: 'SMS', value: 'SMS' },
    files: [],
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate?.toISOString()),
    endDate: CalculateUserTzToUTCTz(selectedDate?.endDate?.toISOString()),
  }
  const [filterData, setFilterData] = useState(filterInitialState)

  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [searchText, setSearchText] = useState('')
  const isMounted = useRef(false)
  const [fileId, setFileId] = useState(null)
  const [selected, setSelected] = useState(null)
  const [enableRowDetails, setEnableRowDetails] = useState(false)
  const [invalidData, setInvalidData] = useState('')

  const ChannelList = [
    { key: 'SMS', value: 'SMS' },
    { key: 'Email', value: 'Email' },
  ]

  const fileList = [...(analyticsData?.invalidFilters?.data?.files || [])]

  const handleSearch = (search) => {
    isMounted.current = false
    setSearchText(search)
  }

  const handleFilter = (data, input) => {
    switch (input) {
      case 'file':
        setFilterData({
          ...filterData,
          files: data,
        })
        break
      case 'channel':
        setFilterData({
          ...filterData,
          channels: { key: data.label, value: data.id },
          templates: [],
          statuses: [],
          languages: [],
          files: [],
        })
        setPagination({ limit: 10, offset: 1 })
        break

      case 'date':
        setFilterData({
          ...filterData,
          endDate: CalculateUserTzToUTCTz(
            data?.[0]?.endDate
              ? endOfDay(data?.[0]?.endDate)
              : endOfDay(data?.endDate)
          ),
          startDate: CalculateUserTzToUTCTz(
            data?.[0]?.startDate || startOfDay(data?.startDate)
          ),
          templates: [],
          statuses: [],
          languages: [],
          files: [],
        })
        break

      default:
        setFilterData({
          ...filterData,
        })
    }
  }

  const filterValues = {
    filterBy: filterData.channels.value,
    fileId:
      filterData.files.map((f) => f.value).length > 0
        ? filterData.files.map((f) => f.value)
        : '',
    startDate: filterData.startDate.toISOString(),
    endDate: filterData.endDate.toISOString(),
  }

  const handleExport = () => {
    exportInvalidReportsData(filterValues)
  }

  useEffect(() => {
    setBreadcrumb('Error Log')
    setFilterData(filterInitialState)
  }, [])

  useEffect(() => {
    isMounted.current = true
    fetchInvalidFiltersData(filterValues)
    fetchInvalidReportData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }, [filterData.channels, filterData.startDate, filterData.endDate])

  useEffect(() => {
    fetchInvalidReportData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }, [JSON.stringify(pagination)])

  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchInvalidReportData(
        pagination.limit,
        pagination.offset,
        filterValues,
        searchText
      )
    }
  }, [searchText])

  const onSubmit = () => {
    console.log(filterValues)
    fetchInvalidReportData(pagination.limit, pagination.offset, filterValues)
  }

  const onRefresh = () => {
    setPagination({ offset: 1, limit: 10 })
    setSearchText('')
    fetchInvalidReportData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.fileSid ? row.fileSid : '-'}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.broadcastTime)))}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.broadcastTime))}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          <div
            style={{ maxWidth: '250px', wordWrap: 'break-word' }}
            title={row?.fileDetails?.originalFileName}
          >
            {row?.fileDetails?.originalFileName}
          </div>
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.templateCode ? row.templateCode : '-'}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {filterData.channels.key === 'Email' ? row.emailId : row.mobileNo}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.fileSentDate
            ? formatDTADate(splitDate(CalculateUTCTzToUserTz(row.fileSentDate)))
            : '-'}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.language}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.validationErrorMessage}
        </TableCell>
      </>
    )
  }
  const onClear = () => {
    const filterInitValues = {
      filterBy: filterInitialState.channels.value,
      fileId: '',
      startDate: CalculateUserTzToUTCTz(startOfDay(new Date())).toISOString(),
      endDate: CalculateUserTzToUTCTz(endOfDay(new Date())).toISOString(),
    }
    setFilterData(filterInitialState)
    setSelectedDate({
      startDate: CalculateUserTzToUTCTz(startOfDay(new Date())),
      endDate: CalculateUserTzToUTCTz(endOfDay(new Date())),
    })
    fetchInvalidFiltersData(filterInitValues)
    fetchInvalidReportData(
      pagination.limit,
      pagination.offset,
      filterInitValues,
      searchText
    )
  }

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const handleSort = (sortValue) => {
    console.log('sort', sortValue)
  }
  const renderDetailButton = (row) => {
    return (
      <div>
        <IconButton
          className="pad-0"
          onClick={() => {
            setEnableRowDetails(fileId !== row.id ? true : !enableRowDetails)
            setFileId(row.id)
            setSelected(row.id)
            setInvalidData(row.decodedMessage.invalidData)
          }}
          title="View Details"
          aria-label="view details"
          size="small"
        >
          {fileId === row.id && enableRowDetails ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
              fontSize="inherit"
            />
          )}
        </IconButton>
      </div>
    )
  }

  const loadRowDetails = () => {
    return (
      <p>
        <b>Data in file: </b> {invalidData}
      </p>
    )
  }
  useEffect(() => {
    loadRowDetails()
  }, [fileId])

  return (
    <div>
      <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className="page-heading">
          <b>Error Log</b>
        </h1>
      </Grid>
      <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />

      {analyticsData?.invalidFilters?.isLoading ? (
        <SpinningLoader
          label="Loading Filters..."
          circleColor={appConfig.colors.darkblue}
        />
      ) : (
        <div className="filter-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Communication Channel</p>
                <FormControl sx={dropDownlistCss}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    openOnFocus
                    clearOnBlur
                    className="autocomplete-class"
                    defaultValue={
                      filterData.channels.key
                        ? filterData.channels.key
                        : 'Select Channel'
                    }
                    options={ChannelList.map((item) => ({
                      label: item.value,
                      id: item.key,
                    }))}
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          'aria-label': 'channel',
                        },
                      }
                      return <TextField {...newParams} />
                    }}
                    onChange={(e, val) => {
                      handleFilter(val, 'channel')
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Date</p>
                <FormControl sx={calenderCss}>
                  <DateRangePickerComponent
                    label={`${moment(
                      CalculateUTCTzToUserTz(filterData.startDate)
                    ).format('MM-DD-YYYY')} - ${moment(
                      CalculateUTCTzToUserTz(filterData.endDate)
                    ).format('MM-DD-YYYY')}`}
                    cssStyle={{
                      color: '#111',
                      fontSize: '15px',
                      border: '1px solid #bbb',
                      justifyContent: 'flex-start',
                    }}
                    selectedDateObj={(date) => {
                      handleFilter(date, 'date')
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4} md={4} xl={4} sx={gridItemCss}>
                <p style={mb}>By File</p>
                <FormControl sx={dropDownlistCss}>
                  <MultiSelect
                    ArrowRenderer={ArrowDropDownIcon}
                    options={fileList.map((item) => ({
                      label: item.value,
                      value: item.key,
                    }))}
                    value={filterData.files}
                    className="multi-select"
                    onChange={(data) => handleFilter(data, 'file')}
                    labelledBy="Select File"
                    ClearSelectedIcon={null}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={1} md={1} xl={1} sx={gridItemCss}>
                <FormControl sx={dropDownlistCss} className="filter-btn">
                  <Styled.CampaignButton type="submit">
                    Apply
                  </Styled.CampaignButton>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={1} md={1} xl={1} sx={gridItemCss}>
                <FormControl sx={dropDownlistCss} className="filter-btn">
                  <Styled.CancelButton
                    sx={{ padding: '5px' }}
                    onClick={() => {
                      onClear()
                    }}
                  >
                    Clear
                  </Styled.CancelButton>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          {analyticsData?.invalidReports?.isLoading ||
          analyticsData?.invalidFilters?.isLoading ? (
            <SkeletonGridLoader />
          ) : (
            <DefaultTable
              title="Total Records"
              handlePagination={handlePagination}
              // headers={['Message ID', 'Broadcast Date & Time' , 'File Name', 'Template Code', filterData.channels.key === "Email" ? 'Email' : 'Mobile', 'File Sent Date' , 'Language', 'Reason']}
              headers={[
                { name: 'Message ID', sortEnabled: false },
                { name: 'Broadcast Date & Time', sortEnabled: false },
                { name: 'File Name', sortEnabled: false },
                { name: 'Template Code', sortEnabled: false },
                {
                  name:
                    filterData.channels.key === 'Email' ? 'Email' : 'Mobile',
                  sortEnabled: false,
                },
                { name: 'File Sent Date', sortEnabled: false },
                { name: 'Language', sortEnabled: false },
                { name: 'Reason', sortEnabled: false },
              ]}
              data={analyticsData?.invalidReports?.data}
              totalCount={analyticsData?.invalidReports?.count}
              currentPage={pagination.offset}
              currentLimit={pagination.limit}
              isSearchEnabled
              searchCallback={(text) => handleSearch(text)}
              searchKey={searchText}
              searchPlaceholderText={
                filterData.channels.key === 'Email'
                  ? 'Search by Email/message ID'
                  : 'Search by mobile/message ID'
              }
              renderCustomCells
              hasAction={false}
              renderCells={renderCells}
              isExportEnabled
              exportCallback={handleExport}
              hasDetails
              renderDetailsButton={renderDetailButton}
              selected={selected}
              enableRowDetails={enableRowDetails}
              loadRowDetails={enableRowDetails && loadRowDetails()}
              isRefreshEnabled
              refreshCallback={() => {
                onRefresh()
              }}
              isSortEnabled
              handleSort={handleSort}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({ analyticsData: state.analytics })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvalidReportData: (limit, page, data, searchText) =>
      dispatch(
        analyticsActions.getInvalidReportsData(limit, page, data, searchText)
      ),
    fetchInvalidFiltersData: (data) =>
      dispatch(analyticsActions.getInvalidFiltersData(data)),
    exportInvalidReportsData: (data, searchText) =>
      dispatch(analyticsActions.exportInvalidReportsData(data, searchText)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvalidReports)

InvalidReports.propTypes = {
  fetchInvalidReportData: PropTypes.func.isRequired,
  fetchInvalidFiltersData: PropTypes.func.isRequired,
  exportInvalidReportsData: PropTypes.func.isRequired,
  analyticsData: PropTypes.objectOf(PropTypes.object).isRequired,
  key: PropTypes.objectOf(PropTypes.object).isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}
