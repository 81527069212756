import React, { useMemo, useState } from 'react'
import { Button, Grid, IconButton, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import MultiStepper from '../../../components/MultiStepper'
import Template from '../../Template.jsx'
import CampaignNew from '../../CampaignNew/CampaignNew'
import ScheduleCampaign from '../../ScheduleCampaign'
import UploadFiles from '../../UploadFiles.jsx'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'

const CreateCampaignNew = ({ setComponentShow }) => {
  const [campaignId, setCampaignId] = useState(-1)
  const [currentStep, setCurrentStep] = useState(1)
  const [channel, setChannel] = useState('')
  const [templateId, setTemplateId] = useState('')
  const [templateRes, setTemplateRes] = useState('')
  const [params, setParams] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [disableCampaign, setDisableCampaign] = useState(false)
  const [disableTemplate, setDisableTemplate] = useState(false)
  const [disableSchedule, setDisableSchedule] = useState(false)
  const [templateCode, setTemplateCode] = useState('')
  const navigate = useNavigate()

  const stages = useMemo(() => {
    return [
      { title: 'Campaign', done: currentStep === 1 },
      { title: 'Template', done: currentStep === 2 },
      { title: 'Schedule', done: currentStep === 3 },
      // { title: 'Upload Files', done: currentStep === 4 },
    ]
  }, [currentStep])

  return (
    <>
      <Grid display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => {
            setComponentShow(false)
            navigate('/dashboard')
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <MultiStepper steps={stages} currentStep={currentStep} />
      <Stack sx={{ backgroundColor: 'white', p: '1rem' }}>
        {currentStep === 1 && (
          <CampaignNew
            setCampaignId={setCampaignId}
            setCurrentStep={setCurrentStep}
            campaignId={campaignId}
            disableCampaign={disableCampaign}
          />
        )}

        {currentStep === 2 && (
          <Template
            campaignId={campaignId}
            setCurrentStep={setCurrentStep}
            setChannel={setChannel}
            setTemplateId={setTemplateId}
            setTemplateRes={setTemplateRes}
            setParams={setParams}
            templateId={templateId}
            setTemplateName={setTemplateName}
            setDisableCampaign={setDisableCampaign}
            disableTemplate={disableTemplate}
            setTemplateCode={setTemplateCode}
          />
        )}

        {currentStep === 3 && (
          <ScheduleCampaign
            campaignId={campaignId}
            setCurrentStep={setCurrentStep}
            setComponentShow={setComponentShow}
            templateRes={templateRes.code}
            setDisableTemplate={setDisableTemplate}
            disableSchedule={disableSchedule}
          />
        )}
        {/* {currentStep === 4 && (
          <UploadFiles
            channel={channel}
            setCurrentStep={setCurrentStep}
            templateId={templateId}
            campaignId={campaignId}
            params={params}
            templateName={templateName}
            setComponentShow={setComponentShow}
            setDisableSchedule={setDisableSchedule}
            templateCode={templateCode}
          />
        )} */}
      </Stack>
    </>
  )
}
CreateCampaignNew.propTypes = {
  setComponentShow: PropTypes.bool,
}
CreateCampaignNew.defaultProps = {
  setComponentShow: false,
}
export default CreateCampaignNew
