import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import PropTypes from 'prop-types'

const RadioButtonGroup = ({
  header,
  value,
  labels,
  handleChange,
  className,
  defaultValue,
}) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel id="demo-controlled-radio-buttons-group">{header}</FormLabel>
      <RadioGroup
        row
        aria-label="radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
        className={className}
        defaultValue={defaultValue}
      >
        {labels.map((item) => {
          return (
            <FormControlLabel
              key={item}
              value={item}
              control={<Radio />}
              label={item}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

RadioButtonGroup.defaultProps = {
  className: {},
  defaultValue: '',
}

RadioButtonGroup.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.object,
  defaultValue: PropTypes.string,
}

export default RadioButtonGroup
