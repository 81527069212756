import * as instantCampaignConstants from './actionTypes'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export const getInstantCampaignList = (limit, page, search, filter) => {
  return (dispatch) => {
    dispatch({
      type: instantCampaignConstants.FETCH_INSTANT_CAMPAIGNS_LIST,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/instant/list/${userDetails?.accountId}/${userDetails?.userId}`,
      data: {
        limit,
        page,
        search,
        filterBy: filter.filterBy,
        startDate: filter.startDate,
        endDate: filter.endDate,
      },
    })
      .then((response) => {
        dispatch({
          type: instantCampaignConstants.FETCH_INSTANT_CAMPAIGNS_LIST_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: instantCampaignConstants.FETCH_INSTANT_CAMPAIGNS_LIST_FAILURE,
          payload: error,
        })
      })
  }
}

export const createInstantCampaign = (instantCampaignData) => {
  return (dispatch) => {
    dispatch({
      type: instantCampaignConstants.CREATE_INSTANT_CAMPAIGN,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/instant/create`,
      data: instantCampaignData,
    })
      .then((response) => {
        dispatch({
          type: instantCampaignConstants.CREATE_INSTANT_CAMPAIGN_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        console.log('error', error)
        dispatch({
          type: instantCampaignConstants.CREATE_INSTANT_CAMPAIGN_FAILURE,
        })
      })
  }
}
