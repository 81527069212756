import * as actionTypes from '../actions/actionTypes'

const initialState = {
  createBilling: {
    data: {},
    count: '',
    isLoading: false,
    isError: false,
  },
  dashboard: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
  billingLov: {
    data: [],
    isLoading: false,
    isError: false,
  },
  dashboardLov: {
    data: [],
    isLoading: false,
    isError: false,
  },
}

const billingReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.CREATE_BILLING:
      return {
        ...newState,
        createBilling: { ...newState.createBilling, isLoading: true },
      }

    case actionTypes.CREATE_BILLING_SUCCESS:
      return {
        ...newState,
        createBilling: {
          data: action.payload,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.CREATE_BILLING_FAILURE:
      return { ...newState, createBilling: { isLoading: false, isError: true } }

    case actionTypes.RESET_CREATE_BILLING_STATE:
      return {
        ...newState,
        createBilling: { data: {}, isLoading: false, isError: false },
      }

    case actionTypes.FETCH_BILLING_DATA:
      return {
        ...newState,
        dashboard: { ...newState.dashboard, isLoading: true },
      }

    case actionTypes.FETCH_BILLING_DATA_SUCCESS:
      return {
        ...newState,
        dashboard: { data: action.payload, isLoading: false, isError: false },
      }

    case actionTypes.FETCH_BILLING_DATA_FAILURE:
      return { ...newState, dashboard: { isLoading: false, isError: true } }

    case actionTypes.FETCH_BILLING_LOV:
      return {
        ...newState,
        billingLov: { ...newState.billingLov, isLoading: true },
      }

    case actionTypes.FETCH_BILLING_LOV_SUCCESS:
      return {
        ...newState,
        billingLov: {
          data: action.payload.data,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_BILLING_LOV_FAILURE:
      return { ...newState, billingLov: { isLoading: false, isError: true } }

    case actionTypes.FETCH_BILLING_DASHBOARD_FILTERS:
      return {
        ...newState,
        dashboardLov: { ...newState.dashboardLov, isLoading: true },
      }

    case actionTypes.FETCH_BILLING_DASHBOARD_FILTERS_SUCCESS:
      return {
        ...newState,
        dashboardLov: {
          data: action.payload,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_BILLING_DASHBOARD_FILTERS_FAILURE:
      return { ...newState, dashboardLov: { isLoading: false, isError: true } }

    default:
      return { ...newState }
  }
}

export default billingReducer
