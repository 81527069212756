import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Stack, Typography } from '@mui/material'
import styles from './index.module.css'
import CountByStatus from '../../../components/CountByStatus'
import { ReactComponent as ReadIcon } from '../../../assets/read.svg'
import { ReactComponent as PendingIcon } from '../../../assets/pending-new.svg'
import { ReactComponent as DeliverdIcon } from '../../../assets/delivered.svg'
import { ReactComponent as InvalidIcon } from '../../../assets/email-invalid.svg'
import { ReactComponent as Cancelledcon } from '../../../assets/cancel.svg'
import { ReactComponent as NotsendIcon } from '../../../assets/not-send.svg'

const Statistics = (props) => {
  const numberWithCommas = (number) => {
    return number?.toLocaleString()
  }
  const commonStatistics = props?.commonStatistic

  const deliveredPercentage = commonStatistics?.delivered
    ? (commonStatistics.delivered / commonStatistics.totalCount) * 100
    : 0
  const sentPercentage = commonStatistics?.sent
    ? (commonStatistics.sent / commonStatistics.totalCount) * 100
    : 0
  const pendingPercentage = commonStatistics?.pending
    ? ((commonStatistics.pending + commonStatistics.queued) /
        commonStatistics.totalCount) *
      100
    : 0
  const notdeliveredPercentage = commonStatistics?.undelivered
    ? ((commonStatistics.undelivered + commonStatistics.failed) /
        commonStatistics.totalCount) *
      100
    : 0
  const invalidPercentage = commonStatistics?.invalid
    ? (commonStatistics.invalid / commonStatistics.totalCount) * 100
    : 0
  const cancelledPercentage = commonStatistics?.cancelled
    ? (commonStatistics.cancelled / commonStatistics.totalCount) * 100
    : 0

  const pending =
    commonStatistics?.pending + commonStatistics?.queued
      ? commonStatistics?.pending + commonStatistics?.queued
      : 0

  const notDelivered =
    commonStatistics?.undelivered + commonStatistics?.failed
      ? commonStatistics?.undelivered + commonStatistics?.failed
      : 0

  const eachCountBlock = [
    {
      count: numberWithCommas(commonStatistics?.sent),
      color: 'green',
      icon: <ReadIcon />,
      text: `${props?.labels} Sent`,
      percentage: sentPercentage,
    },
    {
      count: numberWithCommas(pending),
      color: '#5f508a',
      icon: <PendingIcon />,
      text: `${props?.labels} Pending`,
      percentage: pendingPercentage,
    },
    {
      count: numberWithCommas(commonStatistics?.delivered),
      color: '#53a8e5',
      icon: <DeliverdIcon />,
      text: `${props?.labels} Delivered`,
      percentage: deliveredPercentage,
    },
    {
      count: numberWithCommas(notDelivered),
      color: '#f32c2c',
      icon: <NotsendIcon />,
      text: `${props?.labels} Not Delivered`,
      percentage: notdeliveredPercentage,
    },
    {
      count: numberWithCommas(commonStatistics?.invalid),
      color: '#780c81',
      icon: <InvalidIcon />,
      text: `${props?.labels} Invalid`,
      percentage: invalidPercentage,
    },
    {
      count: numberWithCommas(commonStatistics?.cancelled),
      color: '#6c6c6c',
      icon: <Cancelledcon />,
      text: `${props?.labels} Cancelled`,
      percentage: cancelledPercentage,
    },
  ]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container className={styles.container} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'center',
            },
            justifyContent: 'center',
            gap: '12px',
            boxShadow: 'inset -3px 0 3px -3px rgba(0, 0, 0, 0.5)',
          }}
        >
          <Stack
            sx={{
              paddingBottom: '1rem',
              margin: '1rem 0 1rem 1rem',
              display: 'flex',
              alignItems: 'center',
            }}
            spacing={1}
          >
            <span className={styles.count}>
              {numberWithCommas(commonStatistics?.totalCount)}
            </span>
            <span className={styles.total}>Total {props?.labels}</span>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid container sx={{ padding: '0px 0px 0px 20px', rowGap: '4rem' }}>
            {eachCountBlock.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <CountByStatus
                    count={item.count}
                    statusText={item.text}
                    color={item.color}
                    icon={item.icon}
                    percentage={item.percentage}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

Statistics.propTypes = {}

export default Statistics
