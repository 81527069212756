import { Box, Stack, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import styles from './index.module.css';

const DoughNut = (props) => {
  const { data } = props;
  const hasData = data && data.length > 0;

  const chartData = hasData
    ? {
        labels: [
          'English',
          'Spanish',
          'Vietnamese',
          'Portugese',
          'Haitian Creole',
          'Chinese',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: data,
            backgroundColor: [
              '#73985E',
              '#B42626',
              '#44A0E3',
              '#5F508A',
              '#E8A908',
              '#0F4E96',
            ],
            borderWidth: 0,
            hoverOffset: 4,
            hoverBorderWidth: 0,
          },
        ],
      }
    : null;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: 'white',
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
      },
    },
  };

  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
      className={styles.container}
      spacing={1}
    >
      {hasData && (
        <>
          <Box sx={{ width: '200px' }}>
            <Doughnut data={chartData} options={options} />
          </Box>

          <Stack direction="row" spacing={1}>
            <Box sx={{ ml: '1rem' }}>
              <Typography
                style={{ '--color': '#73985E' }}
                className={styles.lang}
              >
                English
              </Typography>
              <Typography
                style={{ '--color': '#B42626' }}
                className={styles.lang}
              >
                Spanish
              </Typography>
              <Typography
                style={{ '--color': '#44A0E3' }}
                className={styles.lang}
              >
                Vietnamese
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{ '--color': '#5F508A' }}
                className={styles.lang}
              >
                Portugese
              </Typography>
              <Typography
                style={{ '--color': '#E8A908' }}
                className={styles.lang}
              >
                Haitian Creole
              </Typography>
              <Typography
                style={{ '--color': '#0F4E96' }}
                className={styles.lang}
              >
                Chinese
              </Typography>
            </Box>
          </Stack>
        </>
      )}
      {!hasData && (
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          No data found
        </Typography>
      )}
    </Stack>
  );
};

export default DoughNut;
