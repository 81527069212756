import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Switch,
  TextField,
  Backdrop,
} from '@mui/material'
import {
  BorderBottom,
  BorderStyle,
  ModeEditOutlineOutlined,
} from '@mui/icons-material'
import PropTypes, { object } from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import { connect } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import { endOfDay } from 'date-fns'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CreateCampaign from './Dashboard/CreateCampaign'
import DoughNut from '../components/DoughNut/DoughNut'
import TabularView from '../components/TabularView'
import CountByStatus from '../components/CountByStatus'
import Statistics from './Dashboard/Statistics/Statistics'
import styles from '../components/DoughNut/index.module.css'
import Field from '../components/Field'
import CreateIcon from '@mui/icons-material/Create'
import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent'
import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
} from '../components/Utils'
import request from '../components/Helper/Request'
import { config } from '../config/config'
import { getDashboardDetails } from '../store/actions/dashboard'
import * as analyticsActions from '../store/actions/analytics'
import * as menuActions from '../store/actions/breadcrumb'
import CoTextField from '../controllers/CoTextField'
import dayjs from 'dayjs'
import { filter, last } from 'lodash'
import appConfig from '../config/appConfig'
import SpinningLoader from '../components/SpinningLoader'
import Swal from 'sweetalert2'

const upcomingEvents = [
  {
    id: '1234567',
    segment: 'Seg Name',
    name: 'Campaign Name',
    scheduledAt: '10/10/2023',
    time: '20:03',
  },
  {
    id: '1234567',
    segment: 'Seg Name',
    name: 'Campaign Name',
    scheduledAt: '10/10/2023',
    time: '20:03',
  },
  {
    id: '1234567',
    segment: 'Seg Name',
    name: 'Campaign Name',
    scheduledAt: '10/10/2023',
    time: '20:03',
  },
  {
    id: '1234567',
    segment: 'Seg Name',
    name: 'Campaign Name',
    scheduledAt: '10/10/2023',
    time: '20:03',
  },
  {
    id: '1234567',
    segment: 'Seg Name',
    name: 'Campaign Name',
    scheduledAt: '10/10/2023',
    time: '20:03',
  },
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

TabPanel.defaultProps = {
  children: null,
}

const DashboardNew = ({
  fetchDashboardDetails,
  dashboardDetails,
  fetchFiltersData,
  analyticsData,
  setBreadcrumb,
}) => {
  const [componentShow, setComponentShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [campaignType, setCampaignType] = useState(null)
  const [response, setResponse] = useState('')
  const [mailResponse, setMailResponse] = useState('')
  const [dashboardInfo, setDashboardDetails] = useState(
    dashboardDetails.dashboardDetails
  )
  const [isLoading, setIsLoading] = useState(false)

  const filterInitialState = {
    channel: { key: 'SMS', value: 'SMS' },
    templateCode: { key: 'All Templates', value: '' },
    campaignName: { key: 'All Campaigns', value: '' },
    startDate: CalculateUserTzToUTCTz(
      new Date().setDate(new Date().getDate() - 30)
    ),
    endDate: CalculateUserTzToUTCTz(endOfDay(new Date()).toISOString()),
  }

  const [filterData, setFilterData] = useState(filterInitialState)

  const onTabChange = (e, value) => {
    setActiveTab(value)
  }

  const handleButtonClick = () => {
    setComponentShow((prevShow) => !prevShow)
  }

  const handleChange = (event) => {
    setCampaignType(event.target.value)
  }

  const tabStyles = {
    fontFamily: 'Open Sans !important',
    fontSize: '18px',
  }

  const subtitle = {
    fontFamily: 'Roboto ',
    fontSize: '12px',
    fontWeight: '600',
    color: '#6c6c6c',
  }

  const dateStyle = {
    background: '#FFF',
    // marginLeft: '5px',
    marginRight: '5px',
    textTransform: 'capitalize',
    borderColor: '#005974',
    color: '#000',
    height: '38px',
    padding: '0 30px 0 10px',
  }

  const filterHandleChange = (data, val, id) => {
    switch (id) {
      case 'channel':
        setFilterData({
          ...filterData,
          channel: { key: val.label, value: val.id },
          templateCode: { key: 'All Templates', value: '' },
          campaignName: { key: 'All Campaigns', value: '' },
        })

        break
      case 'date':
        setFilterData({
          ...filterData,
          templateCode: { key: 'All Templates', value: '' },
          campaignName: { key: 'All Campaigns', value: '' },
          endDate: CalculateUserTzToUTCTz(endOfDay(data?.[0]?.endDate)),
          startDate: CalculateUserTzToUTCTz(data?.[0]?.startDate),
        })

        break
      case 'template':
        setFilterData({
          ...filterData,
          templateCode: { key: val.label, value: val.id },
        })
        break
      case 'campaign':
        setFilterData({
          ...filterData,
          campaignName: { key: val.label, value: val.id },
        })
        break
      default:
        console.log(data)
    }
  }

  const campaigncode = filterData?.campaignName?.value
  const templateCode = filterData?.templateCode?.value

  const TemplateCodesData = [
    ...(analyticsData?.filters?.data?.templateCodeWithDesc || ''),
  ]

  const TemplateCodes = []
  if (TemplateCodesData.length > 0) {
    TemplateCodes.push({ value: 'All Templates', key: '' })
    TemplateCodesData.forEach((option) => {
      TemplateCodes.push({
        value: `${option.value} (${option.key})`,
        key: option.key,
      })
    })
  }

  const campaignNames = [...(analyticsData?.filters?.data?.campaign || [])]
  if (campaignNames.length > 0) {
    campaignNames.unshift({ value: 'All Campaigns', key: '' })
  }

  const filterValues = {
    filterBy: filterData.channel.value,
    startDate: filterData.startDate.toISOString(),
    endDate: filterData.endDate.toISOString(),
    campaignId: '',
    filterByCampaign: filterData.campaignName.value || '',
    fileId: '',
    language: '',
    statusId: '',
    templateCode: filterData.templateCode.value
      ? filterData.templateCode.value
      : '',
    campaignName: filterData.campaignName.value
      ? filterData.campaignName.value
      : '',
  }

  useEffect(() => {
    setFilterData(filterInitialState)
  }, [])

  useEffect(() => {
    setBreadcrumb('Dashboard')
    fetchFiltersData(filterValues)
    fetchDashboardDetails(filterValues)
    setDashboardDetails(dashboardDetails.dashboardDetails)
  }, [filterData])

  const handleClick = async (params, e) => {
    const payLoad = {
      scheduleId: params.scheduleId,
      dirtyStatus: 'UPDATE',
      isActive: e.target.checked,
      campaignId: params.campaignId,
      scheduleType: params.scheduleType,
      scheduleDate: params.scheduleDate,
      createdBy: params.createdBy,
      modifiedBy: 'Aravinth.Sridharan@kyyba.gov',
    }

    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/campaign/schedule`,
        data: payLoad,
      })
      if (res.status === 1) {
        if (res.isActive === false) {
          Swal.fire({
            icon: 'success',
            button: false,
            showConfirmButton: false,
            timer: 1000,
            text: 'Your scheduled campaign has been disabled.',
          })
          getDashboard_Details()
        } else {
          Swal.fire({
            icon: 'success',
            button: false,
            showConfirmButton: false,
            timer: 1000,
            text: 'Your scheduled campaign has been enabled.',
          })
          getDashboard_Details()
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      // console.log('error');
    }
  }

  const upcomEventColumns = [
    {
      headerName: 'ID',
      field: 'campaignId',
    },
    {
      field: 'campaignName',
      headerName: 'Name',
    },
    {
      headerName: 'Scheduled',
      field: 'scheduledAt',
      renderCell(params) {
        return (
          <Stack>
            <Typography style={{ fontWeight: 'bold' }} variant="outline">
              {dayjs(params.scheduleDate).format('MM-DD-YYYY')}
            </Typography>
            <Typography variant="caption">
              {dayjs(params.scheduleDate).format('LT')}
            </Typography>
          </Stack>
        )
      },
    },

    // {
    //   headerName: 'Edit',
    //   renderCell(params) {
    //     return (
    //       <IconButton>
    //         <CreateIcon />
    //       </IconButton>
    //     );
    //   },
    // },
    {
      headerName: 'Action',
      renderCell(params) {
        return (
          <Switch
            checked={params?.isActive}
            // onClick={handleClick(params)}
            onChange={(e) => handleClick(params, e)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      },
    },
  ]

  const recentCampaignColumns = [
    {
      headerName: 'ID',
      field: 'id',
    },

    {
      headerName: 'Name',
      field: 'campaignName',
    },
    {
      headerName: 'Created',
      renderCell(params) {
        return (
          <Stack>
            <Typography style={{ fontWeight: 'bold' }} variant="outline">
              {dayjs(params.createdTime).format('MM-DD-YYYY')}
            </Typography>
            <Typography variant="caption">
              {dayjs(params.createdTime).format('LT')}
            </Typography>
          </Stack>
        )
      },
    },
    {
      headerName: 'Sent',
      renderCell(params) {
        return (
          <Stack>
            <Typography style={{ fontWeight: 'bold' }} variant="outline">
              {dayjs(params.scheduleDate).format('MM-DD-YYYY')}
            </Typography>
            <Typography variant="caption">
              {dayjs(params.scheduleDate).format('LT')}
            </Typography>
          </Stack>
        )
      },
    },
    // {
    //   headerName: 'Edit',
    //   renderCell(params) {
    //     return (
    //       <IconButton>
    //         <CreateIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ]
  // const lastDate = new Date().toISOString();
  // const beginDate = dayjs().subtract(1, 'week').toISOString();

  const getDashboard_Details = async () => {
    // const url = `${config.API_URL}/api/dashboard/view2/1/139?startDate=2024-02-04T18:30:00.000Z&endDate=${endDate}&filterByChannel=SMS&filterByTemplateCode=&filterByCampaign=&filterByLanguage=`;
    const url = `${
      config.API_URL
    }/api/dashboard/view2/1/139?startDate=${filterData.startDate.toISOString()}&endDate=${filterData.endDate.toISOString()}&filterByChannel=SMS&filterByTemplateCode=${templateCode}&filterByCampaign=${campaigncode}&filterByLanguage=`
    try {
      const res = await request({
        method: 'get',
        url: url,
        headers: {
          Role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
          uid: 'localUser',
          Uniqueid: 'mydhr30bf2179ad6f4d50837443ab3',
          'Content-Type': 'application/json',
        },
      })
      if (res) {
        setResponse(res)
      } else {
        throw new Error('Dashboard API failed')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const scheduled = response?.scheduledCampaigns?.slice(0, 10)
  const recentCampaign = response?.campaignList?.slice(0, 10)
  console.log(response, 'yoloooo')
  const getDashboardMailDetails = async () => {
    const url = `${
      config.API_URL
    }/api/dashboard/view2/1/139?startDate=${filterData.startDate.toISOString()}&endDate=${filterData.endDate.toISOString()}&filterByChannel=Email&filterByTemplateCode=${templateCode}&filterByCampaign=${campaigncode}&filterByLanguage=`
    try {
      const res = await request({
        method: 'get',
        url: url,
        headers: {
          Role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
          uid: 'localUser',
          Uniqueid: 'mydhr30bf2179ad6f4d50837443ab3',
          'Content-Type': 'application/json',
        },
      })
      if (res) {
        setMailResponse(res)
      } else {
        throw new Error('Dashboard API failed')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDashboard_Details()
    getDashboardMailDetails()
  }, [filterData])

  const doughnutData = response?.language
  let { totalCount: langTotalCount, ...rest } = doughnutData ?? {}
  let doughnutChartData = Object.values(rest)
  const smsStatistics = response?.sms
  const emailStatistics = mailResponse?.email
  const emailDoughnut = mailResponse?.language
  let { total: emailTotalCount, ...remaining } = emailDoughnut ?? {}
  let doughnutEmailData = Object.values(remaining)

  return (
    <>
      {isLoading ||
        (analyticsData?.filters?.isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading || analyticsData?.filters?.isLoading}
          >
            <SpinningLoader
              circleColor={appConfig.colors.white}
              label={
                analyticsData?.filters?.isLoading
                  ? 'Campaigns list loading'
                  : 'Creating template'
              }
            />
          </Backdrop>
        ))}
      {!componentShow && (
        <Grid
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          spacing={{ xs: 1, sm: 1, md: 8 }}
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            padding: '8px 12px',
            borderTop: '1px solid #d9d9d9',
            borderBottom: '1px solid #d9d9d9',
          }}
        >
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            spacing={3}
          >
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={8}
              sx={{
                display: 'flex ',
                justifyContent: 'space-between',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                gap: {
                  lg: '.5rem',
                  xs: '0rem',
                  sm: '0rem',
                  mg: '0rem',
                },
              }}
              direction={{ xs: 'column', sm: 'row', md: 'row' }}
            >
              <Stack>
                <span
                  style={{
                    ...subtitle,
                  }}
                >
                  Choose Date Range
                </span>

                <DateRangePickerComponent
                  label={`${moment(
                    CalculateUTCTzToUserTz(filterData.startDate)
                  ).format('MM-DD-YYYY')} - ${moment(
                    CalculateUTCTzToUserTz(filterData.endDate)
                  ).format('MM-DD-YYYY')}`}
                  cssStyle={dateStyle}
                  selectedDateObj={(date) => {
                    filterHandleChange(date, '', 'date')
                  }}
                />
              </Stack>
              <Stack>
                <span
                  style={{
                    ...subtitle,
                  }}
                >
                  Choose Campaign
                </span>

                <div>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    disabled={
                      analyticsData?.filters?.isLoading ||
                      campaignNames.length === 0
                    }
                    openOnFocus
                    className="autocomplete-class"
                    value={filterData.campaignName.key}
                    noOptionsText="Templates not found"
                    options={campaignNames.map((item) => ({
                      label: item.value,
                      id: item.key,
                    }))}
                    onChange={(e, val) => {
                      filterHandleChange(e, val, 'campaign')
                    }}
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          'aria-label': 'campaign name',
                        },
                      }
                      return (
                        <TextField
                          {...newParams}
                          sx={{
                            width: '15rem',
                            paddingBottom: '0px',
                            '& .MuiAutocomplete-input': {
                              padding: '5px 4px 7.5px 5px !important',
                            },
                            '& .MuiOutlinedInput-root': {
                              padding: '2px !important',
                            },
                          }}
                        />
                      )
                    }}
                  />
                </div>
              </Stack>
              <Stack>
                <span
                  style={{
                    ...subtitle,
                  }}
                >
                  Choose Template
                </span>

                <div>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    disabled={
                      analyticsData?.filters?.isLoading ||
                      TemplateCodes.length === 0
                    }
                    openOnFocus
                    className="autocomplete-class"
                    value={filterData.templateCode.key}
                    noOptionsText="Templates not found"
                    options={TemplateCodes.map((item) => ({
                      label: item.value,
                      id: item.key,
                    }))}
                    onChange={(e, val) => {
                      filterHandleChange(e, val, 'template')
                    }}
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          'aria-label': 'template code',
                        },
                      }
                      return (
                        <TextField
                          {...newParams}
                          sx={{
                            width: '15rem',
                            '& .MuiAutocomplete-input': {
                              padding: '5px 4px 7.5px 5px !important',
                            },
                            '& .MuiOutlinedInput-root': {
                              padding: '2px !important',
                            },
                          }}
                        />
                      )
                    }}
                  />
                </div>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Button
                style={{
                  padding: '5px',
                  // width: '175px !important',
                  height: '40px',
                  backgroundColor: '#5f508a',
                  color: '#fff',
                  borderRadius: '6px',
                  gap: '6px',
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: '#5f508a',
                  },
                  textWrap: 'nowrap',
                }}
                onClick={handleButtonClick}
              >
                <ControlPointOutlinedIcon />
                Create Campaign
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {componentShow ? (
        <Box sx={{ p: '1.5rem 0.5rem', backgroundColor: '#f2f2f2' }}>
          <CreateCampaign setComponentShow={setComponentShow} />
        </Box>
      ) : (
        <Box sx={{ p: '1.5rem 0.5rem', backgroundColor: '#fff' }}>
          <Tabs onChange={onTabChange} value={activeTab} variant="scrollable">
            <Tab
              label="SMS"
              style={{
                ...tabStyles,
                color: activeTab === 0 ? '#000' : '#6C6C6C',
                fontWeight: activeTab === 0 ? 700 : 600,
              }}
            />
            <Tab
              label="Email"
              style={{
                ...tabStyles,
                textTransform: 'capitalize',
                color: activeTab === 1 ? '#000' : '#6C6C6C',
                fontWeight: activeTab === 1 ? 700 : 600,
              }}
            />
            {/* <Tab
              label="Voice"
              style={{
                ...tabStyles,
                textTransform: 'capitalize',
                color: activeTab === 2 ? '#000' : '#6C6C6C',
                fontWeight: activeTab === 2 ? 700 : 600,
              }}
            /> */}
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Box>
              <Grid container spacing={2} sx={{ padding: '1rem' }}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    SMS Statistics
                  </Typography>
                  <Statistics commonStatistic={smsStatistics} labels="SMS" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Language Statistics
                  </Typography>
                  <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <DoughNut data={doughnutChartData} />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: '0 1rem',
                }}
              >
                <Grid item xs={12} md={12} lg={6}>
                  {scheduled?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Upcoming Event
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                          // marginLeft: '-16px',
                        }}
                      >
                        <TabularView
                          columns={upcomEventColumns}
                          data={scheduled}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  {recentCampaign?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Recent Campaign
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                        }}
                      >
                        <TabularView
                          columns={recentCampaignColumns}
                          data={recentCampaign}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Box>
              <Grid container spacing={2} sx={{ padding: '1rem' }}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Email Statistics
                  </Typography>

                  <Statistics
                    commonStatistic={emailStatistics}
                    labels="Email"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Language Statistics
                  </Typography>
                  <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <DoughNut data={doughnutEmailData} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {scheduled?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Upcoming Event
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                          // marginLeft: '-16px',
                        }}
                      >
                        <TabularView
                          columns={upcomEventColumns}
                          data={scheduled}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {recentCampaign?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Recent Campaign
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                        }}
                      >
                        <TabularView
                          columns={recentCampaignColumns}
                          data={recentCampaign}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          {/* <TabPanel value={activeTab} index={2}>
            <Box>
              <Grid container spacing={2} sx={{ padding: '1rem' }}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Voice Statistics
                  </Typography>
                  <Statistics commonStatistic={emailStatistics} labels="Mail" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '700',
                      color: '#6c6c6c',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    Language Statistics
                  </Typography>
                  <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <DoughNut data={doughnutChartData} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {scheduled?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Upcoming Event
                      </Typography>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                          // marginLeft: '-16px',
                        }}
                      >
                        <TabularView
                          columns={upcomEventColumns}
                          data={scheduled}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {recentCampaign?.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#6c6c6c',
                          paddingBottom: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        Upcoming Event
                      </Typography>

                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                        }}
                      >
                        <TabularView
                          columns={recentCampaignColumns}
                          data={recentCampaign}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel> */}
        </Box>
      )}
    </>
  )
}
const mapStateToProps = (state) => ({
  dashboardDetails: state.dashboard,
  analyticsData: state.analytics,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardDetails: (filter, date) =>
      dispatch(getDashboardDetails(filter, date)),
    fetchFiltersData: (data) => dispatch(analyticsActions.getFiltersData(data)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}
DashboardNew.propTypes = {
  dashboardDetails: PropTypes.object.isRequired,
  fetchDashboardDetails: PropTypes.func.isRequired,
  fetchFiltersData: PropTypes.func.isRequired,
  analyticsData: PropTypes.objectOf(PropTypes.object).isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNew)
