import React, { useState, useEffect } from 'react'
import ChatBox, { ChatFrame } from 'react-chat-plugin'
import '../styles/App.scss'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as chatActions from '../store/actions/twoWayChat'
import DeliveryImage from '../assets/sent.png'

const TwoWayChat = ({
  getChatList,
  chatList,
  getPhoneNumberList,
  phoneNumberList,
  sendOutBoundMessage,
  outboundMsg,
}) => {
  const [chatDetailsArr, setChatDetailsArray] = useState([])
  const [phoneNumbers, setPhoneNumbers] = useState(null)
  const [phoneNumberMessagesMap, setPhoneNumberMessagesMap] = useState(null)
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null)
  const [attr, setAttr] = useState({
    showChatbox: true,
    showIcon: false,
    messages: [],
  })
  useEffect(() => {
    const intervalId = setInterval(() => {
      getChatList()
      getPhoneNumberList()
    }, 10000)

    return () => clearInterval(intervalId)
  }, [selectedPhoneNumber])
  useEffect(() => {
    getChatList()
    getPhoneNumberList()
  }, [])

  useEffect(() => {
    if (
      phoneNumberList &&
      phoneNumberList?.phoneNumberList &&
      phoneNumberList?.phoneNumberList?.status === 1
    ) {
      setPhoneNumbers(phoneNumberList?.phoneNumberList?.data)

      const defaultPhoneNumber =
        selectedPhoneNumber || phoneNumberList?.phoneNumberList?.data[0]
      setSelectedPhoneNumber(defaultPhoneNumber)
      const selectedChat = chatDetailsArr.find(
        (chat) => chat.mobileNo === defaultPhoneNumber
      )
      setAttr((prevAttr) => ({
        ...prevAttr,
        showChatbox: true,
        messages: selectedChat
          ? selectedChat?.msgConversationJsonArray?.map((message) => ({
              author: {
                username: message.communicationType === 'inbound' ? '' : '',
                id: message.communicationType === 'inbound' ? 2 : 1,
                imageUrl: message.communicationType === 'inbound' ? '' : null,
              },
              text: message.body,
              timestamp: message.timestamp,
              type: 'text',
            }))
          : [],
      }))
    }
  }, [phoneNumberMessagesMap, phoneNumberList?.phoneNumberList?.data])

  useEffect(() => {
    if (chatList && chatList?.chatList && chatList?.chatList?.status === 1) {
      const data = chatList?.chatList?.data
      const phoneNumberMessagesMap1 = {}
      data.forEach((entry) => {
        const phoneNumber = entry.mobileNo.slice(1)
        phoneNumberMessagesMap1[phoneNumber] =
          entry?.msgConversationJsonArray?.map((message) => {
            return {
              author: {
                username: message.communicationType === 'inbound' ? '' : '',
                id: message.communicationType === 'inbound' ? 2 : 1,
                imageUrl: message.communicationType === 'inbound' ? '' : null,
              },
              text: message.body,
              timestamp: message.timestamp,
              type: 'text',
            }
          })
      })
      setPhoneNumberMessagesMap(phoneNumberMessagesMap1)
      setChatDetailsArray(data)
    }
  }, [chatList?.chatList?.data])
  useEffect(() => {
    if (outboundMsg && outboundMsg?.data && outboundMsg?.data?.status === 1) {
      console.log(outboundMsg, 'outboundMsg')
    }
  }, [outboundMsg?.data])

  const getColorForIndex = (index) => {
    const colors = ['red', 'blue', 'green', 'purple', 'orange']
    return colors[index % colors.length]
  }

  const handlePhoneNumberClick = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber)
    const selectedChat = chatDetailsArr.find(
      (chat) => chat.mobileNo === phoneNumber
    )
    setAttr((prevAttr) => ({
      ...prevAttr,
      showChatbox: true,
      messages: selectedChat
        ? selectedChat?.msgConversationJsonArray?.map((message) => ({
            author: {
              username: message.communicationType === 'inbound' ? '' : '',
              id: message.communicationType === 'inbound' ? 2 : 1,
              imageUrl: message.communicationType === 'inbound' ? '' : null,
            },
            text: message.body,
            timestamp: message.timestamp,
            type: 'text',
          }))
        : [],
    }))
  }

  const handleOnSendMessage = (message) => {
    const newMessage = {
      author: {
        username: '',
        id: 1,
        avatarUrl: DeliveryImage,
      },
      text: message,
      type: 'text',
      timestamp: +new Date(),
    }

    setAttr((prevAttr) => ({
      ...prevAttr,
      messages: prevAttr.messages.concat(newMessage),
    }))
    const dataObj = {
      messageContent: message,
      mobileNo: selectedPhoneNumber,
    }
    sendOutBoundMessage(dataObj)
  }

  return (
    <Grid container spacing={2} padding={1}>
      <Grid item xs={12} lg={12} md={12}>
        <h1 className="page-heading">
          <b>Two Way Text</b>
        </h1>
      </Grid>
      <hr
        style={{ border: '3px solid #EDF6F5', margin: '20px 0', width: '100%' }}
      />
      <Grid
        item
        xs={12}
        sm={12}
        lg={12}
        md={12}
        style={{ display: 'flex', height: '64vh' }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          lg={2.5}
          md={2}
          xl={2.5}
          style={{
            paddingRight: 10,
            borderRight: '3px solid rgb(237, 246, 245)',
          }}
        >
          {phoneNumbers?.map((phoneNumber, index) => (
            <button
              key={phoneNumber}
              className="phoneNumber-holder"
              onClick={() => handlePhoneNumberClick(phoneNumber)}
              tabIndex={0}
              style={{
                background:
                  selectedPhoneNumber === phoneNumber ? '#EDF6F4' : 'white',
              }}
              type="button"
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: getColorForIndex(index),
                  marginRight: '8px',
                }}
              />
              <span className="phone-list" style={{ fontSize: '16px' }}>
                {phoneNumber}
              </span>
            </button>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          lg={9.5}
          md={10}
          xl={9.5}
          style={{ padding: '20px', position: 'relative' }}
        >
          <div className="holder-chat">
            {selectedPhoneNumber && (
              <ChatFrame
                chatbox={
                  <ChatBox
                    onSendMessage={handleOnSendMessage}
                    placeholder="Write  Message here..."
                    userId={1}
                    messages={attr.messages}
                    activeAuthor={{ username: 'user2', id: 2, avatarUrl: null }}
                  />
                }
                showChatbox={attr.showChatbox}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  chatList: state.twoWayChat.chatList,
  phoneNumberList: state.twoWayChat.phoneNumberList,
  outboundMsg: state.twoWayChat.outboundMsg,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getChatList: () => dispatch(chatActions.getChatList()),
    getPhoneNumberList: () => dispatch(chatActions.getPhoneNumberList()),
    sendOutBoundMessage: (payload) =>
      dispatch(chatActions.sendOutBoundMessage(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoWayChat)

TwoWayChat.propTypes = {
  chatList: PropTypes.func.isRequired,
  getChatList: PropTypes.func.isRequired,
  phoneNumberList: PropTypes.func.isRequired,
  getPhoneNumberList: PropTypes.func.isRequired,
  sendOutBoundMessage: PropTypes.func.isRequired,
  outboundMsg: PropTypes.func.isRequired,
}
