import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import appConfig from '../../config/appConfig'

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  outline: #fcfcfb;
  border: 1px solid ${appConfig.colors.darkblue};
  height: 100px;
  border-radius: 4px;
  font-family: ${appConfig.fontfamily};
  font-size: 16px;
  padding: 5px;
`

const TextArea = ({
  value,
  onChange,
  name,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <StyledTextarea
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      maxLength="750"
      aria-label="content"
    />
  )
}

TextArea.defaultProps = {
  disabled: false,
  placeholder: '',
  value: '',
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}
export default TextArea
