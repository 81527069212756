import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import TableCell from '@mui/material/TableCell'
import appConfig from '../config/appConfig'
import SkeletonGridLoader from '../components/SkeletonGridLoader'
import DefaultTable from '../components/Table'
import * as optoutActions from '../store/actions/optout'
import * as menuActions from '../store/actions/breadcrumb'
import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
  formatDTADate,
  splitDate,
  splitTime,
} from '../components/Utils'

const OptOutList = ({
  optOutData,
  fetchOptoutList,
  exportOptoutList,
  setBreadcrumb,
}) => {
  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })
  const [channel, setChannel] = useState({ key: 'SMS', value: 'sms' })

  const [searchText, setSearchText] = useState('')

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const onDateChange = (date) => {
    setSelectedDate({
      startDate: date?.[0]?.startDate || date?.startDate,
      endDate: date?.[0]?.endDate || date?.endDate,
    })
  }

  const handleFilterChange = (filter) => {
    setSearchText('')
    setChannel(filter)
  }

  const handleSearch = (search) => {
    setSearchText(search)
  }

  const filterValues = {
    filterBy: channel.value,
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    endDate: CalculateUserTzToUTCTz(
      endOfDay(selectedDate?.endDate)
    ).toISOString(),
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {channel.key === 'Email' ? row.email : row.mobileNo}
        </TableCell>
        {/* <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{formatDTADate(CalculateUTCTzToUserTz(row.date))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.date)))}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.date))}
        </TableCell>

        {/* <TableCell sx={{fontFamily:appConfig.fontfamily,fontSize:appConfig.font16}}>{splitTime(CalculateUTCTzToUserTz(row.date))}</TableCell> */}
      </>
    )
  }

  useEffect(() => {
    setBreadcrumb('Opt Out')
    fetchOptoutList(
      filterValues,
      searchText,
      pagination.limit,
      pagination.offset
    )
  }, [
    JSON.stringify(pagination),
    JSON.stringify(channel),
    JSON.stringify(selectedDate),
    searchText,
  ])

  const handleExport = () => {
    exportOptoutList(filterValues, searchText)
  }

  const handleSort = (sortValue) => {
    console.log('sort', sortValue)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <h1 className="page-heading">
            <b>Opt Out</b>
          </h1>
          <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />
        </Grid>

        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          {optOutData?.optoutList?.isLoading ? (
            <SkeletonGridLoader />
          ) : (
            <DefaultTable
              title="Total"
              handlePagination={handlePagination}
              // headers={[ channel.key === 'Email' ? 'Email' : 'Mobile','Received Date','Received Time']}
              headers={[
                {
                  name: channel.key === 'Email' ? 'Email' : 'Mobile',
                  sortEnabled: false,
                },
                { name: 'Received Date & Time', sortEnabled: false },
              ]}
              data={
                channel.key.toLowerCase() === 'email'
                  ? optOutData?.optoutList?.data?.email
                  : optOutData?.optoutList?.data?.sms
              }
              totalCount={optOutData?.optoutList?.data?.totalCount}
              // isSearchEnabled
              currentPage={pagination.offset}
              currentLimit={pagination.limit}
              isDateFilter
              selectedDateObj={(date) => onDateChange(date)}
              dateFilterLabel={`${moment(
                CalculateUTCTzToUserTz(selectedDate.startDate)
              ).format('MM-DD-YYYY')} - ${moment(
                CalculateUTCTzToUserTz(selectedDate.endDate)
              ).format('MM-DD-YYYY')}`}
              filterOptions={[
                { key: 'SMS', value: 'sms' },
                { key: 'Email', value: 'email' },
              ]}
              isFilterEnabled
              filterAction={(filter) => {
                handleFilterChange(filter)
              }}
              defaultFilter={channel}
              isSearchEnabled
              searchCallback={(text) => handleSearch(text)}
              searchKey={searchText}
              searchPlaceholderText={
                channel.key === 'Email'
                  ? 'Search by Email'
                  : 'Search by mobile number'
              }
              renderCustomCells
              renderCells={renderCells}
              isExportEnabled
              exportCallback={handleExport}
              isSortEnabled
              handleSort={handleSort}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

OptOutList.propTypes = {
  optOutData: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchOptoutList: PropTypes.func.isRequired,
  exportOptoutList: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({ optOutData: state.optout })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOptoutList: (data, search, limit, page) =>
      dispatch(optoutActions.fetchOptOutList(data, search, limit, page)),
    exportOptoutList: (data, search) =>
      dispatch(optoutActions.exportOptoutData(data, search)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptOutList)
