/* eslint-disable */
import qs from 'qs'

import { utils, writeFile } from 'xlsx'

import * as analyticsConstants from './actionTypes'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'
import { CalculateUTCTzToUserTz, splitDate } from '../../components/Utils'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export const getAnalyticsData = (limit, page, data, search = '') => {
  return (dispatch) => {
    dispatch({
      type: analyticsConstants.FETCH_ANALYTICS_DATA,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/messages/list/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        ...data,
        search: search,
        limit,
        page,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false })
      },
    })
      .then((response) => {
        dispatch({
          type: analyticsConstants.FETCH_ANALYTICS_DATA_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: analyticsConstants.FETCH_ANALYTICS_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export const getFiltersData = (data) => {
  return (dispatch) => {
    dispatch({
      type: analyticsConstants.FETCH_FILTER_DATA,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/messages/lov/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        ...data,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false })
      },
    })
      .then((response) => {
        dispatch({
          type: analyticsConstants.FETCH_FILTER_DATA_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: analyticsConstants.FETCH_FILTER_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export function exportAnalyticsData(eData) {
  const data = new FormData()
  data.append('filterBy', eData.filterBy)
  data.append('templateCode', eData.templateCode)
  data.append('fileId', eData.fileId)
  data.append('language', eData.language)
  data.append('statusId', eData.statusId)
  data.append('startDate', eData.startDate)
  data.append('endDate', eData.endDate)
  data.append('campaignId', eData.campaignId)

  return (dispatch) => {
    dispatch({
      type: analyticsConstants.EXPORT_ANALYTICS_DATA,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/messages/list/export/${userDetails?.accountId}/${userDetails?.userId}/`,
      data,
    })
      .then((response) => {
        const wb = utils.book_new()
        const ws = utils.json_to_sheet([
          {
            'start Date': splitDate(CalculateUTCTzToUserTz(eData.startDate)),
            'End Date': splitDate(CalculateUTCTzToUserTz(eData.endDate)),
          },
        ])
        utils.sheet_add_json(
          ws,
          [{ 'Total Records': response.data.totalCount }],
          { origin: 'A5' }
        )
        utils.sheet_add_json(
          ws,
          response.data.msgDetails.map((item) => {
            const csvdata = {
              'Date & Time ': CalculateUTCTzToUserTz(item.date),
              ...item,
            }
            delete csvdata['date']
            return csvdata
          }),
          { origin: 'A8' }
        )
        utils.book_append_sheet(wb, ws, 'Sheet1')
        writeFile(
          wb,
          `${splitDate(CalculateUTCTzToUserTz(eData.startDate))}-${splitDate(
            CalculateUTCTzToUserTz(eData.endDate)
          )}-report.xlsx`
        )
      })
      .catch((error) => {
        dispatch({
          type: analyticsConstants.EXPORT_ANALYTICS_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export const getInvalidReportsData = (limit, page, data, search = '') => {
  return (dispatch) => {
    dispatch({
      type: analyticsConstants.FETCH_INVALID_REPORT_DATA,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/messages/invalid/list/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        ...data,
        search: search,
        limit,
        page,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false })
      },
    })
      .then((response) => {
        dispatch({
          type: analyticsConstants.FETCH_INVALID_REPORT_DATA_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: analyticsConstants.FETCH_INVALID_REPORT_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export const getInvalidFiltersData = (data) => {
  return (dispatch) => {
    dispatch({
      type: analyticsConstants.FETCH_INVALID_FILTER_DATA,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/messages/invalid/lov/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        ...data,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false })
      },
    })
      .then((response) => {
        dispatch({
          type: analyticsConstants.FETCH_INVALID_FILTER_DATA_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: analyticsConstants.FETCH_INVALID_FILTER_DATA_FAILURE,
          payload: error,
        })
      })
  }
}

export function exportInvalidReportsData(eData) {
  const data = new FormData()
  data.append('filterBy', eData.filterBy)
  data.append('fileId', eData.fileId)
  data.append('startDate', eData.startDate)
  data.append('endDate', eData.endDate)

  return (dispatch) => {
    dispatch({
      type: analyticsConstants.EXPORT_INVALID_DATA,
    })

    request({
      method: 'post',
      url: `${config.API_URL}/api/messages/invalid/list/export/${userDetails?.accountId}/${userDetails?.userId}/`,
      data,
    })
      .then((response) => {
        const wb = utils.book_new()
        const ws = utils.json_to_sheet([
          {
            'start Date': splitDate(CalculateUTCTzToUserTz(eData.startDate)),
            'End Date': splitDate(CalculateUTCTzToUserTz(eData.endDate)),
          },
        ])
        utils.sheet_add_json(
          ws,
          response.data.map((item) => {
            const csvdata = {
              'Broadcast Date & Time ':
                item.broadcastTime &&
                CalculateUTCTzToUserTz(item.broadcastTime),
              ...item,
            }
            csvdata['Message'] = item.rawMessage
            delete csvdata['rawMessage']
            delete csvdata['broadcastTime']
            if (eData.filterBy.toLowerCase() === 'email') {
              delete csvdata['sms']
            } else {
              delete csvdata['email']
            }
            return csvdata
          }),
          { origin: 'A8' }
        )
        utils.book_append_sheet(wb, ws, 'Sheet1')
        writeFile(
          wb,
          `${splitDate(CalculateUTCTzToUserTz(eData.startDate))}-${splitDate(
            CalculateUTCTzToUserTz(eData.endDate)
          )}-invalid-report.xlsx`
        )
      })
      .catch((error) => {
        dispatch({
          type: analyticsConstants.EXPORT_INVALID_DATA_FAILURE,
          payload: error,
        })
      })
  }
}
