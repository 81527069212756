import React, { useEffect, useState } from 'react';
import { Button, Stack, IconButton } from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import UserCard from './UserCard';
import {
  updateIsReadMessage,
  getPhoneNumberLists,
  getChatList,
} from '../../store/actions/twoWayChat';
import { useDispatch, useSelector } from 'react-redux';

const LeftSide = ({
  setSelectedUser,
  phoneNumberList,
  setOpenModel,
  phoneNumberLists,
  phoneNumbers,
}) => {
  const dispatch = useDispatch();
  const [selectUserId, setSelectUserId] = useState('');

  // const isActive = (item) => {
  //   if (selectUserId === item) return 'active';
  //   return '';
  // };

  // const handleAddUser = (item) => {
  //   setSelectUserId(item);
  //   setSelectedUser(item);
  // };

  // useEffect(() => {
  //   if (!selectUserId) {
  //     setSelectedUser(phoneNumberList?.data?.[0]);
  //   }
  //   setSelectUserId(phoneNumberList?.data?.[0]);
  // }, [phoneNumberList]);

  const isActive = (item) => {
    if (selectUserId === item.mobileNumber) return 'active';
    return '';
  };

  const handleAddUser = (item) => {
    setSelectUserId(item.mobileNumber);
    setSelectedUser(item);
    if (!item.isReadMsg) {
      dispatch(
        updateIsReadMessage({
          mobileNo: item?.mobileNumber,
        })
      );
      dispatch(getPhoneNumberLists());
      dispatch(getChatList());
    }
  };

  // useEffect(() => {
  //   if (!selectUserId) {
  //     setSelectedUser(phoneNumberLists?.data?.[0]);
  //   }
  //   setSelectUserId(phoneNumberLists?.data?.[0].mobileNumber);
  // }, [phoneNumberLists]);

  useEffect(() => {
    if (!selectUserId) {
      setSelectedUser(phoneNumbers?.[0]);
    }
    setSelectUserId(selectUserId || phoneNumbers?.[0].mobileNumber);
  }, [phoneNumbers]);

  return (
    <>
      <div className="message_user_header">
        <Button
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#fff',
            '&:hover': {
              color: '#fff',
              borderColor: '#fff',
            },
          }}
          endIcon={<AddCommentIcon />}
          onClick={() => setOpenModel(true)}
        >
          New Chat
        </Button>
        <Stack direction="row" spacing={2}>
          <IconButton
            sx={{
              color: '#fff',
            }}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            sx={{
              color: '#fff',
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Stack>
      </div>
      <div className="message_chat_list">
        {phoneNumbers?.map((list, index) => (
          <div
            key={index}
            className={`message_user ${isActive(list)}`}
            onClick={() => handleAddUser(list)}
          >
            <UserCard user={list} />
          </div>
        ))}
      </div>
    </>
  );
};

export default LeftSide;
