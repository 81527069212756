import React, { useState, useEffect } from 'react'
import LeftSide from './LeftSide'
import { Grid } from '@mui/material'
import AvatarIcon from './Avatar'
import RightSide from './RightSide'
import {
  getPhoneNumberList,
  getPhoneNumberLists,
  getChatList,
} from '../../store/actions/twoWayChat'
import { useDispatch, useSelector } from 'react-redux'
import AddNewChatModal from './AddNewChatModal'

const Chat = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [openModel, setOpenModel] = useState(false)
  const [selectedUser, setSelectedUser] = useState('')
  const [phoneNumbers, setPhoneNumbers] = useState(null)
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const { twoWayChat } = state

  useEffect(() => {
    dispatch(getPhoneNumberList())
    dispatch(getPhoneNumberLists())
    dispatch(getChatList())
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getChatList())
      dispatch(getPhoneNumberLists())
    }, 10000)

    return () => clearInterval(intervalId)
  }, [selectedUser])

  useEffect(() => {
    if (
      twoWayChat &&
      twoWayChat?.phoneNumberLists &&
      twoWayChat?.phoneNumberLists?.phoneNumberLists &&
      twoWayChat?.phoneNumberLists?.phoneNumberLists?.status === 1
    ) {
      setPhoneNumbers(twoWayChat?.phoneNumberLists?.phoneNumberLists?.data)
    }
  }, [twoWayChat?.phoneNumberLists?.phoneNumberLists?.data])

  const onClose = () => {
    setOpenModel(false)
  }

  return (
    <>
      <div className="message">
        <div className="message_nav">
          <div
            className={`tab ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            Text
          </div>
          <div
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => setActiveTab(2)}
          >
            Message
          </div>
        </div>
        <Grid container className="message_content">
          <Grid item xs={4} className="left_side">
            <LeftSide
              setSelectedUser={setSelectedUser}
              phoneNumberList={twoWayChat.phoneNumberList.phoneNumberList}
              phoneNumberLists={twoWayChat.phoneNumberLists.phoneNumberLists}
              phoneNumbers={phoneNumbers}
              setOpenModel={setOpenModel}
            />
          </Grid>
          <Grid item xs={8} className="right_side">
            <RightSide
              user={selectedUser}
              setSelectedUser={setSelectedUser}
              chatList={twoWayChat.chatList.chatList}
              phoneNumberList={twoWayChat.phoneNumberList.phoneNumberList}
              phoneNumberLists={twoWayChat.phoneNumberLists.phoneNumberLists}
            />
          </Grid>
        </Grid>
      </div>
      {openModel && (
        <AddNewChatModal
          setOpen={setOpenModel}
          open={openModel}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default Chat
