import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import TableCell from '@mui/material/TableCell'
import { useLocation, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import * as Styled from '../components/styled-components/Campaign'

import SkeletonGridLoader from '../components/SkeletonGridLoader'

import DefaultTable from '../components/Table'
import appConfig from '../config/appConfig'

import FileSnapShot from './FileSnapShot'

import * as campaignActions from '../store/actions/campaign'
import { downloadReport } from '../store/actions/reports'
import * as menuActions from '../store/actions/breadcrumb'

import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
  getChannelLabel,
  formatDTADate,
  getCampaignStatus,
  splitDate,
  splitTime,
} from '../components/Utils'

const CampaignFiles = ({
  campaignFilesData,
  fetchCampaignFilesList,
  setBreadcrumb,
  downloadSourceFile,
  fetchDownloadReport,
}) => {
  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })
  const [sortValue, setSortValue] = useState({
    sortBy: 'createdTime',
    sortOrder: 'desc',
  })
  const navigate = useNavigate()
  const params = useLocation()
  const snapshotRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const isMounted = useRef(false)
  const [fileId, setFileId] = useState(null)
  const [selected, setSelected] = useState(null)
  const [enableRowDetails, setEnableRowDetails] = useState(false)
  const selectedCampaign = params?.state?.campaign

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const onDateChange = (date) => {
    setSelectedDate({
      startDate: date?.[0]?.startDate || date?.startDate,
      endDate: date?.[0]?.endDate || date?.endDate,
    })
  }

  const handleSearch = (search) => {
    isMounted.current = false
    setSearchText(search)
  }

  const filterValues = {
    campaignId: selectedCampaign.id,
    filterBy: selectedCampaign.channelName,
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    endDate: CalculateUserTzToUTCTz(
      endOfDay(selectedDate?.endDate)
    ).toISOString(),
    sortValue: sortValue,
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          <div
            style={{ width: '220px', fontFamily: appConfig.fontfamily }}
            className="campaign-action-btn"
          >
            <button
              type="submit"
              className="link-button"
              onClick={() => {
                downloadSourceFile(row.fileName, row.originalFileName)
              }}
            >
              {row.originalFileName}
            </button>
          </div>
        </TableCell>
        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{formatDTADate(CalculateUTCTzToUserTz(row.createdTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.createdTime)))}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.createdTime))}
        </TableCell>

        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{splitTime(CalculateUTCTzToUserTz(row.createdTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row?.totalMsgCount ? row?.totalMsgCount : '-'}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {getCampaignStatus(row.statusId)}
        </TableCell>
        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{formatDTADate(CalculateUTCTzToUserTz(row.reportGeneratedTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(
            splitDate(CalculateUTCTzToUserTz(row.reportGeneratedTime))
          )}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.reportGeneratedTime))}
        </TableCell>

        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          <div>
            {row.isReportGenerated ? (
              <div
                style={{ width: '250px', fontFamily: 'Source Sans Pro' }}
                className="campaign-action-btn"
              >
                <button
                  type="submit"
                  className="link-button"
                  onClick={() => {
                    fetchDownloadReport(row.fileName, row.reportFileName)
                  }}
                >
                  {row.reportFileName}
                </button>
              </div>
            ) : (
              <span>Response file not generated</span>
            )}
          </div>
        </TableCell>
      </>
    )
  }

  /* eslint-disable */
  const renderActionButton = (row) => {
    return (
      <div>
        {row.reconcileStatus === 'Processing' ? (
          <span
            style={{
              fontFamily: appConfig.fontfamily,
              fontSize: appConfig.font16,
            }}
          >
            In Progress
          </span>
        ) : (
          <a
            href="javascript:void(0)"
            style={{
              color: appConfig.colors.blueprimary,
              fontSize: appConfig.font16,
            }}
            onClick={() => {
              reconcileFile(row.id, channel.value)
              setReconcile({ fileId: row.id, status: true })
            }}
          >
            Reconcile
          </a>
        )}
        {row.reconcileStatus === 'Processing' ? null : (
          <a
            className="mar-l-10"
            href="javascript:void(0)"
            style={{
              color: appConfig.colors.blueprimary,
              fontSize: appConfig.font16,
            }}
            onClick={() => {
              regenerateFile(row.id, channel.value)
              setRegenerate({ fileId: row.id, status: true })
            }}
          >
            Regenerate
          </a>
        )}
      </div>
    )
  }

  const renderDetailButton = (row) => {
    return (
      <div>
        <IconButton
          className="pad-0"
          onClick={() => {
            window.sessionStorage.setItem('campaign', JSON.stringify(row))
            setEnableRowDetails(fileId !== row.id ? true : !enableRowDetails)
            setFileId(row.id)
            setSelected(row.id)
            setTimeout(() => {
              snapshotRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }, 500)
          }}
          title="View Details"
          aria-label="view details"
          size="small"
        >
          {fileId === row.id && enableRowDetails ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
              fontSize="inherit"
            />
          )}
        </IconButton>
      </div>
    )
  }

  const loadRowDetails = () => {
    return <FileSnapShot id={fileId} />
  }

  useEffect(() => {
    loadRowDetails()
  }, [fileId])

  useEffect(() => {
    setBreadcrumb('Files List')
    isMounted.current = true
    fetchCampaignFilesList(
      filterValues,
      searchText,
      pagination.limit,
      pagination.offset
    )
  }, [
    JSON.stringify(pagination),
    JSON.stringify(selectedDate),
    JSON.stringify(sortValue),
  ])

  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchCampaignFilesList(
        filterValues,
        searchText,
        pagination.limit,
        pagination.offset
      )
    }
  }, [searchText])

  const handleSort = (value) => {
    setSortValue(value)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>Files List</b>
          </h1>
        </Grid>
        <Grid item xs={6} xl={6} lg={6} md={6}>
          <div className="text-align-right">
            <Styled.CampaignButton
              className="text-trans-none"
              variant="contained"
              onClick={() => navigate('/campaign')}
            >
              Campaigns
            </Styled.CampaignButton>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <hr className="blue-border" />
        </Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12} sx={{ mb: 2 }}>
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Campaign Name:</b>
            </span>
            <span>{selectedCampaign?.campaignName}</span>
          </div>
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Communication Channel:</b>
            </span>
            <span>{getChannelLabel(selectedCampaign?.channelName)}</span>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          {campaignFilesData?.isLoading ? (
            <SkeletonGridLoader />
          ) : (
            <DefaultTable
              title="Total"
              handlePagination={handlePagination}
              headers={[
                { name: 'File Name', sortEnabled: false },
                {
                  name: 'Created Date & Time',
                  sortEnabled: true,
                  sortBy: 'createdTime',
                },
                { name: 'Total Records', sortEnabled: false },
                { name: 'Status', sortEnabled: false },
                {
                  name: 'Report Generated Date & Time',
                  sortEnabled: true,
                  sortBy: 'reportGeneratedTime',
                },
                { name: 'Response File', sortEnabled: false },
              ]}
              data={campaignFilesData?.files}
              totalCount={campaignFilesData?.count}
              currentPage={pagination.offset}
              currentLimit={pagination.limit}
              isDateFilter
              selectedDateObj={(date) => onDateChange(date)}
              dateFilterLabel={`${moment(
                CalculateUTCTzToUserTz(selectedDate.startDate)
              ).format('MM-DD-YYYY')} - ${moment(
                CalculateUTCTzToUserTz(selectedDate.endDate)
              ).format('MM-DD-YYYY')}`}
              // filterOptions={[{ key: "SMS", value: "sms" }, { key: "Email", value: "email" }]}
              // isFilterEnabled
              // filterAction={(filter) => { handleFilterChange(filter) }}
              // defaultFilter={channel}
              isSearchEnabled
              searchCallback={(text) => handleSearch(text)}
              searchKey={searchText}
              searchPlaceholderText={'Search by file name'}
              renderCustomCells
              renderCells={renderCells}
              // hasAction
              renderActionButton={renderActionButton}
              isRefreshEnabled
              refreshCallback={() => {
                fetchCampaignFilesList(
                  filterValues,
                  searchText,
                  pagination.limit,
                  pagination.offset
                )
              }}
              hasDetails
              enableRowDetails={enableRowDetails}
              renderDetailsButton={renderDetailButton}
              selected={selected}
              loadRowDetails={enableRowDetails && loadRowDetails()}
              isSortEnabled
              handleSort={handleSort}
              sortValue={sortValue}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

CampaignFiles.propTypes = {
  campaignFilesData: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchCampaignFilesList: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
  downloadSourceFile: PropTypes.func.isRequired,
  fetchDownloadReport: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  campaignFilesData: state.campaign.campaignFiles,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignFilesList: (filter, search, limit, page) =>
      dispatch(
        campaignActions.getCampaignFilesList(filter, search, limit, page)
      ),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
    downloadSourceFile: (fileName, originalFileName) =>
      dispatch(
        campaignActions.fetchCampaignSourceFile(fileName, originalFileName)
      ),
    fetchDownloadReport: (fileName, reportFileName) =>
      dispatch(downloadReport(fileName, reportFileName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFiles)
