import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const SkeletonGridLoader = () => {
  return (
    <Box>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton />
    </Box>
  )
}

export default SkeletonGridLoader
