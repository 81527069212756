import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Field from '../../../components/Field'
import CoDropdown from '../../../controllers/CoDropdown'
import * as Styled from '../../../components/styled-components/Campaign'
import { useForm } from 'react-hook-form'
import appConfig from '../../../config/appConfig'
import request from '../../../components/Helper/Request'
import { config } from '../../../config/config'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CoTextField from '../../../controllers/CoTextField'
import SpinningLoader from '../../../components/SpinningLoader'
import Swal from 'sweetalert2'

const CreateUser = ({
  handleCloseUser,
  openUser,
  edit,
  editFormData,
  getUserDetails,
  userId,
}) => {
  const [group, setGroup] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    firstName: yup.string().required('Enter the First Name'),
    lastName: yup.string().required('Enter the last Name'),
    emailId: yup.string().required('Enter the email'),
    // group: edit ? yup.string() : yup.string().required('Select the Group'),
    phoneNumber: yup.string().required('Enter the Phone Number'),
  })
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      firstName: editFormData ? editFormData?.firstname : '',
      lastName: editFormData ? editFormData?.lastname : '',
      emailId: editFormData ? editFormData?.email : '',
      phoneNumber: editFormData ? editFormData?.phoneNumber : '',
      middleName: editFormData ? editFormData?.middleName : '',
      // group: '',
      altPhoneNumber: editFormData ? editFormData?.altPhoneNumber : '',
      altEmail: editFormData ? editFormData?.altEmail : '',
    },
  })

  const getGroupDetails = async () => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/group/getGroup?page=0&size=500`,
      })
      setGroup(res?.groupModelList)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getGroupDetails()
  }, [])

  const groupOpts = useMemo(() => {
    return group?.map(({ groupId, name }) => ({
      label: name,
      value: groupId,
    }))
  }, [group])

  const addUser = async (data) => {
    const payload = {
      username: data?.firstName + ' ' + data?.lastName,
      email: data?.emailId,
      password: null,
      firstname: data?.firstName,
      lastname: data?.lastName,
      middleName: data?.middleName,
      altEmail: data?.altEmail,
      altPhoneNumber: data?.altPhoneNumber,
      dob: null,
      createdBy: 5,
      createdAt: null,
      updatedAt: null,
      updatedBy: 5,
      organizationid: 1,
      managerId: null,
      isadmin: null,
      county: null,
      portalName: null,
      applicanType: null,
      gender: null,
      phoneNumber: data?.phoneNumber,
      ssn: null,
      mailingAddress: null,
      state: null,
      city: null,
      zipcode: null,
      isActive: null,
      groupId: parseInt(data?.group),
    }
    try {
      setIsLoading(true)
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/create/User`,
        data: payload,
      })
      if (res.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'User created successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        handleCloseUser()
        getUserDetails()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const editUser = async (data) => {
    const payload = {
      userId: userId,
      username: data?.firstName + ' ' + data?.lastName,
      email: data?.emailId,
      password: null,
      firstname: data?.firstName,
      lastname: data?.lastName,
      middleName: data?.middleName,
      altEmail: data?.altEmail,
      altPhoneNumber: data?.altPhoneNumber,
      dob: null,
      createdBy: 5,
      createdAt: null,
      updatedAt: null,
      updatedBy: 5,
      organizationid: 1,
      managerId: null,
      isadmin: null,
      county: null,
      portalName: null,
      applicanType: null,
      gender: null,
      phoneNumber: data?.phoneNumber,
      ssn: null,
      mailingAddress: null,
      state: null,
      city: null,
      zipcode: null,
      isActive: true,
    }
    try {
      setIsLoading(true)
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/user/editUser`,
        data: payload,
      })
      if (res.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'User edited successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        handleCloseUser()
        getUserDetails()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Updating user"
          />
        </Backdrop>
      ) : null}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openUser}
        onClose={handleCloseUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {edit ? 'Edit User' : 'Add User'}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: 'solid 1px #CCCC',
              display: 'flex',
              justifyContent: 'center',
              p: '1rem',
              borderRadius: '5px',
            }}
          >
            <Grid container xs={12} lg={12} md={12} sm={12} spacing={2}>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="First Name" titleVariant="h7" required>
                  <CoTextField
                    placeholder="Enter First Name"
                    name="firstName"
                    control={control}
                  />
                </Field>

                {errors.firstName && (
                  <span style={{ color: '#F00' }}>
                    {errors.firstName.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Middle Name" titleVariant="h7">
                  <CoTextField
                    placeholder="Enter Middle Name"
                    name="middleName"
                    control={control}
                  />
                </Field>
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Last Name" titleVariant="h7" required>
                  <CoTextField
                    placeholder="Enter Last Name"
                    name="lastName"
                    control={control}
                  />
                </Field>
                {errors.lastName && (
                  <span style={{ color: '#F00' }}>
                    {errors.lastName.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Email " titleVariant="h7" required>
                  <CoTextField
                    placeholder="Enter Email"
                    name="emailId"
                    control={control}
                  />
                </Field>

                {errors.emailId && (
                  <span style={{ color: '#F00' }}>
                    {errors.emailId.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Alt Email " titleVariant="h7">
                  <CoTextField
                    placeholder="Enter Email"
                    name="altEmail"
                    control={control}
                  />
                </Field>
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Phone Number " titleVariant="h7" required>
                  <CoTextField
                    placeholder="Enter Phone Number"
                    name="phoneNumber"
                    control={control}
                    inputProps={{
                      inputMode: 'numeric',
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                    }}
                  />
                </Field>
                {errors.phoneNumber && (
                  <span style={{ color: '#F00' }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} lg={6} md={6}>
                <Field label="Alt - Phone Number " titleVariant="h7">
                  <CoTextField
                    placeholder="Enter Alt Phone Number"
                    name="altPhoneNumber"
                    control={control}
                    inputProps={{
                      inputMode: 'numeric',
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 10)
                    }}
                  />
                </Field>
              </Grid>

              {/* {!edit && (
                <Grid item xs={6} lg={6} md={6}>
                  <Field label="Group" titleVariant="h7" required />
                  <FormControl fullWidth>
                    <CoDropdown
                      name="group"
                      control={control}
                      options={groupOpts}
                      // disabled={disableCampaign}
                    />
                    {errors.group && (
                      <span style={{ color: '#F00' }}>
                        {errors.group.message}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              )} */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Styled.CancelButton
            sx={{ padding: '5px', textTransform: 'capitalize' }}
            onClick={() => {
              handleCloseUser()
            }}
          >
            Close
          </Styled.CancelButton>
          <Styled.CampaignButton
            onClick={handleSubmit(edit ? editUser : addUser)}
            sx={{ textTransform: 'capitalize' }}
            autoFocus
          >
            Submit
          </Styled.CampaignButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateUser
