/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import ShortcutIcon from '@mui/icons-material/Shortcut'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PropTypes from 'prop-types'
import { TimeField } from '@mui/x-date-pickers/TimeField'
import Field from '../components/Field'
import scheduleCampaign from '../store/actions/scheduleCampaign'
import { connect } from 'react-redux'
import CoTextField from '../controllers/CoTextField'
import dayjs from 'dayjs'
import request from '../components/Helper/Request'
import { config } from '../config/config'
import SpinningLoader from '../components/SpinningLoader'
import appConfig from '../config/appConfig'
import { useNavigate } from 'react-router-dom'

const ScheduleCampaign = ({
  setCurrentStep,
  setComponentShow,
  scheduleNew,
  campaignId,
  templateCode,
  templateRes,
  setDisableTemplate,
  disableSchedule,
}) => {
  const schema = yup.object().shape({
    possible: yup.string().required('Choose Button'),
    // date: yup.string().required('Date is required'),
    // timeFormat: yup.string().required('Choose format(am/pm)'),
  })
  const [isLoading, setIsLoading] = useState(false)

  const {
    watch,
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      possible: null,
      date: null,
      hours: '',
      minutes: '',
      timeFormat: null,
      onetimeRecurring: '',
      campaignId: campaignId,
    },
  })

  const navigate = useNavigate()
  const onetimeRecurringWatch = watch('onetimeRecurring')
  const posWatch = watch('possible')
  const possibleWatch = posWatch === 'Possible'
  const watchOfPossible = possibleWatch ? true : false
  const watchTimeFormat = watch('timeFormat')
  const watchHours = watch('hours')
  const watchMinutes = watch('minutes')
  const watchDate = watch('date')

  const RadioSelect = [
    { label: 'As Soon as Possible', value: 'Possible' },
    { label: 'On a specific Date', value: 'Specificdate' },
  ]

  const handleBack = () => {
    setCurrentStep(2)
    setDisableTemplate(true)
  }

  const formatWithLeadingZero = (value) => (value < 10 ? `0${value}` : value)

  const handleHoursChange = (event) => {
    let { value } = event.target
    value = value.replace(/\D/g, '')
    let numericValue = isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10)
    if (numericValue < 0) {
      numericValue = 0
    } else if (numericValue > 12) {
      numericValue = 12
    }
    const formattedValue = numericValue.toString()
    setValue('hours', formattedValue)
    // setValue('hours', formatWithLeadingZero(numericValue));
  }

  const handleMinutesChange = (event) => {
    let { value } = event.target
    value = value.replace(/\D/g, '')
    let numericValue = isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10)
    if (numericValue < 0) {
      numericValue = 0
    } else if (numericValue > 59) {
      numericValue = 59
    }
    const formattedValue = numericValue.toString()
    setValue('minutes', formattedValue)
    // setValue('minutes', formatWithLeadingZero(numericValue));
  }

  const onSubmit = async (data) => {
    if (Object.keys(dirtyFields).length > 0) {
      const values = {
        ...data,
        templateCode: templateRes,
      }
      await scheduleNew(values, watchOfPossible, campaignId)
      reset()
      setComponentShow(false)
      navigate('/dashboard')
    } else {
      setCurrentStep(4)
    }
  }

  const getScheduleDetails = async () => {
    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/campaign/getSchedule/${campaignId}`,
      })
      const scheduleDate = dayjs(res?.scheduleDate)
      const hours = scheduleDate.hour()
      const minutes = scheduleDate.minute()
      const formattedHours = formatWithLeadingZero(hours)
      const formattedMinutes = formatWithLeadingZero(minutes)
      const timeFormat = hours >= 12 ? 'pm' : 'am'
      reset({
        date: scheduleDate,
        onetimeRecurring: res?.scheduleType,
        hours: formattedHours <= 12 ? formattedHours : formattedHours - 12,
        minutes: formattedMinutes,
        timeFormat: timeFormat,
        possible: res.isInstant ? 'Possible' : 'Specificdate',
      })
    } catch (error) {
    } finally {
      //
    }
  }

  useEffect(() => {
    getScheduleDetails()
  }, [])

  return (
    <div>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label={'Creating Schedule....'}
          />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(onSubmit, watchOfPossible)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field label="Schedule your Campaign" titleVariant="h6" />
          </Grid>
          <Grid item xs={12} sx={{ padding: '20px 0px' }}>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="onetimeRecurring"
                  control={control}
                  render={({ field }) => (
                    <Stack
                      {...field}
                      direction={{ xs: 'column', sm: 'row' }}
                      justifyContent="flex-start"
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                      <Button
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textTransform: 'capitalize !important',
                          backgroundColor:
                            onetimeRecurringWatch === 'OneTime'
                              ? '#052146'
                              : '#fff',
                          color:
                            onetimeRecurringWatch === 'OneTime'
                              ? '#fff'
                              : '#000',
                          width: '16rem',
                          height: '6rem',
                          border: '2px solid #052146',
                          borderRadius: '10px',
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'inherit',
                          },
                        }}
                        value="OneTime"
                        onClick={(e) => {
                          field.onChange('OneTime')
                        }}
                        disabled={disableSchedule}
                      >
                        <ShortcutIcon fontSize="large" />
                        <Typography sx={{ paddingTop: '10px' }}>
                          One Time Only
                        </Typography>
                      </Button>
                      <Button
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textTransform: 'capitalize !important',
                          backgroundColor:
                            onetimeRecurringWatch === 'Recurring'
                              ? '#052146'
                              : '#fff',
                          color:
                            onetimeRecurringWatch === 'Recurring'
                              ? '#fff'
                              : '#000',
                          width: '16rem',
                          height: '6rem',
                          border: '2px solid #052146',
                          borderRadius: '10px',
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'inherit',
                          },
                        }}
                        value="Recurring"
                        onClick={(e) => {
                          field.onChange('Recurring')
                        }}
                        disabled={disableSchedule}
                      >
                        <CachedIcon fontSize="large" />
                        <Typography sx={{ paddingTop: '10px' }}>
                          Recurring
                        </Typography>
                      </Button>
                    </Stack>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column">
              <Controller
                name="possible"
                control={control}
                required
                key={posWatch}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {(RadioSelect || []).map(({ label, value }) => {
                      return (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={label}
                          disabled={disableSchedule}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '18px',
                            },
                          }}
                        />
                      )
                    })}
                  </RadioGroup>
                )}
              />
              {errors.possible && (
                <span style={{ color: 'red' }}>{errors.possible.message}</span>
              )}
            </Stack>
          </Grid>
          {!watchOfPossible && (
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                justifyContent="flex-start"
                spacing={{ xs: 1, sm: 1, md: 4 }}
              >
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={0}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Date<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <Controller
                        name="date"
                        control={control}
                        required
                        // error={errors.date?.message}
                        // helperText={errors.date?.message ?? 'Invalid Date'}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            sx={{ width: '20rem' }}
                            minDate={dayjs()}
                            disabled={disableSchedule}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {errors.date && (
                    <span style={{ color: 'red' }}>{errors.date.message}</span>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Hours
                    </Typography>
                    {/* <TextField
                      control={control}
                      name="hours"
                      variant="outlined"
                      inputProps={{ style: { width: '50px' } }}
                      onChange={handleHoursChange}
                    /> */}
                    <Controller
                      name="hours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="outlined"
                          inputProps={{ style: { width: '50px' } }}
                          onChange={(event) => {
                            field.onChange(event)
                            handleHoursChange(event)
                          }}
                          disabled={disableSchedule}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                      Minutes
                    </Typography>
                    {/* <TextField
                      control={control}
                      name="minutes"
                      variant="outlined"
                      type="text"
                      inputProps={{ style: { width: '50px' } }}
                      onChange={handleMinutesChange}
                    /> */}
                    <Controller
                      name="minutes"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          type="number"
                          inputProps={{ style: { width: '50px' } }}
                          onChange={(event) => {
                            field.onChange(event)
                            handleMinutesChange(event)
                          }}
                          disabled={disableSchedule}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="column" pt={3.5}>
                    <Controller
                      name="timeFormat"
                      control={control}
                      required
                      render={({ field }) => (
                        <ButtonGroup aria-label="Basic button group" {...field}>
                          <Button
                            style={{
                              width: '5rem',
                              height: '3.5rem',
                              backgroundColor:
                                field.value === 'am' ? '#563c5c' : '#faebd7',
                              color: field.value === 'am' ? '#fff' : '#000',
                              border: '1px solid #817d86',
                              borderTopLeftRadius: '10px',
                              borderBottomLeftRadius: '10px',
                            }}
                            sx={{
                              '&:hover': {
                                backgroundColor: 'inherit',
                              },
                            }}
                            value="am"
                            onClick={(e) => {
                              field.onChange('am')
                            }}
                            disabled={disableSchedule}
                          >
                            AM
                          </Button>
                          <Button
                            style={{
                              width: '5rem',
                              height: '3.5rem',
                              backgroundColor:
                                field.value === 'pm' ? '#563c5c' : '#ece6f0',
                              color: field.value === 'pm' ? '#fff' : '#000',
                              border: '1px solid #817d86',
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                            }}
                            sx={{
                              '&:hover': {
                                backgroundColor: 'inherit',
                              },
                            }}
                            value="pm"
                            onClick={(e) => {
                              field.onChange('pm')
                            }}
                            disabled={disableSchedule}
                          >
                            PM
                          </Button>
                        </ButtonGroup>
                      )}
                    />
                    {errors.timeFormat && (
                      <span style={{ color: 'red', paddingTop: '5px' }}>
                        {errors.timeFormat.message}
                      </span>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={
                  (!watchTimeFormat ||
                    !watchHours ||
                    !watchMinutes ||
                    !watchDate) &&
                  !possibleWatch
                }
              >
                Next
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  scheduleDetail: state.createSchedule,
})

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleNew: (data, watchOfPossible, campaignId) =>
      dispatch(scheduleCampaign(data, watchOfPossible, campaignId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCampaign)

ScheduleCampaign.propTypes = {
  // eslint-disable-next-line react/require-default-props
  setCurrentStep: PropTypes.number,
  setComponentShow: PropTypes.bool,
  scheduleCampaign: PropTypes.func,
}
ScheduleCampaign.defaultProps = {
  setComponentShow: false,
}
