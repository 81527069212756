/* eslint-disable react/prop-types */
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';

const CoDropdown = (props) => {
  const { options = [], name, control } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Select
            {...props}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            value={field.value}
          >
            <MenuItem value="">Select</MenuItem>
            {options.map((menuObj) => {
              return (
                <MenuItem value={menuObj.value}>{menuObj?.label}</MenuItem>
              );
            })}
          </Select>
        );
      }}
    />
  );
};
export default CoDropdown;
