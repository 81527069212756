import { Box, Grid, IconButton, Stack, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Menu from '@mui/material/Menu'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { noop } from 'lodash'

const TopNav = ({ setOpenNav }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     background: 'white',
    //   }}
    // >
    //   <Box>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        background: 'white',
        alignItems: 'center',
        backgroundColor: '#000',
        marginBottom: '10px',
      }}
    >
      <Grid item xs={6} md={6} lg={6} sx={{ padding: '1rem' }}>
        <Stack direction="row">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenNav(true)}
            edge="start"
            sx={{
              marginRight: 'auto',
              color: '#FFF',
              mr: 2,
              display: {
                xs: 'block',
                sm: 'block',
                xl: 'none',
                lg: 'none',
                md: 'block',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <TextField
            fullWidth
            size="small"
            // id="outlined-basic"
            // variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#FFF',
                borderRadius: '3rem',
                '& fieldset': {
                  borderColor: '#FFF',
                },
                '&:hover fieldset': {
                  borderColor: '#FFF',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFF',
                },
              },
              '& .MuiInputBase-input': {
                color: '#000',
                padding: '10px 12px',
              },
            }}
            InputProps={{
              style: {
                borderRadius: '3rem',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'end' }}>
        <IconButton sx={{ color: '#FFF' }}>
          <NotificationsNoneIcon />
        </IconButton>
        <IconButton sx={{ color: '#FFF' }}>
          <HelpOutlineIcon />
        </IconButton>
        <IconButton sx={{ color: '#FFF' }}>
          <SettingsOutlinedIcon />
        </IconButton>

        <IconButton
          sx={{
            color: '#FFF',
            marginRight: '10px',
          }}
          onClick={handleClick}
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
          <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
        {/* <IconButton onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu> */}
      </Grid>
    </Grid>
  )
}

TopNav.propTypes = {
  setOpenNav: PropTypes.func,
}

TopNav.defaultProps = {
  setOpenNav: noop,
}

export default TopNav
