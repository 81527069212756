import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Tab,
  Box,
  Avatar,
  Tooltip,
  Button,
  Stack,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useForm } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import * as Styled from '../../components/styled-components/Campaign'
import '../../styles/App.scss'
import { DataGrid } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { map } from 'lodash'
import { color, keyframes, style } from '@mui/system'
import CreateGroup from './CreateGroup'
import CreateUser from './CreateUser'
import request from '../../components/Helper/Request'
import { config } from '../../config/config'
import './index.module.css'
import DeleteModal from './DeleteModal'
import dayjs from 'dayjs'
import { DeleteOutlined, ModeEditOutlineOutlined } from '@mui/icons-material'
import SpinningLoader from '../../components/SpinningLoader'
import appConfig from '../../config/appConfig'
import Swal from 'sweetalert2'
import { CalculateUTCTzToUserTz, splitTime } from '../../components/Utils'

const floatRightToLeft = keyframes`
   0% {
    transform: translateX(0%) translateY(-50%);
  }
  100% {
    transform: translateX(-30%) translateY(-50%);
  }
`
const floatLeftToRight = keyframes`
   0% {
    transform: translateX(-30%) translateY(-50%);
  }
  100% {
    transform: translateX(50%) translateY(-50%);
  }
`

const Group = () => {
  const [tabValue, setTabValue] = useState('1')
  const [viewGroup, setViewGroup] = useState(false)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const [openUser, setOpenUser] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)
  const [userColumns, setUserColumns] = useState([])
  const [groupData, setGroupData] = useState([])
  const [editFormData, setEditFormData] = useState('')
  const [userDeleteData, setUserDeleteData] = useState('')
  const [groupDeleteData, setGroupDeleteData] = useState('')
  const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false)
  const [openGroupDeleteModal, setOpenGroupDeleteModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [action, setAction] = useState()
  const [userCount, setUserCount] = useState()
  const [groupCount, setGroupCount] = useState()
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 10,
  })
  const [pageStateGroup, setPageStateGroup] = useState({
    page: 0,
    pageSize: 10,
  })

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    })
  }
  const handlePaginationGroup = (pages) => {
    setPageStateGroup({
      page: pages.page,
      pageSize: pages.pageSize,
    })
  }

  const handleClickOpenUser = () => {
    setOpenUser(true)
  }

  const handleEditUser = () => {
    setEditUser(true)
  }

  const handleEditCloseUser = () => {
    setEditUser(false)
  }

  const handleClickOpenGroup = () => {
    setOpenGroup(true)
    setAction('Create')
    setUserData([])
  }

  const handleClickOpenGroupEdit = (data) => {
    setAction('Edit')
    getUserInGroup(data, 'Edit')
    setOpenGroup(true)
  }

  const handleCloseGroup = () => {
    setOpenGroup(false)
  }
  const handleCloseUser = () => {
    setOpenUser(false)
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
    },
  })

  const getUserDetails = async () => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/user/GetUser?page=${pageState.page}&size=${pageState.pageSize}`,
      })
      setUserColumns(res.userModelList)
      setUserCount(res.totalCount)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getUserInGroup = async (data, mode) => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/getGroupWithUsers/${data.groupId}`,
      })
      if (res.status === 200) {
        setUserData(res)
        if (mode === 'View') {
          viewListGroup()
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [pageState])

  const editData = (data) => {
    setUserId(data?.userId)
    setEditFormData(data)
    handleEditUser()
  }

  const columns = [
    { field: 'username', headerName: 'User Name', flex: 1 },
    { field: 'email', headerName: 'Email Id', flex: 1 },
    {
      field: 'createdTime',
      headerName: 'Created date',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>{dayjs(params?.row?.createdTime).format('MM-DD-YYYY')} </span>{' '}
            {/* <span>
              {splitTime(CalculateUTCTzToUserTz(params?.row?.createdTime))}
            </span> */}
          </>
        )
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'distOffice',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => editData(params?.row)}>
              <Tooltip title="Edit" placement="top">
                <ModeEditOutlineOutlined fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => handleUserDelete(params)}>
              <Tooltip title="Delete" placement="top">
                <DeleteOutlined fontSize="small" />
              </Tooltip>
            </IconButton>
          </>
        )
      },
    },
  ]

  const view = (data) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => getUserInGroup(data?.row, 'View')}>
          <Tooltip title="View Case" placement="right">
            <VisibilityIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    )
  }

  const viewListGroup = () => {
    setViewGroup(true)
  }
  const closeListGroup = () => {
    setViewGroup(false)
  }

  const handleUserDelete = (params) => {
    setUserDeleteData(params?.row?.userId)
    setOpenUserDeleteModal(true)
  }

  const handleGroupDelete = (params) => {
    setGroupDeleteData(params?.row?.groupId)
    setOpenGroupDeleteModal(true)
  }

  const groupColumns = [
    { field: 'name', headerName: 'Group Name', flex: 1 },
    { field: 'numberOfMembers', headerName: 'Number of Participants', flex: 1 },
    {
      field: 'createdTime',
      headerName: 'Created date',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>{dayjs(params?.row?.createdTime).format('MM-DD-YYYY')} </span>{' '}
            {/* <span>
              {splitTime(CalculateUTCTzToUserTz(params?.row?.createdTime))}
            </span> */}
          </>
        )
      },
    },

    {
      field: 'assignedDt',
      headerName: 'View ',
      flex: 1,
      renderCell: (params) => view(params),
    },
    {
      field: 'distOffice',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => handleClickOpenGroupEdit(params?.row)}>
              <Tooltip title="Edit" placement="top">
                <ModeEditOutlineOutlined fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => handleGroupDelete(params)}>
              <Tooltip title="Delete" placement="top">
                <DeleteOutlined fontSize="small" />
              </Tooltip>
            </IconButton>
          </>
        )
      },
    },
  ]

  const stringAvatar = (name) => {
    const nameParts = name.split(' ')
    const firstLetter = nameParts[0] ? nameParts[0][0] : ''
    const secondLetter = nameParts[1] ? nameParts[1][0] : ''
    return {
      children: `${firstLetter.charAt(0).toUpperCase()}${secondLetter
        .charAt(0)
        .toUpperCase()}`,
    }
  }

  const onUserDelete = async (userId) => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'delete',
        url: `${config.API_URL}/api/delete/user/${userId}?updatedBy=1`,
      })

      if (res === 'Deleted successfully') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'User deleted successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        setOpenUserDeleteModal(false)
        getUserDetails()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onGroupDelete = async (groupId) => {
    try {
      setIsLoading(true)

      const res = await request({
        method: 'delete',
        url: `${config.API_URL}/api/delete/group/${groupId}?updatedBy=1`,
      })

      if (res === 'Group Deleted Successfully') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Group deleted successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        setOpenGroupDeleteModal(false)
        getGroupDetails()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getGroupDetails = async () => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/group/getGroup?page=${pageStateGroup.page}&size=${pageStateGroup.pageSize}`,
      })
      setGroupData(res.groupModelList)
      setGroupCount(res.totalCount)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getGroupDetails()
  }, [pageStateGroup])

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Loading"
          />
        </Backdrop>
      ) : null}
      <Grid>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <h1 className="page-heading">
            <b>Users / Group</b>
          </h1>
          <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />
        </Grid>
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}
            sm={12}
            sx={{
              boxShadow:
                '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              border: '1px solid #e9e9e9',
            }}
          >
            <Box>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="User" value="1" />
                    <Tab label="Group" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box>
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: 1,
                      }}
                    >
                      <Styled.CampaignButton onClick={handleClickOpenUser}>
                        New User
                      </Styled.CampaignButton>
                    </Grid>

                    <DataGrid
                      getRowId={(row) => row.userId}
                      rows={userColumns ?? []}
                      columns={columns}
                      rowCount={userCount}
                      pageSizeOptions={[10, 20, 30]}
                      paginationMode="server"
                      onPaginationModelChange={handlePagination}
                      paginationModel={pageState}
                      autoHeight
                      disableColumnResize
                      loading={false}
                      disableRowSelectionOnClick={true}
                      disableColumnMenu={true}
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontSize: '16px',
                          fontWeight: '700',
                          padding: '0px 10px',
                          color: '#000000',
                        },
                        '& .MuiDataGrid-row': {
                          fontSize: '16px',
                          color: 'black',
                          padding: '0px 10px',
                          textAlign: 'center',
                        },
                      }}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box>
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Styled.CampaignButton
                        onClick={handleClickOpenGroup}
                        variant="contained"
                        sx={{
                          textTransform: 'capitalize',
                          margin: 1,
                        }}
                      >
                        New Group
                      </Styled.CampaignButton>
                    </Grid>
                    <DataGrid
                      getRowId={(row) => row.groupId}
                      rows={groupData ?? []}
                      columns={groupColumns}
                      rowCount={groupCount}
                      pageSizeOptions={[10, 20, 30]}
                      paginationMode="server"
                      onPaginationModelChange={handlePaginationGroup}
                      paginationModel={pageStateGroup}
                      autoHeight
                      disableColumnResize
                      loading={false}
                      disableRowSelectionOnClick={true}
                      disableColumnMenu={true}
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontSize: '16px',
                          fontWeight: '700',
                          padding: '0px 10px',
                          color: '#000000',
                        },
                        '& .MuiDataGrid-row': {
                          fontSize: '16px',
                          color: 'black',
                          padding: '0px 10px',
                          textAlign: 'center',
                        },

                        // '& .MuiDataGrid-columnHeaderTitleContainer': {
                        //   background: 'grey',
                        // },
                      }}
                    />

                    <Dialog
                      open={viewGroup}
                      onClose={closeListGroup}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Group Members
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          sx={{
                            border: 'solid 1px #CCCC',
                            borderRadius: '5px',
                          }}
                        >
                          {map(userData.userModelList, (item) => {
                            return (
                              <Grid
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                  padding: '1rem',
                                }}
                              >
                                <Avatar {...stringAvatar(item.username)} />
                                <Box>
                                  <Typography>{item.username}</Typography>
                                  <Typography>{item.email}</Typography>
                                </Box>
                              </Grid>
                            )
                          })}
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        {/* <Button onClick={closeListGroup}>Disagree</Button> */}
                        <Button
                          onClick={closeListGroup}
                          variant="outlined"
                          autoFocus
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {/* {viewGroup && (
                      <Grid
                        sx={{
                          position: 'absolute',
                          top: '62%',
                          display: viewGroup === false ? 'none' : 'flex',
                          right: '-5rem',
                          animation:
                            viewGroup === true
                              ? `${floatRightToLeft} 1s forwards ease-in-out`
                              : `${floatLeftToRight} 1s forwards ease-in-out`,
                          flexDirection: 'column',
                          padding: '1rem',
                          background: '#FFF',
                          boxShadow:
                            '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                          border: '1px solid #C2C2B7',
                          borderRadius: '6px',
                        }}
                      >
                        <Box
                          sx={{
                            border: 'solid 1px #CCCC',
                            borderRadius: '5px',
                          }}
                        >
                          {map(userData.userModelList, (item) => {
                            return (
                              <Grid
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                  padding: '1rem',
                                }}
                              >
                                <Avatar {...stringAvatar(item.username)} />
                                <Box>
                                  <Typography>{item.username}</Typography>
                                  <Typography>{item.email}</Typography>
                                </Box>
                              </Grid>
                            )
                          })}
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            pt: 1,
                          }}
                        >
                          <Styled.CancelButton
                            sx={{ padding: '5px' }}
                            onClick={() => {
                              closeListGroup()
                            }}
                          >
                            Close
                          </Styled.CancelButton>
                        </Box>
                      </Grid>
                    )} */}
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {openUser && (
        <CreateUser
          openUser={openUser}
          handleCloseUser={handleCloseUser}
          getUserDetails={getUserDetails}
        />
      )}

      {editUser && (
        <CreateUser
          openUser={editUser}
          handleCloseUser={handleEditCloseUser}
          edit
          editFormData={editFormData}
          getUserDetails={getUserDetails}
          userId={userId}
        />
      )}
      {openGroup && (
        <CreateGroup
          data={userData}
          action={action}
          handleCloseGroup={handleCloseGroup}
          openGroup={openGroup}
          getUserDetails={getUserDetails}
          userColumns={userColumns}
          getGroupDetails={getGroupDetails}
        />
      )}
      <DeleteModal
        open={openUserDeleteModal}
        close={() => setOpenUserDeleteModal(false)}
        // deleteData={deleteData}
        userDeleteData={userDeleteData}
        fn={onUserDelete}
      />

      <DeleteModal
        open={openGroupDeleteModal}
        close={() => setOpenGroupDeleteModal(false)}
        userDeleteData={groupDeleteData}
        fn={onGroupDelete}
      />
    </>
  )
}

export default Group
