import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';

const Field = ({
  label,
  required,
  error,
  fullWidth,
  children,
  showError,
  titleVariant,
}) => {
  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      sx={{ position: 'relative' }}
    >
      <FormLabel sx={{ color: '#000000', textAlign: 'left', mb: '0.4rem' }}>
        <Typography variant={titleVariant}>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </Typography>
      </FormLabel>
      {children}
      {error && showError && (
        <FormHelperText
          sx={{
            ml: 0,
            //  position: "absolute",
            // bottom: "-1.5rem",
          }}
          error
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  showError: PropTypes.bool,
  titleVariant: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
};

Field.defaultProps = {
  label: 'unknown',
  fullWidth: true,
  children: '',
  showError: true,
  titleVariant: 'h5grey',
  required: false,
  error: '',
};

export default Field;
