import React, { useState } from 'react'
import BackupIcon from '@mui/icons-material/Backup'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

import * as Styled from './styled-components/Campaign'
import CloseIcon from '@mui/icons-material/Close'
import { useRef } from 'react'

const FileUpload = ({ onChange, selectedFile }) => {
  const [pdfUrl, setPdfUrl] = useState(null)
  const [open, setOpen] = React.useState(false)
  const handleChange = (acceptedFiles) => {
    onChange(acceptedFiles)
  }

  const handlePDFPreview = () => {
    setPdfUrl(URL.createObjectURL(selectedFile))
    setOpen(true)
  }
  const descriptionElementRef = useRef('')

  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  return (
    <Styled.FileUploadWrapper>
      <Box
        style={{
          background: 'var(--grey-200)',
          width: '50%',
          height: '100%',
        }}
      >
        <FileUploader
          FileUploader
          handleChange={handleChange}
          name="file"
          types={['dat', 'csv', 'txt']}
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <BackupIcon
              sx={{ fontSize: 100, color: '#d9d9d9', marginBottom: '15px' }}
            />
            <Typography
              variant="h3"
              sx={{
                fontWeight: '700',
                fontSize: '1.2rem',
                color: '#6C6C6C',
                marginBottom: '15px',
              }}
            >
              Drop your files here
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: '10px' }}
            >
              <span
                style={{
                  border: '1px solid #e0e0e0',
                  width: '6rem',
                  height: '0px !important',
                }}
              />
              <span
                style={{
                  fontSize: '1.2rem',
                  fontWeight: '400',
                }}
              >
                or
              </span>
              <span
                style={{
                  border: '1px solid #e0e0e0',
                  width: '6rem',
                  height: '0px !important',
                }}
              />
            </Stack>
            <Button
              sx={{
                fontSize: '14px',
                color: '#000',
                fontWeight: '700',
                textTransform: 'capitalize !important',
                padding: '10px 20px',
                border: '2px solid #4096d6',
                borderRadius: '30px',
              }}
            >
              Browse Files
            </Button>
          </Box>
        </FileUploader>

        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'green',
          }}
        >
          {selectedFile?.name}

          {selectedFile?.name && (
            <IconButton onClick={handlePDFPreview}>
              <VisibilityOutlinedIcon />
            </IconButton>
          )}
        </span>

        {pdfUrl && (
          <>
            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h5">Preview</Typography>
                <object
                  className="fullscreen-object"
                  data={pdfUrl}
                  type="application/pdf"
                  width="100%"
                  height="350px"
                >
                  PDF Preview
                </object>
              </Box>
            </Modal> */}

            <Dialog
              open={open}
              maxWidth="md"
              fullWidth
              scroll="paper"
              onClose={handleClose}
              aria-labelledby="document"
              aria-describedby="documentDescription"
              sx={{ paddingBottom: '50px' }}
            >
              <DialogTitle id="document">Preview</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute !important',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <DialogContentText
                  id="documentDescription"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                ></DialogContentText>
                <object
                  className="fullscreen-object"
                  data={pdfUrl}
                  type="application/pdf"
                  width="100%"
                  height="350px"
                >
                  PDF Preview
                </object>
              </DialogContent>
            </Dialog>
          </>
        )}

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            fontSize: '15px',
            margin: '15px 0px',
            textWrap: 'nowrap',
          }}
        >
          Note:Supported file formats(csv,dat)
        </Stack>
      </Box>
    </Styled.FileUploadWrapper>
  )
}
FileUpload.defaultProps = {
  selectedFile: {},
  onChange: null,
}
FileUpload.propTypes = {
  selectedFile: PropTypes.objectOf(PropTypes.object),
  onChange: PropTypes.func,
}
export default FileUpload
