import React, { useState, useEffect, useRef } from 'react'
import { Grid, Typography, TextField, CircularProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TableCell from '@mui/material/TableCell'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import IconButton from '@mui/material/IconButton'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DefaultTable from '../components/Table'
import * as usersActions from '../store/actions/users'
import * as menuActions from '../store/actions/breadcrumb'
import ModalPopup from '../components/ModalPopup'
import * as Styled from '../components/styled-components/Campaign'
import SkeletonGridLoader from '../components/SkeletonGridLoader'
import SpinningLoader from '../components/SpinningLoader'
import '../styles/App.scss'
import roleList from '../components/ConfigData'
import appConfig from '../config/appConfig'
import { roles } from '../config/config'
import {
  CalculateUTCTzToUserTz,
  splitDate,
  splitTime,
  formatDTADate,
} from '../components/Utils'
import dayjs from 'dayjs'

const roleCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  width: '100%',
  '& .MuiSelect-select': { p: 1.3 },
}
const errorColor = '#F00'
const mb = { marginBottom: 0 }
const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } }
const textboxCss = {
  margin: '8px 0',
  '& .MuiInputBase-input': {
    backgroundColor: '#fcfcfb',
    fontSize: 16,
    fontFamily: appConfig.fontfamily,
    padding: '10px 14px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#e9f2fa',
  },
}
const Users = ({
  createUser,
  fetchUsersList,
  usersDetails,
  deleteUser,
  setEditUserData,
}) => {
  //
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const [open, setOpen] = useState(false)
  const [usersData, setUsersData] = useState(usersDetails.users)
  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const [isUserDeleted, setIsUserDeleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  useEffect(() => {
    fetchUsersList(
      userDetails?.accountId,
      pagination.limit,
      pagination.offset,
      'all'
    )
    setUsersData(usersDetails.users)
  }, [JSON.stringify(pagination)])

  useEffect(() => {
    if (usersDetails?.users) {
      const usersList = usersDetails.users.users.map((item) => {
        const userData = {
          ...item,
          role: item?.role.length && item?.role[0]?.roleCode?.code,
          status: item?.statusDetails?.status,
        }
        return userData
      })
      const data = { users: usersList, count: usersDetails?.users?.count }
      setUsersData(data)
    } else {
      setUsersData([])
    }
  }, [JSON.stringify(usersDetails.users)])

  useEffect(() => {
    if (usersDetails?.deleteUser?.status === 1 && isUserDeleted) {
      setIsUserDeleted(false)
      setIsLoading(false)
      Swal.fire({
        icon: 'success',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: usersDetails?.deleteUser?.message,
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else if (usersDetails?.deleteUser?.status === 0 && isUserDeleted) {
      setIsUserDeleted(false)
      setIsLoading(false)
      Swal.fire({
        icon: 'warning',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: 'Error, please try again later',
      })
    }
  }, [JSON.stringify(usersDetails?.deleteUser)])

  const handleCreateUser = () => {
    navigate('/createuser')
  }
  const handleEditUser = (selectedUser) => {
    setEditUserData(selectedUser)
    navigate('/edituser')
  }
  const handleClose = () => setOpen(false)

  const onSubmit = (data) => {
    const userData = { ...data, accountId: userDetails?.accountId }
    setIsFormSubmit(true)
    createUser(userData)
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.emailId}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.firstName}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.lastName}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.role === 0 ? '-' : roles[row.role]}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {' '}
          {row.modifiedBy ? row.modifiedBy : '-'} <br />{' '}
          {row?.modifiedTime
            ? dayjs(row.modifiedTime).format('MM-DD-YYYY')
            : '-'}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.modifiedTime))}{' '}
        </TableCell>
      </>
    )
  }

  const handleDeleteUser = (row) => {
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    })

    swalWithCustompButtons
      .fire({
        title: '',
        text: 'Delete this user?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setIsUserDeleted(true)
          setIsLoading(true)
          deleteUser(row.id, userDetails?.accountId, userDetails?.emailId)
        } else {
          setIsUserDeleted(false)
          setIsLoading(false)
        }
      })
  }

  const renderActionButton = (row) => {
    return (
      <div>
        {/* <IconButton disabled={row.role === "FPRADN"} sx={{ border: `1px solid ${row.role === "FPRADN" ? 'rgba(117,117,117,0.7)' :appConfig.colors.darkblue}`, padding: '2px' }} className='pad-0' onClick={() => { */}
        <IconButton
          disabled={row.role === 'FPRADN'}
          sx={{
            border: `1px solid ${
              row.role === 'FPRADN'
                ? 'rgba(117,117,117,0.7)'
                : appConfig.colors.darkblue
            }`,
            padding: '2px',
          }}
          className="pad-0"
          onClick={() => {
            handleEditUser(row)
          }}
          title="Update User"
          aria-label="delete"
          size="small"
        >
          <ModeEditOutlineOutlinedIcon
            sx={{
              fontSize: 24,
              borderRadius: '50%',
              color:
                row.role === 'FPRADN'
                  ? 'rgba(117,117,117,0.7)'
                  : appConfig.colors.darkblue,
            }}
          />
        </IconButton>
        <IconButton
          disabled={row.role === 'FPRADN'}
          sx={{
            border: `1px solid ${
              row.role === 'FPRADN'
                ? 'rgba(117,117,117,0.7)'
                : appConfig.colors.darkblue
            }`,
            padding: '2px',
            marginLeft: '10px',
          }}
          className="pad-0"
          onClick={() => {
            handleDeleteUser(row)
          }}
          title="Delete user"
          aria-label="delete"
          size="small"
        >
          <DeleteForeverOutlinedIcon
            sx={{
              fontSize: 24,
              borderRadius: '50%',
              color:
                row.role === 'FPRADN'
                  ? 'rgba(117,117,117,0.7)'
                  : appConfig.colors.darkblue,
            }}
          />
        </IconButton>
      </div>
    )
  }

  const handleSort = (sortValue) => {
    console.log('sort', sortValue)
  }

  useEffect(() => {
    if (
      (Object.keys(usersDetails?.registerDetails).length ||
        usersDetails?.isError) &&
      isFormSubmit
    ) {
      setIsFormSubmit(false)
      setOpen(false)
      if (
        !usersDetails?.isError &&
        usersDetails?.registerDetails?.status === 1
      ) {
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 2000,
          text: 'User added successfully',
        })
        window.setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        Swal.fire({
          icon: 'error',
          button: false,
          showConfirmButton: false,
          timer: 2000,
          text: usersDetails?.registerDetails?.message,
        })
        window.setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    }
  }, [
    JSON.stringify(usersDetails?.registerDetails),
    JSON.stringify(usersDetails?.errorMessage),
  ])

  return (
    <div>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Deleting user"
          />
        </Backdrop>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>User Management</b>
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          xl={6}
          sm={6}
          lg={6}
          md={6}
          sx={{
            textAlign: {
              xs: 'left',
              sm: 'right',
              lg: 'right',
              xl: 'right',
              md: 'right',
            },
          }}
        >
          <div>
            <Styled.CampaignButton
              variant="contained"
              onClick={handleCreateUser}
              className="text-trans-none"
            >
              Create New User
            </Styled.CampaignButton>
          </div>
        </Grid>
      </Grid>
      <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />

      {usersDetails?.users?.isLoading ? (
        <SkeletonGridLoader />
      ) : (
        <DefaultTable
          title="Total Users"
          handlePagination={handlePagination}
          // headers={['Email ID / User ID', 'First Name', 'Last Name', 'Role']}
          headers={[
            { name: 'Email ID / User ID', sortEnabled: false },
            { name: 'First Name', sortEnabled: false },
            { name: 'Last Name', sortEnabled: false },
            { name: 'Role', sortEnabled: false },
            { name: 'Last Updated', sortEnabled: false },
          ]}
          data={usersData.users}
          totalCount={usersData.count}
          // isSearchEnabled
          currentPage={pagination.offset}
          currentLimit={pagination.limit}
          renderCustomCells
          renderCells={renderCells}
          hasAction
          renderActionButton={renderActionButton}
          isSortEnabled
          handleSort={handleSort}
        />
      )}

      <ModalPopup open={open} closeBtn={false}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography id="modal-modal-title">
              <div className="inline-blk">
                <p>
                  <b>Create New User</b>
                </p>
              </div>
            </Typography>
            <br />

            <div
              style={{
                margin: '0 0 10px 20px',
                height: '400px',
                overflow: 'auto',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>First Name (Required)</p>
                  <TextField
                    {...register('firstName', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: 'Invalid First Name',
                      },
                    })}
                    inputProps={{ maxLength: '30' }}
                    margin="normal"
                    fullWidth
                    id="firstName"
                    sx={textboxCss}
                  />
                  {errors.firstName && (
                    <span style={{ color: errorColor }}>
                      {errors.firstName.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Last Name (Required)</p>
                  <TextField
                    {...register('lastName', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: 'Invalid Last Name',
                      },
                    })}
                    inputProps={{ maxLength: '30' }}
                    margin="normal"
                    fullWidth
                    id="lastName"
                    sx={textboxCss}
                  />
                  {errors.lastName && (
                    <span style={{ color: errorColor }}>
                      {errors.lastName.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Email Address (Required)</p>
                  <TextField
                    {...register('email', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    inputProps={{ maxLength: '50' }}
                    margin="normal"
                    fullWidth
                    id="email"
                    sx={textboxCss}
                  />
                  {errors.email && (
                    <span style={{ color: errorColor }}>
                      {errors.email.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Role (Required)</p>
                  <FormControl sx={roleCss}>
                    <Select
                      {...register('role', { required: true })}
                      displayEmpty
                    >
                      {roleList.map((item) => (
                        <MenuItem value={item.key}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.role && (
                    <span style={{ color: errorColor }}>
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>
            </div>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Styled.CampaignButton
                type="submit"
                variant="contained"
                disabled={usersDetails?.isLoading}
                sx={{
                  backgroundColor: usersDetails?.isLoading
                    ? '#909090 !important'
                    : appConfig.colors.darkblue,
                  color: '#FFFBF9 !important',
                  '&:hover': {
                    backgroundColor: usersDetails?.isLoading
                      ? '#909090 !important'
                      : appConfig.colors.darkblue,
                    color: '#FFFBF9 !important',
                  },
                }}
              >
                Create &nbsp;&nbsp;
                {usersDetails?.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </Styled.CampaignButton>
              &nbsp;&nbsp;
              <Styled.CancelButton
                variant="outlined"
                onClick={handleClose}
                disabled={usersDetails?.isLoading}
              >
                Close
              </Styled.CancelButton>
            </div>
          </form>
        </div>
      </ModalPopup>
    </div>
  )
}

const mapStateToProps = (state) => ({
  usersDetails: state.users,
  authDetails: state.auth,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersList: (accountId, limit, offset, filter) =>
      dispatch(usersActions.getUsersList(accountId, limit, offset, filter)),
    createUser: (fields) => dispatch(usersActions.createUser(fields)),
    deleteUser: (id, accId, modifiedBy) =>
      dispatch(usersActions.deleteUser(id, accId, modifiedBy)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
    setEditUserData: (fields) => dispatch(usersActions.updateUser(fields)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

Users.propTypes = {
  createUser: PropTypes.func.isRequired,
  usersDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchUsersList: PropTypes.func.isRequired,
  authDetails: PropTypes.arrayOf(PropTypes.array).isRequired,
  deleteUser: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
  setEditUserData: PropTypes.func.isRequired,
}
