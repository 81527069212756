import React, { useState, useEffect } from 'react'
import UserCard from './UserCard'
import CallIcon from '@mui/icons-material/Call'
import { IconButton } from '@mui/material'
import ChatBox, { ChatFrame } from 'react-chat-plugin'
import DeliveryImage from '../../assets/sent.png'
import { sendOutBoundMessage } from '../../store/actions/twoWayChat'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'

import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { config } from '../../config/config'
import dayjs from 'dayjs'
var stompClient = null
const RightSide = ({
  user,
  chatList,
  setSelectedUser,
  phoneNumberList,
  phoneNumberLists,
}) => {
  const dispatch = useDispatch()
  const [chatDetailsArr, setChatDetailsArray] = useState([])
  const [phoneNumberMessagesMap, setPhoneNumberMessagesMap] = useState(null)
  const [attr, setAttr] = useState({
    showChatbox: true,
    showIcon: false,
    messages: [],
  })

  useEffect(() => {
    if (chatList && chatList?.status === 1) {
      const data = chatList?.data
      const phoneNumberMessagesMap1 = {}
      data.forEach((entry) => {
        const phoneNumber = entry.mobileNo.slice(1)
        phoneNumberMessagesMap1[phoneNumber] =
          entry?.msgConversationJsonArray?.map((message) => {
            return {
              author: {
                username: message.communicationType === 'inbound' ? '' : '',
                id: message.communicationType === 'inbound' ? 2 : 1,
                imageUrl: message.communicationType === 'inbound' ? '' : null,
              },
              text: message.body,
              timestamp: message.timestamp,
              type: 'text',
            }
          })
      })
      setPhoneNumberMessagesMap(phoneNumberMessagesMap1)
      setChatDetailsArray(data)
    }
  }, [chatList?.data])

  //   useEffect(() => {
  //     if (phoneNumberList && phoneNumberList?.status === 1) {
  //       //   const defaultPhoneNumber =
  //       //     user || phoneNumberList?.phoneNumberList?.data[0];
  //       //   setSelectedUser(defaultPhoneNumber);
  //       const selectedChat = chatDetailsArr.find(
  //         (chat) => chat.mobileNo === user
  //       );

  //       setAttr((prevAttr) => ({
  //         ...prevAttr,
  //         showChatbox: true,
  //         messages: selectedChat
  //           ? selectedChat?.msgConversationJsonArray?.map((message) => ({
  //               author: {
  //                 username: message.communicationType === 'inbound' ? '' : '',
  //                 id: message.communicationType === 'inbound' ? 2 : 1,
  //                 imageUrl: message.communicationType === 'inbound' ? '' : null,
  //               },
  //               text: message.body,
  //               timestamp: message.timestamp,
  //               type: 'text',
  //             }))
  //           : [],
  //       }));
  //     }
  //   }, [phoneNumberMessagesMap, phoneNumberList, user]);

  useEffect(() => {
    if (phoneNumberLists && phoneNumberLists?.status === 1) {
      //   const defaultPhoneNumber =
      //     user || phoneNumberList?.phoneNumberList?.data[0];
      //   setSelectedUser(defaultPhoneNumber);
      const selectedChat = chatDetailsArr.find(
        (chat) => chat.mobileNo === user?.mobileNumber
      )

      setAttr((prevAttr) => ({
        ...prevAttr,
        showChatbox: true,
        messages: selectedChat
          ? selectedChat?.msgConversationJsonArray?.map((message) => ({
              author: {
                username: message.communicationType === 'inbound' ? '' : '',
                id: message.communicationType === 'inbound' ? 2 : 1,
                imageUrl: message.communicationType === 'inbound' ? '' : null,
              },
              text: message.body,
              timestamp: message.timestamp,
              type: 'text',
            }))
          : [],
      }))
    }
  }, [phoneNumberMessagesMap, phoneNumberLists, user])

  const handleOnSendMessage = (message) => {
    const newMessage = {
      author: {
        username: '',
        id: 1,
        avatarUrl: DeliveryImage,
      },
      text: message,
      type: 'text',
      timestamp: +new Date(),
    }

    setAttr((prevAttr) => ({
      ...prevAttr,
      messages: prevAttr.messages.concat(newMessage),
    }))
    const dataObj = {
      messageContent: message,
      mobileNo: user.mobileNumber,
    }
    const sendObj = {
      body: message,
      communicationType: 'outbound',
      timestamp: dayjs(),
    }
    dispatch(sendOutBoundMessage(dataObj))
    //send(sendObj);
  }

  function openSocketConnection() {
    try {
      console.log('open socket')
      let environmentUrl = new URL(`${config.API_URL}/chat`)
      const socketUrl = environmentUrl.toString()
      const socket = new SockJS(socketUrl)
      stompClient = Stomp.over(socket)
      stompClient.webSocketFactory = function () {
        return new WebSocket(socketUrl)
      }
      stompClient.connect({}, onConnected, onError)
    } catch (error) {
      console.log('error', error)
      dispatch({ type: 'SOCKET_CONNECTION_ERROR', payload: error })
    }
  }

  function onConnected() {
    stompClient.subscribe(`/topic/public/` + user?.mobileNumber, (message) => {
      const parsedMessage = JSON.parse(message.body)
      const newMessage = {
        author: {
          username: parsedMessage.communicationType === 'inbound' ? '' : '',
          id: parsedMessage.communicationType === 'inbound' ? 2 : 1,
          imageUrl: parsedMessage.communicationType === 'inbound' ? '' : null,
        },
        text: parsedMessage.body,
        timestamp: parsedMessage.timestamp,
        type: 'text',
      }
      setAttr((prevAttr) => ({
        ...prevAttr,
        messages: prevAttr.messages.concat(newMessage),
      }))
    })
  }

  function onError(error) {
    return async (dispatch) => {
      console.log('STOMP protocol error:', error)
      dispatch({ type: 'SOCKET_CONNECTION_ERROR', payload: error })
    }
  }

  function send(messageInput) {
    stompClient.send(
      '/app/chat.send/' + user.mobileNumber,
      {},
      JSON.stringify(messageInput)
    )
  }

  // useEffect(() => {
  //   if (user) {
  //     if (stompClient) {
  //       stompClient.disconnect()
  //     }
  //     openSocketConnection()
  //   }
  // }, [user])

  return (
    <>
      <div className="message_header" style={{ cursor: 'pointer' }}>
        <UserCard user={user} msg>
          <div className="call_icon">
            <IconButton
              sx={{
                fontSize: '1.1rem',
              }}
            >
              <CallIcon
                sx={{
                  fontSize: '1.1rem',
                }}
              />
              &nbsp;
              <span>call</span>
            </IconButton>
          </div>
        </UserCard>
      </div>
      <div className="holder-chat">
        {user && (
          <ChatFrame
            chatbox={
              <ChatBox
                onSendMessage={handleOnSendMessage}
                placeholder="Write  Message here..."
                userId={1}
                messages={attr.messages}
                activeAuthor={{
                  username: 'user2',
                  id: 2,
                  avatarUrl: null,
                }}
              />
            }
            showChatbox={attr.showChatbox}
          />
        )}
      </div>
      {/* <div className="chat">
        <div className="chat-messages">
          {messages.map((message, index) => (
            <Message key={index} text={message.text} sender={message.sender} />
          ))}
        </div>
        <form className="chat-input" onSubmit={sendMessage}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message"
          />
          <button type="submit">Send</button>
        </form>
      </div> */}
    </>
  )
}

export default RightSide
