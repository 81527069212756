import { postLogoutRedirectUri, msalConfig } from '../../config/config'

export default function idleTimer(msalInstance) {
  let t
  const homeAccount = JSON.parse(window.sessionStorage.getItem('home_account'))

  function idle() {
    if (homeAccount) {
      const currentAccount = msalInstance.getAccountByHomeId(
        homeAccount.homeAccountId
      )
      msalInstance.logout({
        account: currentAccount,
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
        authority: msalConfig.auth.authority,
      })
    }
    sessionStorage.clear()
    window.location.href = '/login'
  }

  function resetTimer() {
    clearTimeout(t)
    t = setTimeout(idle, 1200000)
  }

  window.onmousemove = resetTimer
  window.onmousedown = resetTimer
  window.onclick = resetTimer
  window.onscroll = resetTimer
  window.onkeypress = resetTimer
}
