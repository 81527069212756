import React, { useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { useNavigate, useLocation } from 'react-router-dom'
// import { endOfDay, startOfDay } from 'date-fns'
// import moment from 'moment';
import TableCell from '@mui/material/TableCell'
import appConfig from '../config/appConfig'
import DefaultTable from '../components/Table'
import * as campaignActions from '../store/actions/campaign'
import * as menuActions from '../store/actions/breadcrumb'
import * as Styled from '../components/styled-components/Campaign'
import SkeletonGridLoader from '../components/SkeletonGridLoader'
import '../styles/App.scss'
import CampaignSnapshot from './CampaignSnapshot'
import {
  CalculateUTCTzToUserTz,
  formatDTADate,
  getChannelLabel,
  splitDate,
  splitTime,
} from '../components/Utils'
import { config } from '../config/config'
import dayjs from 'dayjs'

const Campaign = ({ fetchCampaignList, campaignDetails, setBreadcrumb }) => {
  // const Campaign = ({ fetchCampaignList, campaignDetails, downloadSourceFile, fetchDownloadReport, setBreadcrumb }) => {
  const params = useLocation()
  const snapshotRef = useRef(null)
  const [enableRowDetails, setEnableRowDetails] = useState(false)
  const [campaignId, setCampaignId] = useState(null)
  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [campaignFilter, setCampaignFilter] = useState(
    params.state && params.state.dropdownSelected
      ? params.state.dropdownSelected
      : { key: 'All Communication Channels', value: 'all' }
  )
  const [selected, setSelected] = useState(null)
  const [sortValue, setSortValue] = useState({
    sortBy: 'modifiedTime',
    sortOrder: 'desc',
  })
  const navigate = useNavigate()
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const [searchText, setSearchText] = useState('')
  const isMounted = useRef(false)
  // const [selectedDate, setSelectedDate] = useState({
  //   startDate: startOfDay(new Date().setDate(new Date().getDate() - 30)),
  //   endDate: endOfDay(new Date())
  // });
  const [selectedCampaign, setSelectedCampaign] = useState({})

  useEffect(() => {
    setBreadcrumb('Campaigns')
    if (params.state && params.state.dropdownSelected) {
      setCampaignFilter(params.state.dropdownSelected)
    }
  }, [params])

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const getFilterValues = (filter) => {
    setPagination((prevState) => ({
      ...prevState,
      offset: 1,
    }))
    setEnableRowDetails(false)
    setCampaignId(null)
    setCampaignFilter(filter)
  }

  const filterValues = {
    filterBy: campaignFilter.value,
    sortValue: sortValue,
    // startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    // endDate: CalculateUserTzToUTCTz(endOfDay(selectedDate?.endDate)).toISOString()
  }

  useEffect(() => {
    isMounted.current = true
    fetchCampaignList(
      pagination.limit,
      pagination.offset,
      searchText,
      filterValues
    )
  }, [JSON.stringify(pagination), campaignFilter, JSON.stringify(sortValue)])

  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchCampaignList(
        pagination.limit,
        pagination.offset,
        searchText,
        filterValues
      )
    }
  }, [searchText])

  const loadRowDetails = () => {
    return (
      <CampaignSnapshot
        id={campaignId}
        desc={selectedCampaign.campaignDescription}
      />
    )
  }

  useEffect(() => {
    loadRowDetails()
  }, [campaignId])

  const renderDetailButton = (row) => {
    return (
      <div>
        <IconButton
          className="pad-0"
          onClick={() => {
            window.sessionStorage.setItem('campaign', JSON.stringify(row))
            setEnableRowDetails(
              campaignId !== row.id ? true : !enableRowDetails
            )
            setCampaignId(row.id)
            setSelected(row.id)
            setSelectedCampaign(row)
            setTimeout(() => {
              snapshotRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }, 500)
          }}
          title="View Details"
          aria-label="view details"
          size="small"
        >
          {campaignId === row.id && enableRowDetails ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
              fontSize="inherit"
            />
          )}
        </IconButton>
      </div>
    )
  }

  const handleSearch = (search) => {
    isMounted.current = false
    setSearchText(search)
  }

  // const onDateChange = (date) => {
  //   setSelectedDate({
  //     startDate: date?.[0]?.startDate || date?.startDate,
  //     endDate: date?.[0]?.endDate || date?.endDate
  //   });
  // }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.campaignName}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.channelName ? getChannelLabel(row.channelName) : '-'}
        </TableCell>
        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{formatDTADate(CalculateUTCTzToUserTz(row.modifiedTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {dayjs(row.modifiedTime).format('MM-DD-YYYY')}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.modifiedTime))}
        </TableCell>
        {/* <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{splitTime(CalculateUTCTzToUserTz(row.modifiedTime))}</TableCell> */}
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.createdBy}
        </TableCell>
      </>
    )
  }

  const onRefresh = () => {
    setPagination({ offset: 1, limit: 10 })
    setSearchText('')
    // setSelectedDate(
    //   {
    //     startDate: startOfDay(new Date().setDate(new Date().getDate() - 30)),
    //     endDate: endOfDay(new Date())
    //   }
    // )
    setCampaignFilter({ key: 'All Communication Channels', value: 'all' })
  }

  const renderActionButton = (row) => {
    return (
      <div className="display-flex">
        <IconButton
          sx={{
            border: `1px solid ${appConfig.colors.darkblue}`,
            padding: '2px',
          }}
          onClick={() => {
            navigate('/campaignfiles', { state: { campaign: row } })
          }}
          title="View Files Details"
          aria-label="delete"
          size="small"
        >
          <VisibilityIcon
            sx={{
              fontSize: 24,
              borderRadius: '50%',
              color: appConfig.colors.darkblue,
            }}
          />
        </IconButton>
      </div>
    )
  }

  const handleSort = (value) => {
    setSortValue(value)
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>Campaigns</b>
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          xl={6}
          lg={6}
          md={6}
          sx={{
            textAlign: {
              xs: 'left',
              sm: 'right',
              lg: 'right',
              xl: 'right',
              md: 'right',
            },
          }}
        >
          {userDetails?.authorities.includes('CAMCRE') ? (
            <div>
              <Styled.CancelButton
                variant="outlined"
                sx={{ mr: 1 }}
                color="primary"
                onClick={() => {
                  navigate('/uploadfile')
                }}
              >
                Upload Data File
              </Styled.CancelButton>
              <Styled.CampaignButton
                variant="contained"
                onClick={() =>
                  navigate('/createcampaign', { state: { initState: true } })
                }
              >
                Create Campaign
              </Styled.CampaignButton>
            </div>
          ) : null}
        </Grid>
      </Grid>
      <hr className="blue-border" />
      {campaignDetails?.campaigns?.isLoading ? (
        <SkeletonGridLoader />
      ) : (
        <DefaultTable
          filterAction={(filter) => {
            getFilterValues(filter)
          }}
          title="Campaigns"
          handlePagination={handlePagination}
          // headers={['Campaign Name','Channel','Modified Date','Modified Time','Created By']}
          headers={[
            { name: 'Campaign Name', sortEnabled: false },
            { name: 'Channel', sortEnabled: false },
            {
              name: 'Modified Date & Time',
              sortEnabled: true,
              sortBy: 'modifiedTime',
            },
            { name: 'Created By', sortEnabled: false },
          ]}
          hasDetails
          data={campaignDetails?.campaigns?.campaigns}
          totalCount={campaignDetails?.campaigns?.count}
          filterOptions={config.CHANNEL_LIST}
          renderDetailsButton={renderDetailButton}
          isFilterEnabled
          isSearchEnabled
          currentPage={pagination.offset}
          currentLimit={pagination.limit}
          defaultFilter={campaignFilter}
          selected={selected}
          loadRowDetails={enableRowDetails && loadRowDetails()}
          enableRowDetails={enableRowDetails}
          renderCustomCells
          renderCells={renderCells}
          searchKey={searchText}
          searchCallback={(text) => handleSearch(text)}
          searchPlaceholderText="Search by campaign name"
          // isDateFilter
          // selectedDateObj={(date) => onDateChange(date)}
          // dateFilterLabel={ `${moment(CalculateUTCTzToUserTz(selectedDate.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(selectedDate.endDate)).format('MM-DD-YYYY')}`}
          isRefreshEnabled
          refreshCallback={() => {
            onRefresh()
          }}
          hasAction
          renderActionButton={renderActionButton}
          isSortEnabled
          handleSort={handleSort}
          sortValue={sortValue}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({ campaignDetails: state.campaign })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignList: (limit, offset, search, filter) =>
      dispatch(campaignActions.getCampaignList(limit, offset, search, filter)),
    createNewCampaign: (data) => dispatch(campaignActions.createCampaign(data)),
    downloadSourceFile: (fileName, originalFileName) =>
      dispatch(
        campaignActions.fetchCampaignSourceFile(fileName, originalFileName)
      ),
    // fetchDownloadReport: (fileName) => dispatch(downloadReport(fileName)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)

Campaign.propTypes = {
  campaignDetails: PropTypes.object.isRequired,
  fetchCampaignList: PropTypes.func.isRequired,
  // downloadSourceFile: PropTypes.func.isRequired,
  // fetchDownloadReport: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}
