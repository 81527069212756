import * as actionTypes from '../actions/actionTypes'

const initialState = {
  analytics: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
  filters: {
    data: {},
    isLoading: false,
    isError: false,
  },
  invalidReports: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
  invalidFilters: {
    data: {},
    isLoading: false,
    isError: false,
  },
}

const analyticsReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.FETCH_ANALYTICS_DATA:
      return {
        ...newState,
        analytics: { ...newState.analytics, isLoading: true },
      }

    case actionTypes.FETCH_ANALYTICS_DATA_SUCCESS:
      return {
        ...newState,
        analytics: {
          data: [...action.payload.data],
          count: action.payload.count,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_ANALYTICS_DATA_FAILURE:
      return { isLoading: false, isError: true }

    case actionTypes.FETCH_FILTER_DATA:
      return { ...newState, filters: { ...newState.filters, isLoading: true } }

    case actionTypes.FETCH_FILTER_DATA_SUCCESS:
      return {
        ...newState,
        filters: {
          data: { ...action.payload },
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_FILTER_DATA_FAILURE:
      return { isLoading: false, isError: true }

    case actionTypes.FETCH_INVALID_REPORT_DATA:
      return {
        ...newState,
        invalidReports: { ...newState.invalidReports, isLoading: true },
      }

    case actionTypes.FETCH_INVALID_REPORT_DATA_SUCCESS:
      return {
        ...newState,
        invalidReports: {
          data: [...action.payload.data],
          count: action.payload.count,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_INVALID_REPORT_DATA_FAILURE:
      return { isLoading: false, isError: true }

    case actionTypes.FETCH_INVALID_FILTER_DATA:
      return {
        ...newState,
        invalidFilters: { ...newState.invalidFilters, isLoading: true },
      }

    case actionTypes.FETCH_INVALID_FILTER_DATA_SUCCESS:
      return {
        ...newState,
        invalidFilters: {
          data: { ...action.payload },
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_INVALID_FILTER_DATA_FAILURE:
      return { isLoading: false, isError: true }

    default:
      return { ...newState }
  }
}

export default analyticsReducer
