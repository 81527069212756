import React, { useState, useEffect, useRef } from 'react' //
import { Grid, TextField } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form' //
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import * as usersActions from '../store/actions/users'
// import * as menuActions from '../store/actions/breadcrumb';
import * as Styled from '../components/styled-components/Campaign'
import SpinningLoader from '../components/SpinningLoader'
import '../styles/App.scss'
import roleList from '../components/ConfigData'
import appConfig from '../config/appConfig'

const roleCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  width: '100%',
  '& .MuiSelect-select': { p: 1.3 },
  border: `1px solid ${appConfig.colors.darkblue}`,
  borderRadius: '5px',
}
const errorColor = '#F00'
const mb = { marginBottom: 0 }
const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } }
const textboxCss = {
  margin: '8px 0',
  '& .MuiInputBase-input': {
    backgroundColor: '#fcfcfb',
    fontSize: 16,
    fontFamily: appConfig.fontfamily,
    padding: '10px 14px',
    border: `1px solid ${appConfig.colors.darkblue}`,
    borderRadius: '5px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#e9f2fa',
  },
}
const CreateUser = ({ createUser, usersDetails }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  // const [isUserDeleted, setIsUserDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //     setBreadcrumb('User Management')
  // }, [])

  // useEffect(() => {
  //     if (usersDetails?.deleteUser?.status === 1 && isUserDeleted) {
  //         setIsUserDeleted(false);
  //         Swal.fire({
  //             icon: "success",
  //             button: false,
  //             showConfirmButton: false,
  //             timer: 2000,
  //             text: "User deleted successfully",
  //         });
  //         setTimeout(() => {
  //             window.location.reload();
  //         }, 2000);
  //     }
  // }, [JSON.stringify(usersDetails?.deleteUser)])

  const checkConfirmation = (formDat) => {
    // if (selectedFile) {
    const swalWithCustompButtons = Swal.mixin({
      customClass: {
        confirmButton: 'custom-ok-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    })

    swalWithCustompButtons
      .fire({
        title: '',
        text: 'Create user?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Create',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true)
          createUser(formDat)
        }
      })

    // } else {
    //     setError({ showErrorMsg: true })
    // }
  }

  const onSubmit = (data) => {
    const userData = {
      ...data,
      accountId: userDetails?.accountId,
      createdBy: userDetails?.emailId,
    }
    setIsFormSubmit(true)
    checkConfirmation(userData)
  }

  useEffect(() => {
    if (
      (Object.keys(usersDetails?.registerDetails).length ||
        usersDetails?.isError) &&
      isFormSubmit
    ) {
      setIsFormSubmit(false)
      if (
        !usersDetails?.isError &&
        usersDetails?.registerDetails?.status === 1
      ) {
        setIsLoading(false)
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 2000,
          text: 'User created successfully',
        })
        window.setTimeout(() => {
          navigate('/usermanagement')
        }, 1000)
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          button: false,
          showConfirmButton: false,
          timer: 2000,
          text: usersDetails?.registerDetails?.message,
        })
        window.setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    }
  }, [
    JSON.stringify(usersDetails?.registerDetails),
    JSON.stringify(usersDetails?.errorMessage),
  ])

  return (
    <div>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Creating user"
          />
        </Backdrop>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <h1 className="page-heading">
            <b>Create User</b>
          </h1>
        </Grid>
      </Grid>
      <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />

      <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ margin: '0 0 10px 20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sm={12} sx={gridItemCss}>
                  <p style={mb}>First Name (Required)</p>
                  <TextField
                    {...register('firstName', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message: 'First name must be alphabetic',
                      },
                    })}
                    inputProps={{ maxLength: '30' }}
                    margin="normal"
                    fullWidth
                    id="firstName"
                    sx={textboxCss}
                  />
                  {errors.firstName && (
                    <span style={{ color: errorColor }}>
                      {errors.firstName.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Last Name (Required)</p>
                  <TextField
                    {...register('lastName', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[a-zA-Z]*$/,
                        message: 'Last name must be alphabetic',
                      },
                    })}
                    inputProps={{ maxLength: '30' }}
                    margin="normal"
                    fullWidth
                    id="lastName"
                    sx={textboxCss}
                  />
                  {errors.lastName && (
                    <span style={{ color: errorColor }}>
                      {errors.lastName.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Email Address (Required)</p>
                  <TextField
                    {...register('email', {
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    inputProps={{ maxLength: '50' }}
                    margin="normal"
                    fullWidth
                    id="email"
                    sx={textboxCss}
                  />
                  {errors.email && (
                    <span style={{ color: errorColor }}>
                      {errors.email.message}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} lg={12} md={12} sx={gridItemCss}>
                  <p style={mb}>Role (Required)</p>
                  <FormControl sx={roleCss}>
                    <Select
                      {...register('role', { required: true })}
                      displayEmpty
                    >
                      {roleList.map((item) => (
                        <MenuItem value={item.key}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.role && (
                    <span style={{ color: errorColor }}>
                      This field is required
                    </span>
                  )}
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={1} sx={{ paddingLeft: '10px' }}>
              <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                <Styled.CancelButton
                  variant="outlined"
                  sx={{
                    mt: 3,
                    ml: 1,
                    fontFamily: appConfig.fontfamily,
                    textTransform: 'capitalize',
                    width: '100%',
                    height: '50px',
                  }}
                  onClick={() => navigate('/usermanagement')}
                >
                  Cancel
                </Styled.CancelButton>
              </Grid>
              <Grid item xs={12} sm={12} xl={8.5} lg={8.5} md={8.5}>
                <Styled.CampaignButton
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    ml: 1,
                    fontFamily: appConfig.fontfamily,
                    textTransform: 'capitalize',
                    width: '100%',
                    height: '50px',
                  }}
                >
                  Create
                </Styled.CampaignButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  usersDetails: state.users,
  authDetails: state.auth,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersList: (accountId, limit, offset, filter) =>
      dispatch(usersActions.getUsersList(accountId, limit, offset, filter)),
    createUser: (fields) => dispatch(usersActions.createUser(fields)),
    deleteUser: (id) => dispatch(usersActions.deleteUser(id)),
    // setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)

CreateUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  usersDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchUsersList: PropTypes.func.isRequired,
  authDetails: PropTypes.arrayOf(PropTypes.array).isRequired,
  deleteUser: PropTypes.func.isRequired,
  // setBreadcrumb: PropTypes.func.isRequired
}
