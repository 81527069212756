import React, { useEffect, useState } from 'react'
import { Grid, TextField, Autocomplete, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'
import Swal from 'sweetalert2'
import FormControl from '@mui/material/FormControl'

import * as Styled from '../components/styled-components/Campaign'
import SpinningLoader from '../components/SpinningLoader'
import FormDialog from '../components/Modal'
import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent'

import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
} from '../components/Utils'
import appConfig from '../config/appConfig'
import * as billingActions from '../store/actions/billing'

const dropDownlistCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  border: `1px solid ${appConfig.colors.darkblue}`,
  borderRadius: '5px',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px' },
}
const calenderCss = {
  mt: 1,
  mb: 1,
  background: '#fcfcfb',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px' },
  zIndex: 1000,
}

const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } }

const mb = {
  marginBottom: 0,
  fontWeight: 'bold',
  color: appConfig.colors.darkgray,
  fontFamily: 'Source Sans Pro, sans-serif !important',
}
const ErrorStyle = {
  color: 'red',
  display: 'flex',
  marginTop: '5px',
}

const textboxCss = {
  border: `1px solid ${appConfig.colors.darkblue}`,
  borderRadius: '4px',
  outline: 'none',
  '&.MuiFormControl-root': {
    margin: '0',
  },
  '&.MuiTextField-root': {
    backgroundColor: '#fff',
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    fontSize: 16,
    fontFamily: appConfig.fontfamily,
    padding: '10px 14px',
    borderRadius: '4px',
    outline: 'none',
  },
  '& .MuiOutlinedInput-root': {
    padding: '0',
  },
}

const Billing = ({
  billingData,
  createBilling,
  fetchBillingFilters,
  getBillingDashboardData,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()
  const [isTriggerReload, setisTriggerReload] = useState(false)
  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
  const filterInitialState = {
    channels: { key: 'SMS', value: 'SMS' },
    startDate: CalculateUserTzToUTCTz(startOfDay(new Date()).toISOString()),
    endDate: CalculateUserTzToUTCTz(endOfDay(new Date()).toISOString()),
    billingCode: { key: 'All', value: null },
    campaign: { key: 'All', value: null },
    template: { key: 'All', value: null },
  }
  const [filterData, setFilterData] = useState(filterInitialState)
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let campaignList = billingData?.dashboardLov?.data?.campaigns || []
  const billingCodeList = billingData?.dashboardLov?.data?.billingCodes || []
  let templatesList = billingData?.dashboardLov?.data?.templates || []

  const statusObj = billingData?.dashboard?.data?.statusObj || []
  const langObj = billingData?.dashboard?.data?.languageObj || []

  const ChannelList = [
    { key: 'SMS', value: 'SMS' },
    { key: 'Email', value: 'Email' },
  ]

  if (filterData.billingCode.key !== 'All') {
    campaignList = [
      ...(billingData?.dashboardLov?.data?.campaigns.filter(
        (camp) => camp.billingCodeId === filterData.billingCode.value
      ) || ''),
    ]
    campaignList.unshift({
      value: 'All',
      key: null,
    })
  }

  if (filterData.campaign.key !== 'All') {
    templatesList = [
      ...(billingData?.dashboardLov?.data?.templates.filter(
        (temp) => temp.campaignId === filterData.campaign.value
      ) || ''),
    ]
    templatesList.unshift({
      value: 'All',
      key: null,
    })
  }

  // if (filterData.template.key !== "All") {
  //     templatesList = [
  //         ...billingData?.dashboardLov?.data?.templates.filter((temp) => temp.key === filterData.template.value) || "",
  //     ];
  //     templatesList.unshift({
  //         "value": "All",
  //         "key": null
  //     })
  // }

  const getFilters = () => {
    let selectedBillingCodes = []
    let selectedCampaigns = []
    let selectedTemplates = []

    selectedBillingCodes =
      filterData.billingCode.key === 'All'
        ? billingCodeList.map((bill) => bill.key)
        : [filterData.billingCode.value]

    selectedCampaigns =
      filterData.campaign.key === 'All'
        ? campaignList.map((camp) => camp.key).filter((cam) => cam != null)
        : [filterData.campaign.value]

    selectedTemplates =
      filterData.template.key === 'All'
        ? templatesList.map((temp) => temp.key).filter((tem) => tem != null)
        : [filterData.template.value]

    // to reduce backend query

    if (
      filterData.billingCode.key === 'All' &&
      filterData.campaign.key === 'All' &&
      filterData.template.key === 'All'
    ) {
      selectedTemplates = []
      selectedCampaigns = []
      selectedBillingCodes = []
    }

    return {
      selectedBillingCodes,
      selectedCampaigns,
      selectedTemplates,
    }
  }

  useEffect(() => {
    fetchBillingFilters()
    const filters = getFilters()
    const data = {}
    data.startDate = filterData.startDate.toISOString()
    data.endDate = filterData.endDate.toISOString()
    data.filterByChannel = filterData.channels.value.toLocaleLowerCase()
    data.filterByCampaign = filters.selectedCampaigns
    data.filterByBillingCode = filters.selectedBillingCodes
    data.filterByTemplate = filters.selectedTemplates
    getBillingDashboardData(data)
  }, [])

  const fetchBillDBData = () => {
    const filters = getFilters()
    const data = {}
    data.startDate = filterData.startDate.toISOString()
    data.endDate = filterData.endDate.toISOString()
    data.filterByChannel = filterData.channels.value.toLocaleLowerCase()
    data.filterByCampaign = filters.selectedCampaigns
    data.filterByBillingCode = filters.selectedBillingCodes
    data.filterByTemplate = filters.selectedTemplates
    getBillingDashboardData(data)
  }

  const onClear = () => {
    setFilterData(filterInitialState)
    setisTriggerReload(true)
  }

  useEffect(() => {
    if (isTriggerReload) {
      fetchBillingFilters()
      const data = {}
      data.startDate = CalculateUserTzToUTCTz(startOfDay(new Date()))
      data.endDate = CalculateUserTzToUTCTz(endOfDay(new Date()))
      data.filterByChannel = filterData.channels.value.toLocaleLowerCase()
      data.filterByCampaign = data.selectedCampaigns
      data.filterByBillingCode = data.selectedBillingCodes
      data.filterByTemplate = data.selectedTemplates
      getBillingDashboardData(data)
    }
  }, [isTriggerReload])

  useEffect(() => {
    setIsLoading(false)
    if (billingData.createBilling.data.status === 1) {
      Swal.fire({
        icon: 'success',
        button: false,
        showConfirmButton: false,
        timer: 2500,
        text: 'Billing code created successfully',
      })
    } else if (billingData.createBilling.data.status === 0) {
      Swal.fire({
        icon: 'error',
        button: false,
        showConfirmButton: false,
        timer: 2500,
        text: billingData.createBilling.data.message,
      })
    }
  }, [JSON.stringify(billingData.createBilling)])

  const checkConfirmation = (formData) => {
    createBilling(formData)
    setOpen(false)
    setIsLoading(true)
    reset({ code: '', description: '' })
  }

  const handleFormSubmit = (data) => {
    if (!Object.keys(errors).length) {
      checkConfirmation(data)
    }
  }

  const renderModalBody = () => {
    return (
      <div className="modal-body">
        <Grid container spacing={2}>
          <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
            <div>
              <Typography
                component="p"
                sx={{ mb: 1, fontFamily: 'Source Sans Pro' }}
              >
                {' '}
                Billing Code (Required)
              </Typography>
              <TextField
                {...register('code', {
                  required: true,
                  pattern: /^[A-Za-z0-9 ]+$/,
                  maxLength: 6,
                  minLength: 6,
                })}
                margin="normal"
                id="name"
                sx={textboxCss}
                fullWidth
                inputProps={{ 'aria-label': 'Billing code' }}
              />
            </div>
            {errors?.code?.type === 'required' && (
              <span style={ErrorStyle}>Please enter billing code.</span>
            )}
            {errors?.code?.type === 'pattern' && (
              <span style={ErrorStyle}>Special characters not allowed.</span>
            )}
            {errors?.code?.type === 'maxLength' && (
              <span style={ErrorStyle}>Billing code must be 6 characters.</span>
            )}
            {errors?.code?.type === 'minLength' && (
              <span style={ErrorStyle}>Billing code must be 6 characters.</span>
            )}
          </Grid>
          <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
            <div>
              <Typography
                component="p"
                sx={{ mb: 1, fontFamily: 'Source Sans Pro' }}
              >
                Description
              </Typography>
              <TextField
                {...register('description', { pattern: /^[A-Za-z0-9 ]+$/ })}
                margin="normal"
                id="description"
                sx={textboxCss}
                fullWidth
                inputProps={{ 'aria-label': 'campaign description' }}
              />
            </div>
            {errors?.description?.type === 'pattern' && (
              <span style={ErrorStyle}>Special characters not allowed.</span>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderBillingByKPI = (list, type) => {
    let listObj = [...list]
    const possibleStatuses = [
      'Queued',
      'Sent',
      'Delivered',
      'Undelivered',
      'Failed',
      'Invalid',
      'Cancelled',
    ]
    const possibleLanguages = ['en', 'es', 'vi', 'pt', 'ht', 'zh']
    const possibleFilters =
      type === 'status' ? possibleStatuses : possibleLanguages

    if (list.length > 0) {
      const responseDict = listObj.reduce((acc, obj) => {
        acc[obj[type]] = obj
        return acc
      }, {})
      possibleFilters.forEach((status) => {
        if (!responseDict[status]) {
          responseDict[status] = {
            totalMessages: 0,
            totalSegments: 0,
            [type]: status,
            totalPrice: 0,
          }
        }
      })

      listObj = Object.values(responseDict)
    }

    const mapLanguage = (language) => {
      switch (language) {
        case 'en':
          return 'English'
        case 'es':
          return 'Spanish'
        case 'vi':
          return 'Vietnamese'
        case 'pt':
          return 'Portuguese'
        case 'ht':
          return 'Haitian Creole'
        case 'zh':
          return 'Chinese'
        default:
          return language
      }
    }

    const renderCard = (modifiedObj) => (
      <div className="bill-card">
        <p className="txt-md">
          <b>{mapLanguage(modifiedObj[type])}</b>
        </p>
        <div>
          <div style={{ marginBottom: '5px' }}>
            <span className="txt-heading">Total Messages : </span>
            <span className="txt-heading">
              {modifiedObj.totalMessages.toLocaleString('en-US') || 0}
            </span>
          </div>
          <div>
            <span className="txt-heading">Total Cost : </span>
            <span className="txt-heading">
              {' '}
              $ {modifiedObj.totalPrice.toFixed(3) || 0}
            </span>
          </div>
        </div>
      </div>
    )

    return listObj.length > 0 ? (
      listObj.map(renderCard)
    ) : (
      <div style={{ width: '100%' }} className="blk-center">
        <h4 className="txt-grey">No Data</h4>{' '}
      </div>
    )
  }

  const handleFilter = (data, input) => {
    setisTriggerReload(false)
    switch (input) {
      case 'channel':
        setFilterData({
          ...filterData,
          channels: { key: data.label, value: data.id },
        })
        break

      case 'billingCode':
        setFilterData({
          ...filterData,
          billingCode: { key: data.label, value: data.value },
        })
        break

      case 'campaign':
        setFilterData({
          ...filterData,
          campaign: { key: data.label, value: data.value },
        })
        break

      case 'template':
        setFilterData({
          ...filterData,
          template: { key: data.label, value: data.value },
        })
        break
      case 'date':
        setFilterData({
          ...filterData,
          endDate: CalculateUserTzToUTCTz(
            data?.[0]?.endDate
              ? endOfDay(data?.[0]?.endDate)
              : endOfDay(data?.endDate)
          ),
          startDate: CalculateUserTzToUTCTz(
            data?.[0]?.startDate || startOfDay(data?.startDate)
          ),
        })
        break

      default:
        setFilterData({
          ...filterData,
        })
    }
  }

  useEffect(() => {
    setFilterData(filterInitialState)
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    reset({ code: '', description: '' })
  }

  return (
    <div>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Creating billing code"
          />
        </Backdrop>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>Billing</b>
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          xl={6}
          lg={6}
          md={6}
          sx={{
            textAlign: {
              xs: 'left',
              sm: 'right',
              lg: 'right',
              xl: 'right',
              md: 'right',
            },
          }}
        >
          {userDetails?.authorities.includes('BILCRE') ? (
            <div>
              <Styled.CampaignButton
                variant="contained"
                onClick={handleClickOpen}
              >
                Create Billing Code
              </Styled.CampaignButton>
            </div>
          ) : null}
        </Grid>
      </Grid>
      <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />

      {billingData?.dashboardLov?.isLoading ? (
        <SpinningLoader
          label="Loading Filters..."
          circleColor={appConfig.colors.darkblue}
        />
      ) : (
        <div className="filter-container">
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} md={4} xl={4} sx={gridItemCss}>
              <p style={mb}>By Channel</p>
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  openOnFocus
                  clearOnBlur
                  disabled
                  className="autocomplete-class"
                  value={
                    filterData.channels.key
                      ? filterData.channels.key
                      : 'Select Channel'
                  }
                  options={ChannelList.map((item) => ({
                    label: item.value,
                    id: item.key,
                  }))}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'channel',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                  onChange={(e, val) => {
                    handleFilter(val, 'channel')
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4} md={4} xl={4} sx={gridItemCss}>
              <p style={mb}>By Date</p>
              <FormControl sx={calenderCss}>
                <DateRangePickerComponent
                  label={`${moment(
                    CalculateUTCTzToUserTz(filterData.startDate)
                  ).format('MM-DD-YYYY')} - ${moment(
                    CalculateUTCTzToUserTz(filterData.endDate)
                  ).format('MM-DD-YYYY')}`}
                  cssStyle={{
                    color: '#111',
                    fontSize: '15px',
                    border: '1px solid #bbb',
                    justifyContent: 'flex-start',
                  }}
                  selectedDateObj={(date) => {
                    handleFilter(date, 'date')
                  }}
                  staticDateRange={{
                    threeMonths: true,
                    sixMonths: true,
                    oneYear: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4} md={4} xl={4} sx={gridItemCss}>
              <p style={mb}>By Billing Code</p>
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  openOnFocus
                  clearOnBlur
                  className="autocomplete-class"
                  value={
                    filterData.billingCode.key
                      ? filterData.billingCode.key
                      : 'Select Billing Code'
                  }
                  options={billingCodeList.map((item) => ({
                    label: `${item.value}${
                      item.description ? ` ${item.description}` : ''
                    }`,
                    value: item.key,
                  }))}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'billing code',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                  onChange={(e, val) => {
                    handleFilter(val, 'billingCode')
                  }}
                  disabled={billingData?.dashboardLov?.isLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} md={4} xl={4} sx={gridItemCss}>
              <p style={mb}>By Campaign</p>
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  openOnFocus
                  clearOnBlur
                  className="autocomplete-class"
                  value={
                    filterData.campaign.key
                      ? filterData.campaign.key
                      : 'Select Campaign'
                  }
                  options={campaignList.map((item) => ({
                    label: item.value,
                    value: item.key,
                  }))}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'campaign',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                  onChange={(e, val) => {
                    handleFilter(val, 'campaign')
                  }}
                  disabled={billingData?.dashboardLov?.isLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3} md={4} xl={4} sx={gridItemCss}>
              <p style={mb}>By Template</p>
              <FormControl sx={dropDownlistCss}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  openOnFocus
                  clearOnBlur
                  className="autocomplete-class"
                  value={
                    filterData.template.key
                      ? filterData.template.key
                      : 'Select Campaign'
                  }
                  options={templatesList.map((item) => ({
                    label: item.value,
                    value: item.key,
                  }))}
                  renderInput={(params) => {
                    const newParams = {
                      ...params,
                      inputProps: {
                        ...params.inputProps,
                        'aria-label': 'campaign',
                      },
                    }
                    return <TextField {...newParams} />
                  }}
                  onChange={(e, val) => {
                    handleFilter(val, 'template')
                  }}
                  disabled={billingData?.dashboardLov?.isLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4} md={4} xl={4} sx={{ mt: 4 }}>
              <div style={{ float: 'right', padding: '0 15px' }}>
                <Styled.CampaignButton
                  onClick={fetchBillDBData}
                  style={{ marginRight: '30px' }}
                >
                  Apply
                </Styled.CampaignButton>
                <Styled.CancelButton onClick={onClear}>
                  Clear
                </Styled.CancelButton>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          {billingData?.dashboard?.isLoading ? (
            <SpinningLoader
              circleColor={appConfig.colors.darkblue}
              label="loading..."
            />
          ) : (
            <div>
              <div className="dashboard-heading">
                <span>
                  <b>Billing Statistics</b>
                </span>
              </div>
              {billingData?.dashboard?.data?.totalMessages > 0 ? (
                <Grid
                  container
                  className="dashboard-data-block"
                  style={{ marginLeft: '-10px' }}
                >
                  <div className="bill-card">
                    <p className="txt-heading">
                      <b>Total Messages</b>
                    </p>
                    <span className="txt-md">
                      {billingData?.dashboard?.data?.totalMessages
                        ? billingData?.dashboard?.data?.totalMessages.toLocaleString(
                            'en-US'
                          )
                        : 0}
                    </span>
                  </div>
                  <div className="bill-card">
                    <p className="txt-heading">
                      <b>Total Segments</b>
                    </p>
                    <span className="txt-md">
                      {billingData?.dashboard?.data?.totalSegments
                        ? billingData?.dashboard?.data?.totalSegments.toLocaleString(
                            'en-US'
                          )
                        : 0}
                    </span>
                  </div>
                  <div className="bill-card">
                    <p className="txt-heading">
                      <b>Total Cost</b>
                    </p>
                    <span className="txt-md">
                      ${' '}
                      {billingData?.dashboard?.data?.totalPrice
                        ? billingData?.dashboard?.data?.totalPrice.toFixed(3)
                        : 0}
                    </span>
                  </div>
                </Grid>
              ) : (
                <div style={{ width: '100%' }} className="blk-center">
                  <h4 className="txt-grey">No Data</h4>{' '}
                </div>
              )}

              <div className="dashboard-heading">
                <span>
                  <b>Billing By Status</b>
                </span>
              </div>
              <Grid
                container
                className="dashboard-data-block"
                style={{ marginLeft: '-10px' }}
              >
                {renderBillingByKPI(statusObj, 'status')}
              </Grid>

              <div className="dashboard-heading">
                <span>
                  <b>Billing By Language</b>
                </span>
              </div>
              <Grid
                container
                className="dashboard-data-block"
                style={{ marginLeft: '-10px' }}
              >
                {renderBillingByKPI(langObj, 'language')}
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>

      <FormDialog
        secondaryAction={handleClose}
        primaryAction={handleSubmit(handleFormSubmit)}
        handleClose={handleClose}
        secondaryText="Cancel"
        PrimaryText="Create"
        title="Create Billing Code"
        status={open}
      >
        {renderModalBody()}
      </FormDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({ billingData: state.billing })

const mapDispatchToProps = (dispatch) => {
  return {
    createBilling: (data) => dispatch(billingActions.createBilling(data)),
    fetchBillingFilters: () =>
      dispatch(billingActions.getBillingDashboardFilters()),
    getBillingDashboardData: (data) =>
      dispatch(billingActions.getBillingDashboardData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)

Billing.propTypes = {
  createBilling: PropTypes.func.isRequired,
  fetchBillingFilters: PropTypes.func.isRequired,
  getBillingDashboardData: PropTypes.func.isRequired,
  billingData: PropTypes.objectOf(PropTypes.object).isRequired,
  key: PropTypes.objectOf(PropTypes.object).isRequired,
}
