/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import {
  Backdrop,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import '../../styles/campaign.scss'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Field from '../../components/Field'
import FileUpload from '../../components/FileUpload'
import CoDropdown from '../../controllers/CoDropdown'
import * as campaignActions from '../../store/actions/campaign'
import CoTextField from '../../controllers/CoTextField'
import useLookup from '../../components/useLookup'
import request from '../../components/Helper/Request'
import { config } from '../../config/config'
import Swal from 'sweetalert2'
import SpinningLoader from '../../components/SpinningLoader'
import appConfig from '../../config/appConfig'
import {
  CLEAR_CREATE_CAMPAIGN_VALUES,
  SET_CREATE_CAMPAIGN_VALUES,
} from '../../store/actions/actionTypes'
import { findIndex, map, size } from 'lodash'
import { getBillingCodeLov } from '../../store/actions/billing'

const CampaignNew = ({
  createCampaign: createCampaign1,
  campaignDetail,
  setCurrentStep,
  setCampaignId,
  campaignId,
  disableCampaign,
  billing,
  fetchBillingCodes,
}) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedBillingCode, setSelectedBillingCode] = useState([])
  const billingCodeList = billing?.billingLov?.data || []
  const [groupData, setGroupData] = useState([])

  const billingCodeOpts = useMemo(() => {
    return map(billingCodeList, ({ key, value }) => ({
      label: value,
      value: key,
    }))
  }, [billingCodeList])

  const schema = yup.object().shape({
    title: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, 'Title should not contain special characters')
      .required('Enter Campaign Title'),
    channel: yup.string().required('Select Channel'),
    billingCodeId: yup.string().required('Select Billing Code'),
    country: yup.string().required('Select the Country'),
  })

  const {
    watch,
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // title: campaignDetail?.createCampaignData?.campaignName ?? '',
      title: '',
      campaignType: '',
      country: '',
      channel: '',
      priority: '',
      description: '',
      file: null,
      billingCodeId: '',
    },
  })

  const { getOptions } = useLookup('creatercampaign')
  const { campaignType, country, communicationChannel, campaignPriority } =
    useMemo(() => {
      return {
        campaignType: getOptions('CampaignType'),
        country: getOptions('Country'),
        communicationChannel: getOptions('CommunicationChannel'),
        campaignPriority: getOptions('Campaign Priority'),
      }
    }, [getOptions])

  const getGroupDetails = async () => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/group/getGroup?page=0&size=500`,
      })
      // if (res?.status === 200) {
      const lookup = map(res?.groupModelList, (item) => {
        return {
          label: item.name,
          value: item.groupId,
        }
      })
      setGroupData(lookup)
      // }
    } catch (error) {
      //
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getGroupDetails()
  }, [])

  const handleFileChange = (accptedFiles) => {
    setSelectedFile(accptedFiles)
  }

  const dropDownlistCss = {
    mt: 1,
    mb: 1,
    borderRadius: '5px',
    border: `1px solid ${appConfig.colors.darkblue}`,
    background: '#fcfcfb',
    width: '100%',
    '.MuiAutocomplete-input': { height: '5px' },
  }

  const handleBillingCodeChange = (data) => {
    setSelectedBillingCode(data)
    // setBillingCodeError(false)
  }

  const createCampaign = async (data) => {
    console.log(data, 'pl')
    const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
    const payload = new FormData()
    payload.append('campaignName', data?.title ?? '')
    payload.append('campaignDescription', data?.description ?? '')
    payload.append('channelName', data?.channel ?? null)
    payload.append('accountId', userDetails?.accountId ?? null)
    payload.append('userId', userDetails?.userId ?? null)
    payload.append('createdBy', userDetails?.emailId ?? null)
    payload.append('campaignType', data?.campaignType ?? null)
    payload.append('country', data?.country ?? null)
    payload.append('campaignPriority', data?.priority ?? null)
    payload.append('uploadFiles', data?.file ?? null)
    payload.append('billingCodeId', data?.billingCodeId ?? null)

    setIsLoading(true)
    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/campaign/create`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: payload,
      })

      if (res.status === 1) {
        setIsLoading(false)
        setCampaignId(res.campaignId)
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 1000,
          text: 'Campaign added Successfully',
        })
        setCurrentStep(2)
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'warning',
          button: false,
          showConfirmButton: false,
          timer: 3000,
          text: res.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const getCampaignDetails = async () => {
    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/get/campaign/${campaignId}`,
      })

      if (res.status === 1) {
        reset({
          title: res.campaignName,
          campaignType: res.campaignType,
          billingCodeId: res.billingCodeId,
          country: res.country,
          channel: res.channelName,
          priority: res.campaignPriority,
          description: res.campaignDescription,
        })
      }
    } catch (error) {
    } finally {
      //
    }
  }

  const getBillingCode = async () => {
    try {
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/billingcode/lov`,
      })
      console.log(res, 'lolo')
    } catch (error) {
    } finally {
      //
    }
  }

  const onSubmit = async (data) => {
    // await createCampaign1(data);

    if (Object.keys(dirtyFields).length > 0) {
      createCampaign(data)
    } else {
      setCurrentStep(2)
    }
  }

  useEffect(() => {
    fetchBillingCodes()
  }, [])

  useEffect(() => {
    if (campaignId !== -1) {
      getCampaignDetails()
    }
    // getBillingCode()
  }, [])

  return (
    <div>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label={'Creating Campaign....'}
          />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '23.44px',
            textAlign: 'left',
            paddingLeft: '12px',
          }}
        >
          Create Campaign
        </Typography>
        <Grid container spacing={2} sx={{ padding: '10px' }}>
          <Grid item xs={12} sm={6}>
            <Field label="Title of Campaign" titleVariant="h7" required>
              <CoTextField
                placeholder="Enter Campaign Title"
                name="title"
                control={control}
                disabled={disableCampaign}
              />
            </Field>
            {errors.title && (
              <span style={{ color: 'red' }}>{errors.title.message}</span>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field label="Select Billing code" titleVariant="h7" required />
            <FormControl fullWidth>
              <CoDropdown
                name="billingCodeId"
                control={control}
                options={billingCodeOpts}
                disabled={disableCampaign}
              />
              {errors.billingCodeId && (
                <span style={{ color: 'red' }}>
                  {errors.billingCodeId.message}
                </span>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field label="Campaign Type" titleVariant="h7" />
            <FormControl fullWidth>
              <CoDropdown
                name="campaignType"
                control={control}
                options={campaignType}
                disabled={disableCampaign}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field label="Country" titleVariant="h7" required />
            <FormControl fullWidth>
              <CoDropdown
                name="country"
                control={control}
                options={country}
                disabled={disableCampaign}
              />
              {errors.country && (
                <span style={{ color: 'red' }}>{errors.country.message}</span>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              label="Communication Channel"
              titleVariant="h7"
              required
              error={errors?.channel?.message}
              showError={errors?.channel?.message}
            >
              <FormControl fullWidth>
                <CoDropdown
                  name="channel"
                  control={control}
                  options={communicationChannel}
                  disabled={disableCampaign}
                />
              </FormControl>
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field label="Campaign Priority" titleVariant="h7" />
            <FormControl fullWidth>
              <CoDropdown
                name="priority"
                control={control}
                options={campaignPriority}
                placeholder="Select Priority"
                disabled={disableCampaign}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field label="Group" titleVariant="h7" />
            <FormControl fullWidth>
              <CoDropdown
                name="group"
                control={control}
                options={groupData}
                placeholder="Select Priority"
                disabled={disableCampaign}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Field label="Description" titleVariant="h7" />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  placeholder="Enter Description here"
                  disabled={disableCampaign}
                  style={{
                    resize: 'none',
                    width: '98%',
                    outline: '#fcfcfb',
                    border: '1px solid rgb(224, 224, 224)',
                    height: '19rem',
                    borderRadius: '4px',
                    fontfamily: '"Source Sans Pro", sans-serif !important',
                    fontSize: '1rem',
                    padding: '15px',
                  }}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6}>
            <Field label="File Upload" titleVariant="h7" />
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <FileUpload
                  onChange={(e) => {
                    field.onChange(e);
                    handleFileChange(e);
                  }}
                  selectedFile={field.value}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="contained" type="submit">
                Next
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaignDetail: state.createNewCampaign,
  billing: state.billing,
})

const mapDispatchToProps = (dispatch) => {
  return {
    createCampaign: (data) => dispatch(campaignActions.createCampaignNew(data)),
    setCampaignValues: (data) => {
      dispatch({ type: SET_CREATE_CAMPAIGN_VALUES, payload: data })
    },
    clearCampaignValues: () => {
      dispatch({ type: CLEAR_CREATE_CAMPAIGN_VALUES })
    },
    fetchBillingCodes: () => dispatch(getBillingCodeLov()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignNew)

CampaignNew.propTypes = {
  campaignDetail: PropTypes.object.isRequired,
  createCampaign: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  setCurrentStep: PropTypes.number,
  billing: PropTypes.arrayOf(PropTypes.array).isRequired,
  fetchBillingCodes: PropTypes.func.isRequired,
}
