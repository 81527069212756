import * as actionTypes from '../actions/actionTypes'

const initialState = {
  adhoc: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
  external: {
    data: [],
    count: '',
    isLoading: false,
    isError: false,
  },
}

const apiLogsReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.FETCH_ADHOC_LOGS:
      return { ...newState, adhoc: { ...newState.analytics, isLoading: true } }

    case actionTypes.FETCH_ADHOC_LOGS_SUCCESS:
      return {
        ...newState,
        adhoc: {
          data: [...action.payload.data],
          count: action.payload?.count,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_ADHOC_LOGS_FAILURE:
      return { ...newState, adhoc: { isLoading: false, isError: true } }

    case actionTypes.FETCH_EXTERNAL_LOGS:
      return {
        ...newState,
        external: { ...newState.analytics, isLoading: true },
      }

    case actionTypes.FETCH_EXTERNAL_LOGS_SUCCESS:
      return {
        ...newState,
        external: {
          data: [...action.payload.data],
          count: action.payload?.count,
          isLoading: false,
          isError: false,
        },
      }

    case actionTypes.FETCH_EXTERNAL_LOGS_FAILURE:
      return { ...newState, external: { isLoading: false, isError: true } }

    default:
      return { ...newState }
  }
}

export default apiLogsReducer
