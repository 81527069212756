import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import styles from './index.module.css';

const CountByStatus = ({ count, statusText, icon, color, percentage }) => {
  return (
    <Box
      style={{ color, '--data-percentage': `${percentage}%` }}
      className={styles.container}
    >
      <Stack direction="column" spacing={1}>
        <Stack
          direction={{ xs: 'row', sm: 'row', md: 'row' }}
          justifyContent="space-between"
          spacing={1}
        >
          <span className={styles.count}>{count}</span>
          <Box className={styles.iconWrapper}>{icon}</Box>
        </Stack>
        <span className={styles.status}>{statusText}</span>
      </Stack>
      <div data-percentage={`${percentage}%`} className={styles.progressBar}>
        <div className={styles.progress} />
      </div>
    </Box>
  );
};

CountByStatus.propTypes = {
  count: PropTypes.number,
  statusText: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.string,
  percentage: PropTypes.number,
};

CountByStatus.defaultProps = {
  count: 0,
  statusText: 'SMS Sent',
  icon: null,
  color: 'blue',
  percentage: 0,
};

export default CountByStatus;
