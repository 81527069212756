import React from 'react'
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LoopIcon from '@mui/icons-material/Loop'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import AssessmentIcon from '@mui/icons-material/Assessment'
import HandshakeIcon from '@mui/icons-material/Handshake'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SearchIcon from '@mui/icons-material/Search'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import DetailsIcon from '@mui/icons-material/Details'
import { map, noop } from 'lodash'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as BrandLogo } from '../assets/brand-logo.svg'
import ChatIcon from '@mui/icons-material/Chat'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { ReactComponent as Bell } from '../assets/bell.svg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EmailIcon from '@mui/icons-material/Email'
import LaunchIcon from '@mui/icons-material/Launch'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'

const navItems = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    to: '/dashboard',
  },
  {
    title: 'Campaigns',
    icon: CampaignOutlinedIcon,
    to: '/campaign',
  },
  {
    title: 'Templates',
    icon: BackupTableIcon,
    to: '/templates',
  },
  {
    title: 'TwoWay Text',
    icon: ChatIcon,
    to: '/chat',
  },
  {
    title: 'Instant Notifications',
    icon: LoopIcon,
    to: '/instant',
  },
  {
    title: 'Reports',
    icon: AssessmentIcon,
    to: '/reports',
  },
  {
    title: 'Error Log',
    icon: ErrorOutlineIcon,
    to: '/errorlog',
  },
  {
    title: 'Opt Out',
    icon: HandshakeIcon,
    to: '/optoutlist',
  },
  {
    title: 'User Management',
    icon: AccountCircleIcon,
    to: '/usermanagement',
  },
  {
    title: 'Group',
    icon: GroupsOutlinedIcon,
    to: '/group',
  },
  // {
  //   title: 'Email Configuration',
  //   // icon: AttachEmailIcon,
  //   to: '/emailconfiguration',
  // },
  {
    title: 'Audit Trail',
    icon: SearchIcon,
    to: '/audits',
  },
  {
    title: 'Email Configuration',
    icon: EmailIcon,
    to: '/emailconfiguration',
  },
  {
    title: 'Glossary',
    icon: DetailsIcon,
    to: '/glossary',
  },
  {
    title: 'Billing',
    icon: AttachMoneyIcon,
    to: '/billing',
  },
  {
    title: 'Inbound API Logs',
    icon: LaunchIcon,
    to: '/adhoclogs',
  },
  {
    title: 'Outbound API Logs',
    icon: DetailsIcon,
    to: '/externallogs',
  },
]

const LeftNav = ({ openNav, setOpenNav }) => {
  const location = useLocation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()

  const renderContent = () => {
    return (
      <Box sx={{ height: '100vh' }}>
        <Grid
        // sx={{
        //   display: {
        //     xs: 'none',
        //     sm: 'none',
        //     md: 'none',
        //     lg: 'flex',
        //   },
        //   flexDirection: 'column',
        // }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: '1rem',
              backgroundColor: '#05162E',
              position: 'sticky',
            }}
            onClick={() => {
              navigate('/dashboard')
            }}
          >
            <IconButton size="small">
              <Bell />
            </IconButton>
            <IconButton size="small">
              <BrandLogo />
            </IconButton>
          </Box>
          <Button
            sx={{
              backgroundColor: '#fff',
              color: '#6750A4',
              textTransform: 'capitalize',
              width: '90%',
              margin: '.8rem 0rem 0rem .8rem',
              '&:hover': {
                backgroundColor: '#ffffff !important',
              },
            }}
            startIcon={<ControlPointIcon />}
            onClick={() => {
              navigate('/campaigncreation')
            }}
          >
            Create Campaign
          </Button>
          <List>
            {map(navItems, ({ title, icon: Icon, to = '' }, index) => {
              const selected = to === location.pathname

              return (
                // <ListItemButton
                //   sx={{
                //     color: '#ffffff',
                //     '&.Mui-selected': {
                //       backgroundColor: '#FFFFFF',
                //       color: '#000000',
                //     },
                //     '&.Mui-selected:hover': {
                //       backgroundColor: '#FFFFFF',
                //     },
                //     '&:hover': {
                //       backgroundColor: 'rgba(255, 255, 255, 0.25)',
                //     },
                //   }}
                //   selected={index === 0}
                // >
                <NavLink to={to} style={{ textDecoration: 'none' }}>
                  <ListItem
                    sx={{
                      color: '#ffffff',
                      '&.Mui-selected': {
                        backgroundColor: '#FFFFFF',
                        color: '#000000',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#FFFFFF',
                      },
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.25)',
                      },
                    }}
                    selected={selected}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText>{title}</ListItemText>
                  </ListItem>
                </NavLink>
                // </ListItemButton>
              )
            })}
          </List>
        </Grid>

        {/* <Grid
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'none',
            },
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: '.5rem',
              backgroundColor: '#05162E',
              position: 'sticky',
            }}
            onClick={() => {
              navigate('/dashboard')
            }}
          >
            <IconButton
              sx={{
                width: '2rem', 
                height: '2rem',
                padding: '2px', 
              }}
            >
              <Bell />
            </IconButton>
           
          </Box>
          <Grid>
            <IconButton
              sx={{
                backgroundColor: '#fff',
                color: '#6750A4',
                textTransform: 'capitalize',
                // width: '90%',
                borderRadius: '5px',
                // margin: '.8rem 0rem 0rem .8rem',
                '&:hover': {
                  backgroundColor: '#ffffff !important',
                },
              }}
              onClick={() => {
                navigate('/campaigncreation')
              }}
            >
              <ControlPointIcon />
            </IconButton>
          </Grid>
          <Grid>
            <List>
              {map(navItems, ({ title, icon: Icon, to = '' }, index) => {
                const selected = to === location.pathname

                return (
                  // <ListItemButton
                  //   sx={{
                  //     color: '#ffffff',
                  //     '&.Mui-selected': {
                  //       backgroundColor: '#FFFFFF',
                  //       color: '#000000',
                  //     },
                  //     '&.Mui-selected:hover': {
                  //       backgroundColor: '#FFFFFF',
                  //     },
                  //     '&:hover': {
                  //       backgroundColor: 'rgba(255, 255, 255, 0.25)',
                  //     },
                  //   }}
                  //   selected={index === 0}
                  // >
                  <Tooltip title={title}>
                    <NavLink to={to} style={{ textDecoration: 'none' }}>
                      <ListItem
                        sx={{
                          color: '#ffffff',
                          '&.Mui-selected': {
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: '#FFFFFF',
                          },
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                          },
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          flexDirection: 'column',
                        }}
                        selected={selected}
                      >
                        <ListItemIcon
                          sx={{
                            color: 'inherit',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                          }}
                          primary={title.split(' ').map((word, index) => (
                            <span key={index}>
                              {word}
                              {index < title.split(' ').length - 1 && (
                                <br />
                              )}{' '}
                            </span>
                          ))}
                        />
                      </ListItem>
                    </NavLink>
                  </Tooltip>
                  // </ListItemButton>
                )
              })}
            </List>
          </Grid>
        </Grid> */}
      </Box>
    )
  }

  return (
    <Drawer
      open={matches ? openNav : true}
      onClose={() => setOpenNav(false)}
      variant={matches ? 'temporary' : 'persistent'}
      anchor="left"
      sx={{
        height: '100%',
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#052146',
          color: '#ffffff',
          position: 'static',
          width: 'max-content',
        },
      }}
    >
      {renderContent()}
    </Drawer>
  )
}

LeftNav.propTypes = {
  openNav: PropTypes.bool,
  setOpenNav: PropTypes.func,
}

LeftNav.defaultProps = {
  openNav: false,
  setOpenNav: noop,
}

export default LeftNav
