import * as actionTypes from '../actions/actionTypes'

const initialState = {
  campaignData: {
    campaignName: '',
    campaignDescription: '',
    channel: 'sms',
    uploadMode: 'web',
    scheduleMode: 'immediate',
    selectedPerson: '',
    isApprovalOveride: true,
  },
  createCampaignData: {},
  isError: false,
  isLoading: false,
}

const createCampaignReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.CREATE_CAMPAIGN:
      return { ...newState, isLoading: true }

    case actionTypes.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...newState,
        isLoading: false,
        createCampaignData: { ...action.payload },
      }

    case actionTypes.CREATE_CAMPAIGN_FAILURE:
      return {
        ...newState,
        isLoading: false,
        isError: true,
        createCampaignData: { ...action.payload },
      }

    case actionTypes.SET_CREATE_CAMPAIGN_VALUES:
      return {
        ...newState,
        campaignData: { ...newState.campaignData, ...action.payload },
      }

    case actionTypes.CLEAR_CREATE_CAMPAIGN_VALUES:
      return initialState

    default:
      return { ...newState }
  }
}

export default createCampaignReducer
