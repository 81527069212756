import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import * as templateActions from '../store/actions/template'
import * as settingsActions from '../store/actions/settings'
import * as Styled from '../components/styled-components/Campaign'
import RadioButtonGroup from '../components/RadioButtonGroup'
import EmailContentCreation from '../components/EmailContentCreation'
import SpinningLoader from '../components/SpinningLoader'

import appConfig from '../config/appConfig'

const ErrorStyle = {
  color: 'red',
  display: 'flex',
  marginTop: '5px',
}

const textboxCss = {
  border: `1px solid ${appConfig.colors.darkblue}`,
  borderRadius: '4px',
  outline: 'none',
  '&.MuiFormControl-root': {
    margin: '0',
  },
  '&.MuiTextField-root': {
    backgroundColor: '#fff',
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#fff',
    fontSize: 16,
    fontFamily: appConfig.fontfamily,
    padding: '10px 14px',
    borderRadius: '4px',
    outline: 'none',
  },
  '& .MuiOutlinedInput-root': {
    padding: '0',
  },
}

const CreateTemplate = ({
  createTemplate,
  templateData,
  getCampaignsListLov,
  fetchEmailConfiguration,
  settingsData,
}) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [channel, setChannel] = useState('SMS')
  const [isMassCampaign, setMassCampaign] = useState('No')
  const [selectedLanguage, setSelectedLanguage] = useState([
    {
      lan: 'en',
      name: 'English (en)',
      isEnabled: true,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
    {
      lan: 'pt',
      name: 'Portuguese (pt)',
      isEnabled: false,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
    {
      lan: 'vi',
      name: 'Vietnamese (vi)',
      isEnabled: false,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
    {
      lan: 'es',
      name: 'Spanish (es)',
      isEnabled: false,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
    {
      lan: 'zh',
      name: 'Chinese (zh)',
      isEnabled: false,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
    {
      lan: 'ht',
      name: 'Haitian Creole (ht)',
      isEnabled: false,
      text: '',
      subject: '',
      content: '',
      attachment: '',
    },
  ])
  const [isError, setError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [formValue, setFormValue] = useState(null)
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const isContentEnabled = true
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState([])
  const campaignList = templateData?.campaignsLov?.data || []
  const [campaignError, setCampaignError] = useState(false)

  const handleContentChange = (event) => {
    setError(false)
    setSelectedCampaign([])
    setIsEmpty(false)
    setChannel(event.target.value === 'Email' ? 'email' : 'sms')
    const newData = selectedLanguage.map((item) => {
      const data = { ...item }
      data.content = ''
      data.subject = ''
      data.text = ''
      return data
    })
    setSelectedLanguage(newData)
  }

  const dropDownlistCss = {
    mt: 1,
    mb: 1,
    borderRadius: '5px',
    border: `1px solid ${appConfig.colors.darkblue}`,
    background: '#fcfcfb',
    width: '100%',
    '.MuiAutocomplete-input': { height: '5px' },
  }

  useEffect(() => {
    getCampaignsListLov(channel)
  }, [channel])

  useEffect(() => {
    // resetTemplateConfiguration()
    selectedLanguage.forEach((la) => {
      if (!Object.keys(settingsData?.templateEmailSettings).includes(la.lan)) {
        fetchEmailConfiguration(la.lan)
      }
    })
  }, [])

  const updateIsEnabledChanged = (index, value) => {
    selectedLanguage.forEach((l, i) => {
      if (channel === 'email' && i === index && value) {
        if (!Object.keys(settingsData?.templateEmailSettings).includes(l.lan)) {
          fetchEmailConfiguration(l.lan)
        }
      }
    })
    setError(false)
    setIsEmpty(false)
    const data = [...selectedLanguage] // copying the old datas array
    data[index].isEnabled = value
    if (!data[index].isEnabled) {
      data[index].text = ''
      data[index].subject = ''
      data[index].content = ''
      data[index].attachment = ''
    }
    setSelectedLanguage(data)
  }

  useEffect(() => {
    setFormValue(null)
    if (isContentEnabled) {
      selectedLanguage.forEach((item) => {
        if (item.isEnabled) {
          let contentText = {
            content:
              channel.toLowerCase() === 'email' ||
              channel.toLowerCase() === 'sms'
                ? item?.content
                : item.text,
            language: item.lan,
          }
          if (channel.toLowerCase() === 'email') {
            contentText = {
              subject: item.subject ? item.subject : '',
              attachment: item.attachment ? item.attachment : '',
              content: item.content ? item.content : '',
              language: item.lan,
            }
          } else if (channel.toLowerCase() === 'sms') {
            contentText = {
              attachment: item.attachment ? item.attachment : '',
              content: item.content ? item.content : '',
              language: item.lan,
            }
          }

          setFormValue((prevState) => ({
            ...prevState,
            channel,
            isContentEnabled,
            contentJson: {
              ...prevState?.contentJson,
              [item.lan]: contentText,
            },
            languageEnabled: {
              ...prevState?.languageEnabled,
              [item.lan]: item.isEnabled,
            },
          }))
        }
        return formValue
      })
    } else {
      setFormValue((prevState) => ({
        ...prevState,
        channel,
        isContentEnabled,
        contentJson: {},
        languageEnabled: {},
      }))
    }
  }, [isContentEnabled, channel, selectedLanguage])

  useEffect(() => {
    if (isError) {
      setIsEmpty(false)
    }
    if (isEmpty) {
      setError(false)
    }
  }, [isEmpty, isError])

  const emailSubjectChange = (e, index) => {
    const data = [...selectedLanguage]
    data[index].subject = e.target.value
    setSelectedLanguage(data)
  }
  const emailContentChange = (e, index) => {
    if (channel.toLowerCase() === 'sms' && e.target.value === '') {
      setIsEmpty(true)
    }
    if (channel.toLowerCase() === 'email' && e.length <= 8) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }

    const data = [...selectedLanguage]
    if (channel.toLowerCase() === 'email') {
      data[index].content = e
    } else {
      data[index].content = e.target.value
    }
    setSelectedLanguage(data)
  }

  const sendFormValues = () => {
    if (formValue === null) {
      setError(true)
      setIsEmpty(false)
    } else {
      const empty = Object.values(formValue?.contentJson).some((item) => {
        if (channel.toLowerCase() === 'email') {
          return !item?.content
        }
        return false
      })
      if (empty) {
        setIsEmpty(true)
        setError(false)
      }
    }
  }

  const handleCampaignChange = (data) => {
    setSelectedCampaign(data)
    setCampaignError(false)
  }

  const onSubmit = (data) => {
    let formEmptyError = true
    let CampaignErr = false
    let templateDat
    const isSMS = channel.toLowerCase() === 'sms'
    const isYesMassCampaign = isMassCampaign === 'Yes'

    if (!selectedCampaign.id) {
      setCampaignError(true)
      CampaignErr = true
    } else {
      setCampaignError(false)
      CampaignErr = false
    }
    if (formValue?.contentJson) {
      templateDat = {
        name: data.name,
        code: data.code,
        channel: channel.toLowerCase() === 'sms' ? 'SMS' : 'Email',
        campaignId: selectedCampaign.id ? selectedCampaign.id : '',
        templateDetails: Object.entries(formValue?.contentJson).map((e) => {
          if (channel.toLowerCase() === 'email' && e[1].content.length <= 8) {
            formEmptyError = true
            setIsEmpty(true)
          } else if (
            channel.toLowerCase() === 'sms' &&
            e[1].content.length === 0
          ) {
            formEmptyError = true
            setIsEmpty(true)
          } else {
            formEmptyError = false
            setIsEmpty(false)
          }
          return e[1]
        }),
        isBulkCampaign: !isSMS ? isYesMassCampaign : false,
      }
    } else {
      setError(true)
    }

    if (formEmptyError) {
      setIsEmpty(true)
    } else if (!CampaignErr) {
      setIsFormSubmit(true)
      setIsLoading(true)
      createTemplate(templateDat)
    }
  }

  // const formatTemplate = (oneItem) => {
  //     const htmlStr = oneItem.replace(/<[^/>][^>]*><\/[^>]+>/gim, "");
  //     console.log("oneitem", htmlStr)
  //     if (channel.toLowerCase() === 'email') {
  //         const contentBlock = htmlToDraft(htmlStr.replace(/<[>]+>/g, ''));
  //         if (contentBlock) {
  //             const content = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //             const editor = EditorState.createWithContent(content);
  //             const data = { content: editor }
  //             return data;
  //         }
  //     }
  // }

  useEffect(() => {
    if (
      (templateData?.createTemplateData?.status === 1 ||
        templateData?.createTemplateData?.status === 0) &&
      isFormSubmit
    ) {
      setIsFormSubmit(false)
      setIsLoading(false)
      Swal.fire({
        icon:
          templateData?.createTemplateData?.status === 1
            ? 'success'
            : 'warning',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: templateData?.createTemplateData?.message,
      })
      if (templateData?.createTemplateData?.status === 1) {
        setTimeout(() => {
          navigate('../templates')
        }, 2000)
      }
    }
  }, [JSON.stringify(templateData?.createTemplateData)])

  return (
    <div>
      {isLoading || templateData?.campaignsLov?.isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || templateData?.campaignsLov?.isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label={
              templateData?.campaignsLov?.isLoading
                ? 'Campaigns list loading'
                : 'Creating template'
            }
          />
        </Backdrop>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
          <h1 className="page-heading">
            <b>Create Template</b>
          </h1>
        </Grid>
        <Grid item xs={6} xl={6} lg={6} md={6}>
          <div className="text-align-right">
            <Styled.CampaignButton
              className="text-trans-none"
              variant="contained"
              onClick={() => navigate('/templates')}
            >
              Templates
            </Styled.CampaignButton>
          </div>
        </Grid>
      </Grid>
      <hr className="blue-border" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={7} xl={7} lg={7} xs={12} sm={12}>
            <div>
              <Typography component="p" sx={{ mb: 1 }}>
                Template Name (Required)
              </Typography>
              <TextField
                {...register('name', { required: true, maxLength: 79 })}
                margin="normal"
                id="name"
                sx={textboxCss}
                fullWidth
                inputProps={{ 'aria-label': 'template name' }}
              />
            </div>
            {errors?.name?.type === 'required' && (
              <span style={ErrorStyle}>Please enter template name.</span>
            )}
            {errors?.name?.type === 'maxLength' && (
              <span style={ErrorStyle}>
                Template name cannot exceed 80 characters
              </span>
            )}
          </Grid>

          <Grid item md={7} xl={7} lg={7} sm={12}>
            <div>
              <Typography component="p" sx={{ mb: 1 }} className="inline-blk">
                Template Code (Required)
              </Typography>
              <Typography
                component="p"
                sx={{ mb: 1 }}
                className="inline-blk flt-r"
              >
                Must be 6 characters
              </Typography>
              <TextField
                {...register('code', {
                  required: true,
                  pattern: /^[A-Za-z0-9 ]+$/,
                  maxLength: 6,
                  minLength: 6,
                })}
                margin="normal"
                id="code"
                sx={textboxCss}
                fullWidth
                inputProps={{ 'aria-label': 'template code' }}
              />
            </div>
            {errors?.code?.type === 'required' && (
              <span style={ErrorStyle}>Please enter template code.</span>
            )}
            {errors?.code?.type === 'pattern' && (
              <span style={ErrorStyle}>Special characters not allowed.</span>
            )}
            {errors?.code?.type === 'maxLength' && (
              <span style={ErrorStyle}>Template code must be 6 characters</span>
            )}
            {errors?.code?.type === 'minLength' && (
              <span style={ErrorStyle}>Template code must be 6 characters</span>
            )}
          </Grid>
          <Grid item xl={7} md={7} lg={7} sm={12} xs={12}>
            <Typography component="p" sx={{ mt: 1, mb: 2 }}>
              Communication Channel
            </Typography>
            <RadioButtonGroup
              className={[
                'create-using-account-radio',
                channel === 'email' ? 'email' : 'sms',
              ].join(' ')}
              header=" "
              value={channel}
              labels={['SMS', 'Email']}
              handleChange={handleContentChange}
            />
          </Grid>
          <Grid item md={7} xl={7} lg={7} sm={12} xs={12}>
            <Typography component="p" sx={{ mt: 1, mb: 1 }}>
              Select Campaign (Required)
            </Typography>
            <FormControl sx={dropDownlistCss}>
              <Autocomplete
                disablePortal
                disableClearable
                className="autocomplete-class"
                disabled={templateData?.campaignsLov?.isLoading}
                value={selectedCampaign}
                options={
                  templateData?.campaignsLov?.isLoading
                    ? [{ id: '', label: 'Select Template' }]
                    : campaignList.map((item) => ({
                        label: item.value,
                        id: item.key,
                      }))
                }
                renderInput={(params) => {
                  const newParams = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      'aria-label': 'campaign',
                    },
                  }
                  return <TextField {...newParams} />
                }}
                onChange={(e, val) => {
                  handleCampaignChange(val)
                }}
              />
            </FormControl>
            {campaignError && (
              <span style={ErrorStyle}>Please select a campaign. </span>
            )}
          </Grid>
          <Grid item xl={7} md={7} lg={7} sm={12} xs={12}>
            <Typography component="p" sx={{ mt: 1, mb: 2 }}>
              Is this template intended for mass campaign?
            </Typography>
            <RadioButtonGroup
              className={[
                'create-using-account-radio',
                channel.toLowerCase() === 'sms' && 'disable',
                isMassCampaign,
              ].join(' ')}
              header=" "
              value={isMassCampaign}
              // disabled={channel === 'sms'}
              labels={['Yes', 'No']}
              handleChange={
                channel === 'email'
                  ? (val) => {
                      setMassCampaign(val.target.value)
                    }
                  : null
              }
            />
          </Grid>
          <Grid item md={8} xl={8} lg={8} sm={12} xs={12}>
            <Typography component="p" style={{ marginBottom: '10px' }}>
              Choose Language
            </Typography>
            {selectedLanguage.map((item, index) => {
              return (
                <FormControlLabel
                  key={item.lan}
                  sx={
                    item.isEnabled
                      ? appConfig.checkboxStyleChecked
                      : appConfig.checkboxStyle
                  }
                  control={
                    <Checkbox
                      {...register('language', { required: true })}
                      sx={
                        item.isEnabled
                          ? { color: appConfig.colors.white }
                          : { color: appConfig.colors.darkblue }
                      }
                      key={item.lan}
                      checked={item.isEnabled}
                      disabled={!isContentEnabled}
                      onChange={(e) => {
                        updateIsEnabledChanged(
                          index,
                          e.target.checked,
                          e.target
                        )
                      }}
                    />
                  }
                  label={item.name}
                />
              )
            })}
            {isError ? (
              <span style={ErrorStyle}>Please choose a language.</span>
            ) : null}
            {isEmpty ? (
              <span style={ErrorStyle}>
                Content shoud not be empty for selected language.
              </span>
            ) : null}
            <br />
            <br />
          </Grid>
          <Grid container xs={12} md={12} lg={12} sm={12} xl={12}>
            {selectedLanguage.map((item, index) => {
              return (
                item.isEnabled && (
                  <Grid
                    item
                    xs={12}
                    md={7.3}
                    lg={7.3}
                    sm={12}
                    xl={7.3}
                    sx={{ paddingLeft: '15px' }}
                  >
                    <EmailContentCreation
                      isEnableSubject={channel.toLowerCase() === 'email'}
                      fileFormat={
                        channel.toLowerCase() === 'sms'
                          ? ['.jpeg', '.jpg', '.png', '.pdf']
                          : '*'
                      }
                      emailContent={item}
                      disabled={!isContentEnabled}
                      // handleFiles={handleFiles}
                      emailSubjectChange={emailSubjectChange}
                      emailContentChange={emailContentChange}
                      index={index}
                      isAttachment={false}
                      previewChildren={
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                settingsData.templateEmailSettings[item.lan]
                                  ?.header,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                item.content === ''
                                  ? '<h3><b>< Enter your template content here ></b></h3>'
                                  : item.content,
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                settingsData.templateEmailSettings[item.lan]
                                  ?.footer,
                            }}
                          />
                        </div>
                      }
                    />
                  </Grid>
                )
              )
            })}
          </Grid>

          <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
            <hr className="blue-border" />
          </Grid>
          <Grid container spacing={1} sx={{ marginLeft: '10px' }}>
            <Grid item xs={12} sm={4} xl={1.5} lg={1.5} md={1.5}>
              <Styled.CancelButton
                variant="outlined"
                sx={{
                  mt: 3,
                  fontFamily: appConfig.fontfamily,
                  textTransform: 'capitalize',
                  width: '100%',
                  height: '50px',
                }}
                onClick={() => navigate('/templates')}
              >
                Cancel
              </Styled.CancelButton>
            </Grid>
            <Grid item xs={12} sm={8} xl={5.6} lg={5.6} md={5.6}>
              <Styled.CampaignButton
                onClick={() => sendFormValues()}
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  fontFamily: appConfig.fontfamily,
                  textTransform: 'capitalize',
                  width: '100%',
                  height: '50px',
                }}
              >
                Create
              </Styled.CampaignButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

CreateTemplate.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  templateData: PropTypes.symbol.isRequired,
  getCampaignsListLov: PropTypes.func.isRequired,
  fetchEmailConfiguration: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  // resetTemplateConfiguration: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
  templateData: state.template,
  settingsData: state.settings,
})

const mapDispatchToProps = (dispatch) => {
  return {
    createTemplate: (fields) =>
      dispatch(templateActions.createTemplate(fields)),
    getCampaignsListLov: (fields) =>
      dispatch(templateActions.getCampaignsListLov(fields)),
    fetchEmailConfiguration: (lang) =>
      dispatch(settingsActions.fetchEmailConfigurationForTemplate(lang)),
    // resetTemplateConfiguration: ()=>dispatch(settingsActions.resetEmailConfiguration())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate)
