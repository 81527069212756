import { saveAs } from 'file-saver'
import { Parser } from 'json2csv'

import * as optoutConstants from './actionTypes'
import { config } from '../../config/config'
import request from '../../components/Helper/Request'
import { CalculateUTCTzToUserTz } from '../../components/Utils'

const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

export function triggerOptOut(email, agency) {
  return (dispatch) => {
    dispatch({
      type: optoutConstants.TRIGGER_OPT_OUT,
      payload: { email, agency },
    })

    const data = { email, agency }
    request({
      method: 'post',
      url: `${config.API_URL}/api/optout/email`,
      data,
    })
      .then((response) => {
        dispatch({
          type: optoutConstants.TRIGGER_OPT_OUT_SUCCESS,
          payload: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: optoutConstants.TRIGGER_OPT_OUT_FAILURE,
          payload: error,
        })
      })
  }
}

export function fetchOptOutList(data, search, limit, page) {
  return (dispatch) => {
    dispatch({
      type: optoutConstants.FETCH_OPT_OUT_LIST,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/optout/list/${userDetails?.accountId}/${userDetails?.userId}`,
      params: {
        filterBy: data.filterBy,
        startDate: data.startDate,
        endDate: data.endDate,
        search,
        limit,
        page,
      },
    })
      .then((response) => {
        dispatch({
          type: optoutConstants.FETCH_OPT_OUT_LIST_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: optoutConstants.FETCH_OPT_OUT_LIST_FAILURE,
          payload: error,
        })
      })
  }
}

export function exportOptoutData(data, search) {
  return (dispatch) => {
    dispatch({
      type: optoutConstants.EXPORT_OPT_OUT_LIST,
    })

    request({
      method: 'get',
      url: `${config.API_URL}/api/optout/export/${userDetails?.accountId}/${userDetails?.userId}/`,
      params: {
        filterBy: data.filterBy,
        startDate: data.startDate,
        endDate: data.endDate,
        search,
      },
    })
      .then((response) => {
        const json2csvParser = new Parser()
        const currentChannel = data.filterBy.toLowerCase()
        const currentData = response.data[currentChannel]
        const csv = json2csvParser.parse(
          currentData.map((item) => {
            const csvdata = {}
            if (data.filterBy.toLowerCase() === 'email') {
              csvdata.Email = item.email
            } else {
              csvdata['Mobile Number'] = item.mobileNo
            }
            csvdata['Received Time'] = CalculateUTCTzToUserTz(item.date)
            return csvdata
          })
        )
        const blob = new Blob([csv], { type: 'text/csv' })
        const fileName = `${
          CalculateUTCTzToUserTz(new Date()).split(' ')[0]
        }_optout_report`
        saveAs(blob, fileName)
      })
      .catch((error) => {
        dispatch({
          type: optoutConstants.EXPORT_OPT_OUT_LIST_FAILURE,
          payload: error,
        })
      })
  }
}
