import React, { useEffect, useState, useRef } from 'react'
import { Grid, TextField, Autocomplete } from '@mui/material'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment'

import TableCell from '@mui/material/TableCell'
import { MultiSelect } from 'react-multi-select-component'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import FormControl from '@mui/material/FormControl'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

import IconButton from '@mui/material/IconButton'
import appConfig from '../config/appConfig'

import * as Styled from '../components/styled-components/Campaign'
import DefaultTable from '../components/Table'
import SkeletonGridLoader from '../components/SkeletonGridLoader'

import * as analyticsActions from '../store/actions/analytics'
import * as menuActions from '../store/actions/breadcrumb'

import SpinningLoader from '../components/SpinningLoader'
import DateRangePickerComponent from '../components/DateRangePicker/DateRangePickerComponent'

import {
  CalculateUTCTzToUserTz,
  CalculateUserTzToUTCTz,
  splitDate,
  splitTime,
  formatDTADate,
} from '../components/Utils'

const dropDownlistCss = {
  mt: 1,
  mb: 1,
  borderRadius: '5px',
  border: `1px solid ${appConfig.colors.darkblue}`,
  background: '#fcfcfb',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px' },
}
const datePropDownCss = {
  mt: 1,
  mb: 1,
  borderRadius: '5px',
  background: '#fcfcfb',
  width: '100%',
  '.MuiAutocomplete-input': { height: '5px', outline: 'none' },
}

const gridItemCss = { '&.MuiGrid-item': { pt: 0, pl: 2, pr: 2 } }

const mb = {
  marginBottom: 0,
  fontWeight: 'bold',
  color: appConfig.colors.darkgray,
}

const Analytics = ({
  analyticsData,
  fetchAnalyticsData,
  fetchFiltersData,
  exportAnalyticsData,
  setBreadcrumb,
}) => {
  const { handleSubmit } = useForm()
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  })

  const filterInitialState = {
    channels: { key: 'SMS', value: 'SMS' },
    files: [],
    campaigns: [],
    languages: [],
    templates: [],
    statuses: [],
    startDate: CalculateUserTzToUTCTz(
      selectedDate?.startDate?.toISOString() ||
        startOfDay(new Date()).toISOString()
    ),
    endDate: CalculateUserTzToUTCTz(
      selectedDate?.endDate?.toISOString() || endOfDay(new Date()).toISOString()
    ),
  }
  const [filterData, setFilterData] = useState(filterInitialState)

  const [pagination, setPagination] = useState({ limit: 10, offset: 1 })
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [enableRowDetails, setEnableRowDetails] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const [selected, setSelected] = useState(null)
  const [searchText, setSearchText] = useState('')
  const isMounted = useRef(false)
  const [sortValue, setSortValue] = useState({
    sortBy: 'broadcastTime',
    sortOrder: 'desc',
  })

  const ChannelList = [
    { key: 'SMS', value: 'SMS' },
    { key: 'Email', value: 'Email' },
    // { key: "Voice", value: "Voice" }
  ]

  const TemplateCodes = [
    ...(analyticsData?.filters?.data?.templateCode || 'All'),
  ]

  // const statusIds = [
  //     ...analyticsData?.filters?.data?.status || []
  // ];
  const smsStatusIds = [
    { key: '402', value: 'Pending' },
    { key: '403', value: 'Queued' },
    { key: '404', value: 'Sent' },
    { key: '405', value: 'Delivered' },
    { key: '406', value: 'Undelivered' },
    { key: '407', value: 'Failed' },
    { key: '409', value: 'Cancelled' },
  ]
  const emailStatusIds = [
    { key: '602', value: 'Pending' },
    { key: '603', value: 'Sent' },
    { key: '604', value: 'Failed' },
    { key: '605', value: 'Delivered' },
    { key: '606', value: 'Undelivered' },
    { key: '607', value: 'Cancelled' },
  ]
  const statusIds =
    filterData?.channels?.value === 'SMS' ? smsStatusIds : emailStatusIds || []

  const fileList = [...(analyticsData?.filters?.data?.files || [])]

  const campaignList = [...(analyticsData?.filters?.data?.campaign || [])]

  const languages = [
    { key: 'en', value: 'English (en)' },
    { key: 'es', value: 'Spanish (es)' },
    { key: 'pt', value: 'Portuguese (pt)' },
    { key: 'vi', value: 'Vietnamese (vi)' },
    { key: 'zh', value: 'Chinese (zh)' },
    { key: 'ht', value: 'Haitian Creole (ht)' },
  ]

  let languageCodes = []
  languageCodes = [...languages]

  const handleSearch = (search) => {
    isMounted.current = false
    setSearchText(search)
  }

  const handleFilter = (data, input) => {
    switch (input) {
      case 'file':
        setFilterData({
          ...filterData,
          files: data,
        })
        break
      case 'campaign':
        setFilterData({
          ...filterData,
          templates: [],
          statuses: [],
          languages: [],
          files: [],
          campaigns: [{ key: data.label, value: data.value }],
        })
        break
      case 'language':
        setFilterData({
          ...filterData,
          languages: data,
        })
        break
      case 'status':
        setFilterData({
          ...filterData,
          statuses: data,
        })
        break
      case 'template':
        setFilterData({
          ...filterData,
          templates: data,
        })
        break
      case 'channel':
        setFilterData({
          ...filterData,
          channels: { key: data.label, value: data.id },
          templates: [],
          statuses: [],
          languages: [],
          files: [],
          campaigns: [],
        })
        setPagination({ limit: 10, offset: 1 })
        break

      case 'date':
        setFilterData({
          ...filterData,
          endDate: CalculateUserTzToUTCTz(
            data?.[0]?.endDate
              ? endOfDay(data?.[0]?.endDate)
              : endOfDay(data?.endDate)
          ),
          startDate: CalculateUserTzToUTCTz(
            data?.[0]?.startDate || startOfDay(data?.startDate)
          ),
          templates: [],
          statuses: [],
          languages: [],
          files: [],
          campaigns: [],
        })
        break

      default:
        setFilterData({
          ...filterData,
        })
    }
  }

  const renderGrid = (label, value) => {
    return (
      <div className="data-grid">
        <span className="inline-blk mar-r-10">
          <b>{label}:</b>
        </span>
        <span>{value}</span>
      </div>
    )
  }

  const filterValues = {
    filterBy: filterData.channels.value,
    fileId:
      filterData.files.map((f) => f.value).length > 0
        ? filterData.files.map((f) => f.value)
        : '',
    campaignId:
      filterData.campaigns.map((f) => f.value).length > 0
        ? filterData.campaigns.map((f) => f.value)
        : '',
    templateCode:
      filterData.templates.map((t) => t.value).length > 0
        ? filterData.templates.map((t) => t.value)
        : '',
    language:
      filterData.languages.map((l) => l.value).length > 0
        ? filterData.languages.map((l) => l.value)
        : '',
    statusId:
      filterData.statuses.map((s) => s.value).length > 0
        ? filterData.statuses.map((s) => s.value)
        : '',
    startDate: filterData.startDate.toISOString(),
    endDate: filterData.endDate.toISOString(),
    sortBy: sortValue.sortBy,
    sortOrder: sortValue.sortOrder,
  }

  const handleExport = () => {
    exportAnalyticsData(filterValues)
  }

  useEffect(() => {
    setBreadcrumb('Reports')
    setFilterData(filterInitialState)
  }, [])

  useEffect(() => {
    isMounted.current = true
    fetchFiltersData(filterValues)
    fetchAnalyticsData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }, [
    filterData.channels,
    filterData.startDate,
    filterData.endDate,
    filterData.campaigns,
  ])

  useEffect(() => {
    fetchAnalyticsData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }, [JSON.stringify(pagination), JSON.stringify(sortValue)])

  useEffect(() => {
    if (!isMounted.current) {
      setPagination({ limit: pagination.limit, offset: 1 })
      fetchAnalyticsData(pagination.limit, 1, filterValues, searchText)
    }
  }, [searchText])

  const onSubmit = () => {
    fetchAnalyticsData(pagination.limit, pagination.offset, filterValues)
  }

  const renderCells = (row) => {
    return (
      <>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.fileSid}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.broadcastTime)))}{' '}
          {splitTime(CalculateUTCTzToUserTz(row.broadcastTime))}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.templateCode}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {filterData.channels.key === 'Email' ? row.emailId : row.mobileNo}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {formatDTADate(splitDate(CalculateUTCTzToUserTz(row.fileSentDate)))}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.language}
        </TableCell>
        <TableCell
          sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}
        >
          {row.statusDetails.status}
        </TableCell>
      </>
    )
  }

  const onClear = () => {
    const filterInitValues = {
      filterBy: filterInitialState.channels.value,
      fileId: '',
      campaignId: '',
      templateCode: '',
      language: '',
      statusId: '',
      startDate: CalculateUserTzToUTCTz(startOfDay(new Date())).toISOString(),
      endDate: CalculateUserTzToUTCTz(endOfDay(new Date())).toISOString(),
      sortBy: sortValue.sortBy,
      sortOrder: sortValue.sortOrder,
    }
    setFilterData(filterInitialState)
    setSelectedDate({
      startDate: CalculateUserTzToUTCTz(startOfDay(new Date())),
      endDate: CalculateUserTzToUTCTz(endOfDay(new Date())),
    })
    fetchFiltersData(filterInitValues)
    setSelected(null)
    fetchAnalyticsData(
      pagination.limit,
      pagination.offset,
      filterInitValues,
      searchText
    )
  }

  const onRefresh = () => {
    setPagination({ offset: 1, limit: 10 })
    setSearchText('')
    fetchAnalyticsData(
      pagination.limit,
      pagination.offset,
      filterValues,
      searchText
    )
  }

  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const renderDetailsButton = (row) => {
    return (
      <div>
        <IconButton
          style={{ padding: 0 }}
          onClick={() => {
            setSelectedMessage(row)
            setEnableRowDetails(messageId !== row.id ? true : !enableRowDetails)
            setMessageId(row.id)
            setSelected(row.id)
          }}
          title="View Details"
          aria-label="delete"
          size="small"
        >
          {messageId === row.id && enableRowDetails ? (
            <KeyboardArrowUpOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                border: `1px solid ${appConfig.colors.darkblue}`,
                fontSize: 30,
                borderRadius: '50%',
                color: appConfig.colors.darkblue,
              }}
              fontSize="inherit"
            />
          )}
        </IconButton>
      </div>
    )
  }

  const loadRowDetails = () => {
    return (
      <Grid
        container
        spacing={2}
        padding={1}
        sx={{ height: '300px', overflow: 'auto' }}
      >
        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
          {renderGrid('Status', selectedMessage.statusDetails?.status || '-')}
          {renderGrid(
            'File Name',
            selectedMessage?.fileDetails?.originalFileName || '-'
          )}
          {renderGrid(
            'Campaign Name',
            selectedMessage?.campaignDetails?.campaignName || '-'
          )}
          {filterData.channels.key === 'Email' ? (
            <div className="data-grid">
              <span className="inline-blk mar-r-10">
                <b>Message Content:</b>
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedMessage?.messageContent,
                }}
              />
            </div>
          ) : (
            renderGrid(
              'Message Content',
              selectedMessage?.messageContent || '-'
            )
          )}
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Created Time:</b>
            </span>
            <span>
              {formatDTADate(
                splitDate(CalculateUTCTzToUserTz(selectedMessage?.createdTime))
              )}{' '}
              {splitTime(CalculateUTCTzToUserTz(selectedMessage?.createdTime))}
            </span>
          </div>
        </Grid>

        <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Queued Time:</b>
            </span>
            {filterData.channels.key === 'Email' ? (
              <span>
                {selectedMessage?.sentTime
                  ? formatDTADate(
                      splitDate(
                        CalculateUTCTzToUserTz(selectedMessage?.sentTime)
                      )
                    )
                  : '-'}{' '}
                {selectedMessage?.sentTime
                  ? splitTime(CalculateUTCTzToUserTz(selectedMessage?.sentTime))
                  : '-'}
              </span>
            ) : (
              <span>
                {selectedMessage?.queuedTime
                  ? formatDTADate(
                      splitDate(
                        CalculateUTCTzToUserTz(selectedMessage?.queuedTime)
                      )
                    )
                  : '-'}{' '}
                {selectedMessage?.queuedTime
                  ? splitTime(
                      CalculateUTCTzToUserTz(selectedMessage?.queuedTime)
                    )
                  : '-'}
              </span>
            )}
          </div>
          {selectedMessage?.deliveredTime ? (
            <div className="data-grid">
              <span className="inline-blk mar-r-10">
                <b>Delivered Time:</b>
              </span>
              <span>
                {selectedMessage?.deliveredTime
                  ? formatDTADate(
                      splitDate(
                        CalculateUTCTzToUserTz(selectedMessage?.deliveredTime)
                      )
                    )
                  : '-'}{' '}
                {selectedMessage?.deliveredTime
                  ? splitTime(
                      CalculateUTCTzToUserTz(selectedMessage?.deliveredTime)
                    )
                  : '-'}
              </span>
            </div>
          ) : null}
          {selectedMessage?.notificationErrorMessage ? (
            <div className="data-grid">
              <span className="inline-blk mar-r-10">
                <b>Failure Reason:</b>
              </span>
              <span style={{ width: '50%', wordWrap: 'break-word' }}>
                {selectedMessage?.notificationErrorMessage}{' '}
              </span>
            </div>
          ) : null}
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Failed Time:</b>
            </span>
            <span>
              {selectedMessage?.failedTime
                ? formatDTADate(
                    splitDate(
                      CalculateUTCTzToUserTz(selectedMessage?.failedTime)
                    )
                  )
                : '-'}{' '}
              {selectedMessage?.failedTime
                ? splitTime(CalculateUTCTzToUserTz(selectedMessage?.failedTime))
                : '-'}
            </span>
          </div>
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Cancelled Time:</b>
            </span>
            <span>
              {selectedMessage?.fileDetails?.cancelledTime
                ? formatDTADate(
                    splitDate(
                      CalculateUTCTzToUserTz(
                        selectedMessage?.fileDetails?.cancelledTime
                      )
                    )
                  )
                : '-'}{' '}
              {selectedMessage?.fileDetails?.cancelledTime
                ? splitTime(
                    CalculateUTCTzToUserTz(
                      selectedMessage?.fileDetails?.cancelledTime
                    )
                  )
                : '-'}
            </span>
          </div>
          <div className="data-grid">
            <span className="inline-blk mar-r-10">
              <b>Undelivered Time:</b>
            </span>
            <span>
              {selectedMessage?.undeliveredTime
                ? formatDTADate(
                    splitDate(
                      CalculateUTCTzToUserTz(selectedMessage?.undeliveredTime)
                    )
                  )
                : '-'}{' '}
              {selectedMessage?.undeliveredTime
                ? splitTime(
                    CalculateUTCTzToUserTz(selectedMessage?.undeliveredTime)
                  )
                : '-'}
            </span>
          </div>
        </Grid>
      </Grid>
    )
  }

  const handleSort = (value) => {
    setSortValue(value)
  }

  return (
    <div>
      <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
        <h1 className="page-heading">
          <b>Reports</b>
        </h1>
      </Grid>
      <hr style={{ border: '3px solid #EDF6F5', margin: '20px 0' }} />

      {analyticsData?.filters?.isLoading ? (
        <SpinningLoader
          label="Loading Filters..."
          circleColor={appConfig.colors.darkblue}
        />
      ) : (
        <div className="filter-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Communication Channel</p>
                <FormControl sx={dropDownlistCss}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    openOnFocus
                    clearOnBlur
                    className="autocomplete-class"
                    defaultValue={
                      filterData.channels.key
                        ? filterData.channels.key
                        : 'Select Channel'
                    }
                    options={ChannelList.map((item) => ({
                      label: item.value,
                      id: item.key,
                    }))}
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          'aria-label': 'channel',
                        },
                      }
                      return <TextField {...newParams} />
                    }}
                    onChange={(e, val) => {
                      handleFilter(val, 'channel')
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Date</p>
                <FormControl sx={datePropDownCss}>
                  <DateRangePickerComponent
                    label={`${moment(
                      CalculateUTCTzToUserTz(filterData.startDate)
                    ).format('MM-DD-YYYY')} - ${moment(
                      CalculateUTCTzToUserTz(filterData.endDate)
                    ).format('MM-DD-YYYY')}`}
                    cssStyle={{
                      color: '#111',
                      fontSize: '15px',
                      border: '1px solid #bbb',
                      justifyContent: 'flex-start',
                    }}
                    selectedDateObj={(date) => {
                      handleFilter(date, 'date')
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Campaign</p>
                <FormControl sx={dropDownlistCss}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    openOnFocus
                    clearOnBlur
                    defaultValue={
                      filterData.campaigns[0]?.key
                        ? filterData.campaigns[0]?.key
                        : 'Select Campaign'
                    }
                    className="autocomplete-class"
                    options={campaignList.map((item) => ({
                      label: item.value,
                      value: item.key,
                    }))}
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          'aria-label': 'campaigns',
                        },
                      }
                      return <TextField {...newParams} />
                    }}
                    onChange={(e, data) => handleFilter(data, 'campaign')}
                  />

                  {/* <MultiSelect
                                    ArrowRenderer={ArrowDropDownIcon}
                                    options={campaignList.map((item) => ({ label: item.value, value: item.key }))}
                                    value={filterData.campaigns}
                                    className="multi-select"
                                    onChange={(data) => handleFilter(data, "campaign")}
                                    label="Select File"
                                    ClearSelectedIcon={null}

                                /> */}
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By File</p>
                <FormControl sx={dropDownlistCss}>
                  <MultiSelect
                    ArrowRenderer={ArrowDropDownIcon}
                    options={fileList.map((item) => ({
                      label: item.value,
                      value: item.key,
                    }))}
                    value={filterData.files}
                    className="multi-select"
                    onChange={(data) => handleFilter(data, 'file')}
                    label="Select File"
                    ClearSelectedIcon={null}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Template</p>
                <FormControl sx={dropDownlistCss}>
                  <MultiSelect
                    ArrowRenderer={ArrowDropDownIcon}
                    options={TemplateCodes.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={filterData.templates}
                    className="multi-select"
                    onChange={(data) => handleFilter(data, 'template')}
                    label="Select Template"
                    ClearSelectedIcon={null}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Language</p>
                <FormControl sx={dropDownlistCss}>
                  <MultiSelect
                    ArrowRenderer={ArrowDropDownIcon}
                    options={languageCodes.map((item) => ({
                      label: item.value,
                      value: item.key,
                    }))}
                    value={filterData.languages}
                    className="multi-select"
                    onChange={(data) => handleFilter(data, 'language')}
                    label="Select Language"
                    ClearSelectedIcon={null}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={3} md={4} xl={3} sx={gridItemCss}>
                <p style={mb}>By Status</p>
                <FormControl sx={dropDownlistCss}>
                  <MultiSelect
                    ArrowRenderer={ArrowDropDownIcon}
                    className="multi-select"
                    options={statusIds.map((item) => ({
                      label: item.value,
                      value: item.key,
                    }))}
                    value={filterData.statuses}
                    onChange={(data) => handleFilter(data, 'status')}
                    label="Select Status"
                    ClearSelectedIcon={null}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={1} md={1} xl={1} sx={gridItemCss}>
                <FormControl sx={dropDownlistCss} className="filter-btn">
                  <Styled.CampaignButton type="submit">
                    Apply
                  </Styled.CampaignButton>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={1} md={1} xl={1} sx={gridItemCss}>
                <FormControl sx={dropDownlistCss} className="filter-btn">
                  <Styled.CancelButton
                    sx={{ padding: '5px' }}
                    onClick={() => {
                      onClear()
                    }}
                  >
                    Clear
                  </Styled.CancelButton>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          {analyticsData?.analytics?.isLoading ||
          analyticsData?.filters?.isLoading ? (
            <SkeletonGridLoader />
          ) : (
            <DefaultTable
              title="Total Records"
              handlePagination={handlePagination}
              hasDetails
              renderDetailsButton={renderDetailsButton}
              // headers={['Message ID', 'Broadcast Date', 'Broadcast Time', 'Template Code', filterData.channels.key === "Email" ? 'Email' : 'Mobile', 'File Sent Date', 'Language', 'Status']}
              headers={[
                { name: 'Message ID', sortEnabled: false },
                {
                  name: 'Broadcast Date & Time',
                  sortEnabled: true,
                  sortBy: 'broadcastTime',
                },
                { name: 'Template Code', sortEnabled: false },
                {
                  name:
                    filterData.channels.key === 'Email' ? 'Email' : 'Mobile',
                  sortEnabled: false,
                },
                { name: 'File Sent Date', sortEnabled: false },
                { name: 'Language', sortEnabled: false },
                { name: 'Status', sortEnabled: false },
              ]}
              data={analyticsData?.analytics?.data}
              totalCount={analyticsData?.analytics?.count}
              currentPage={pagination.offset}
              currentLimit={pagination.limit}
              selected={selected}
              loadRowDetails={enableRowDetails ? loadRowDetails() : null}
              enableRowDetails={enableRowDetails}
              isSearchEnabled
              searchCallback={(text) => handleSearch(text)}
              searchKey={searchText}
              searchPlaceholderText={
                filterData.channels.key === 'Email'
                  ? 'Search by Email/message ID'
                  : 'Search by mobile/message ID'
              }
              renderCustomCells
              renderCells={renderCells}
              isExportEnabled
              exportCallback={handleExport}
              isRefreshEnabled
              refreshCallback={() => onRefresh()}
              isSortEnabled
              handleSort={handleSort}
              sortValue={sortValue}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({ analyticsData: state.analytics })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAnalyticsData: (limit, page, data, searchText) =>
      dispatch(
        analyticsActions.getAnalyticsData(limit, page, data, searchText)
      ),
    fetchFiltersData: (data) => dispatch(analyticsActions.getFiltersData(data)),
    exportAnalyticsData: (data, searchText) =>
      dispatch(analyticsActions.exportAnalyticsData(data, searchText)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)

Analytics.propTypes = {
  fetchAnalyticsData: PropTypes.func.isRequired,
  fetchFiltersData: PropTypes.func.isRequired,
  exportAnalyticsData: PropTypes.func.isRequired,
  analyticsData: PropTypes.objectOf(PropTypes.object).isRequired,
  setBreadcrumb: PropTypes.func.isRequired,
}
