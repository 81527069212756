import * as actionTypes from '../actions/actionTypes'

const initialState = {
  currentMenu: 'Dashboard',
  isSubMenu: false,
  subMenu: '',
  pageData: {},
}

const breadcrumbReducer = (state = initialState, action = {}) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.MENU_CHANGE:
      return { ...newState, currentMenu: action.payload }

    case actionTypes.SUB_MENU_CHANGE:
      return { ...newState, isSubMenu: true, subMenu: action.payload }

    default:
      return { ...newState }
  }
}

export default breadcrumbReducer
