import {
  Avatar,
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import appConfig from '../../../config/appConfig'
import { useForm } from 'react-hook-form'
import { includes, map } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import * as Styled from '../../../components/styled-components/Campaign'
import RemoveIcon from '@mui/icons-material/Remove'
import Field from '../../../components/Field'
import CoTextField from '../../../controllers/CoTextField'
import request from '../../../components/Helper/Request'
import { config } from '../../../config/config'
import SpinningLoader from '../../../components/SpinningLoader'
import Swal from 'sweetalert2'

const textboxCss = {
  margin: '8px 0',
  '& .MuiInputBase-input': {
    backgroundColor: '#fcfcfb',
    fontSize: 16,
    fontFamily: appConfig.fontfamily,
    padding: '10px 14px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#e9f2fa',
  },
}

const CreateGroup = ({
  action,
  data,
  handleCloseGroup,
  openGroup,
  getUserDetails,
  userColumns,
  getGroupDetails,
}) => {
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [removedUsers, setRemovedUsers] = useState([])

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      groupName: action === 'Edit' ? data.name : '',
      search: '',
      // emailId: '',
      // phoneNumber: '',
    },
  })

  const stringAvatar = (name) => {
    const nameParts = name.split(' ')
    const firstLetter = nameParts[0] ? nameParts[0][0] : ''
    const secondLetter = nameParts[1] ? nameParts[1][0] : ''

    return {
      children: `${firstLetter.charAt(0).toUpperCase()}${secondLetter
        .charAt(0)
        .toUpperCase()}`,
    }
  }

  useEffect(() => {
    if (data && action === 'Edit') {
      setValue('groupName', data.name)
    }
  }, [data, action, setValue])

  const addUsers = (id) => {
    if (userList.includes(id)) {
      setUserList((prevList) => prevList.filter((userId) => userId !== id))
      if (data.userModelList.some((user) => user.userId === id)) {
        setRemovedUsers((prevList) => [...prevList, id])
      }
    } else {
      setUserList((prevList) => [...prevList, id])
      setRemovedUsers((prevList) => prevList.filter((userId) => userId !== id))
    }
  }

  const addUser = async (data) => {
    const payload = {
      name: data?.groupName,
      priority: 1,
      createdBy: 1,
      updatedBy: 1,
      userModelList: userList.map((user) => {
        return {
          userId: user,
        }
      }),
    }
    try {
      setIsLoading(true)
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/create/Group`,
        data: payload,
      })
      if (res.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Group created successfully',
          showConfirmButton: false,
          timer: 1500,
        })
        handleCloseGroup()
        getGroupDetails()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  const searchWord = watch('search')

  const filteredUsers = userColumns.filter((user) => {
    const wordMatch = searchWord.toLowerCase()
    return (
      user.username.toLowerCase().includes(wordMatch) ||
      user.email.toLowerCase().includes(wordMatch)
    )
  })

  const uniqueFilteredUsers = filteredUsers?.filter((filteredItem) => {
    return (
      data?.userModelList &&
      !data.userModelList.some(
        (userModelItem) => userModelItem.userId === filteredItem.userId
      )
    )
  })

  const filteredRemovedUsers = data?.userModelList?.filter((user) => {
    const wordMatch = searchWord.toLowerCase()
    return (
      user.username.toLowerCase().includes(wordMatch) ||
      user.email.toLowerCase().includes(wordMatch)
    )
  })

  const editUser = async () => {
    const payload = {
      name: getValues('groupName'),
      groupId: data.groupId,
      priority: 1,
      createdBy: 1,
      updatedBy: 1,
      userModelList: userList.map((user) => {
        return {
          userId: user,
        }
      }),
    }

    try {
      setIsLoading(true)

      // Call editGroup API
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/group/editGroup`,
        data: payload,
      })

      if (res.status) {
        // Remove the users that were removed from the group
        await Promise.all(
          removedUsers.map((userId) => dltUserFromGroup(userId))
        )
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Group edited successfully',
          showConfirmButton: false,
          timer: 1500,
        })

        handleCloseGroup()
        getGroupDetails()
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false)
    }
  }

  const dltUserFromGroup = async (userId) => {
    try {
      setIsLoading(true)
      const res = await request({
        method: 'delete',
        url: `${config.API_URL}/api/deleteUserFromGroup/${userId}/${data.groupId}?updatedBy=1`,
      })
      if (res.status === 200) {
        console.log('deleted succesfully')
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (data.userModelList) {
      const initialUserIds = data.userModelList.map((item) => item.userId)
      setUserList(initialUserIds)
    }
    if (action === 'Create') {
      setUserList([])
    }
  }, [data.userModelList, action])

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label="Loading"
          />
        </Backdrop>
      ) : null}
      <Dialog
        open={openGroup}
        onClose={handleCloseGroup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {action === 'Edit' ? 'Edit Group' : 'Create Group'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ border: 'solid 1px #CCCC', borderRadius: '5px' }}>
            <Grid container>
              <Grid item xs={12} lg={12} md={12} m={1}>
                <Field label="Group Name" titleVariant="h7" required>
                  <CoTextField
                    placeholder="Enter Group Name"
                    name="groupName"
                    control={control}
                  />
                </Field>
                {errors.groupName && (
                  <span style={{ color: '#F00' }}>
                    {errors.groupName.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} lg={12} md={12} m={1}>
                <Field label="Search User" titleVariant="h7">
                  <CoTextField
                    placeholder="Search User"
                    name="search"
                    control={control}
                  />
                </Field>
              </Grid>
            </Grid>
            {data.userModelList && (
              <Box>
                {map(filteredRemovedUsers, (item) => {
                  return (
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        padding: '1rem 0rem 1rem 1rem',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ display: 'flex' }} gap={1.5}>
                          <Avatar {...stringAvatar(item.username)} />
                          <Box>
                            <Typography>{item.username}</Typography>
                            <Typography>{item.email}</Typography>
                          </Box>
                        </Box>
                        <Box p={1}>
                          {userList.includes(item.userId) === false ? (
                            <Styled.CampaignButton
                              startIcon={<AddIcon />}
                              sx={{
                                background: '#00BFA5',
                                color: '#FFF',
                                border: 'solid 1px ',
                                // width: '20px',
                                width: '100px',
                              }}
                              onClick={() => addUsers(item.userId)}
                            >
                              Add
                            </Styled.CampaignButton>
                          ) : (
                            <Styled.CancelButton
                              startIcon={<RemoveIcon />}
                              sx={{
                                background: '#00BFA5',
                                color: '#FFF',
                                border: 'solid 1px ',
                                // width: '20px',
                                width: '100px',
                              }}
                              onClick={() => addUsers(item.userId)}
                            >
                              Remove
                            </Styled.CancelButton>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )
                })}
                <Divider />
              </Box>
            )}
            <Grid item>
              {map(
                action === 'Edit' ? uniqueFilteredUsers : filteredUsers,
                (item) => {
                  return (
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        padding: '1rem 0rem 1rem 1rem',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ display: 'flex' }} gap={1.5}>
                          <Avatar {...stringAvatar(item.username)} />
                          <Box>
                            <Typography>{item.username}</Typography>
                            <Typography>{item.email}</Typography>
                          </Box>
                        </Box>
                        <Box p={1}>
                          {userList.includes(item.userId) === false ? (
                            <Styled.CampaignButton
                              startIcon={<AddIcon />}
                              sx={{
                                background: '#00BFA5',
                                color: '#FFF',
                                border: 'solid 1px ',
                                width: '100px',
                              }}
                              onClick={() => addUsers(item.userId)}
                            >
                              Add
                            </Styled.CampaignButton>
                          ) : (
                            <Styled.CancelButton
                              startIcon={<RemoveIcon />}
                              sx={{
                                background: '#00BFA5',
                                color: '#FFF',
                                border: 'solid 1px ',
                                width: '100px',
                              }}
                              onClick={() => addUsers(item.userId)}
                            >
                              Remove
                            </Styled.CancelButton>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Styled.CancelButton
            sx={{ padding: '5px', textTransform: 'capitalize' }}
            onClick={() => {
              handleCloseGroup()
            }}
          >
            Close
          </Styled.CancelButton>
          {action === 'Edit' ? (
            <Styled.CampaignButton
              onClick={editUser}
              sx={{ textTransform: 'capitalize' }}
              autoFocus
            >
              Update
            </Styled.CampaignButton>
          ) : (
            <Styled.CampaignButton
              onClick={handleSubmit(addUser)}
              sx={{ textTransform: 'capitalize' }}
              autoFocus
            >
              Submit
            </Styled.CampaignButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateGroup
