import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  FormHelperText,
  Tooltip,
  Avatar,
} from '@mui/material'
import React, { useState, useRef, useMemo, useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { find, map, noop } from 'lodash'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Field from '../components/Field'
import CoTextField from '../controllers/CoTextField/index'
import CoDropdown from '../controllers/CoDropdown/index.js'
import useLookup from '../components/useLookup'
import FileUpload from '../components/FileUpload.js'
import request from '../components/Helper/Request.js'
import { config } from '../config/config.js'
import Swal from 'sweetalert2'
import SpinningLoader from '../components/SpinningLoader.js'
import appConfig from '../config/appConfig.js'
import brandLogo from '../assets/brand-logo.svg'
import CloseIcon from '@mui/icons-material/Close'
import DOMPurify from 'dompurify'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import UploadFiles from './UploadFiles.jsx'
import ManualEntry from './ManualEntry.jsx'
import TabularView from '../components/TabularView/index.js'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs from 'dayjs'
import axios from 'axios'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

TabPanel.defaultProps = {
  children: null,
}

const imageHTML = '<strong>hello</strong>'
const blocksFromHTML = convertFromHTML(imageHTML)
const initialContentState = ContentState.createFromBlockArray(blocksFromHTML)

const Template = ({
  setCurrentStep,
  campaignId,
  setChannel,
  setTemplateId,
  setTemplateRes,
  setParams,
  templateId,
  setTemplateName,
  setDisableCampaign,
  disableTemplate,
  setTemplateCode,
}) => {
  // const [headerState, setHeaderState] = useState(
  //   EditorState.createWithContent(initialContentState)
  // );
  // const [headerState, setHeaderState] = useState(EditorState.createEmpty());
  const [activeTab, setActiveTab] = useState(0)
  const [activeFileTab, setActiveFileTab] = useState(0)
  const [fileDocuments, setFileDocuments] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [entries, setEntries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [editIndex, setEditIndex] = useState(-1)

  const [editorStates, setEditorStates] = useState({})
  const fileInputRef = useRef(null)

  const [contentState, setContentState] = useState(EditorState.createEmpty())
  const [headerState, setHeaderState] = useState(EditorState.createEmpty())
  const [footerState, setFooterState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          '<p><span style="font-size: 12px">This message (including any attachments) contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you should delete this message and any disclosure, copying, or distribution of this message, or the taking of any action based on it, by you is strictly prohibited.</span></p>'
        )
      )
    )
  )
  const editorRef = useRef()
  const handleClose = () => setOpen(false)
  const descriptionElementRef = useRef('')

  useEffect(() => {
    setHeaderState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`<h3 style='color:red';>KNotify</h3>`)
        )
      )
    )
  }, [])

  const { getOptions, lkpFlatten } = useLookup('creatercampaign')

  const onTabChange = (e, value) => {
    setActiveTab(value)
  }

  const onFileTabChange = (e, value) => {
    setActiveFileTab(value)
  }

  // const onContentStateChange = (state) => {
  //   setContentState(state);
  // };

  const onHeaderStateChange = (state) => {
    setHeaderState(state)
  }

  const onFooterStateChange = (state) => {
    setFooterState(state)
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required('Enter Template Name'),
    channel: yup.string().required('Select Channel'),
  })

  const getContent = useMemo(() => {
    if (Object.keys(editorStates).length) {
      return draftToHtml(convertToRaw(editorStates['en']?.getCurrentContent()))
    }
  }, [editorStates])

  const paramsPattern = /[^{}]+(?=})/g
  const contentParam = getContent?.match(paramsPattern)

  const contentParameters = map(contentParam, (p) => ({ key: p, value: '' }))
  const params = [...contentParameters]
  console.log(params, 555666)

  const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

  const {
    watch,
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      header: '',
      channel: 'Email',
      campaignPriority: '',
      campaignId: campaignId,
      file: null,
      languages: ['en'],
      email: '',
      parameters: params,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameters',
  })
  useEffect(() => {
    setValue('parameters', params)
  }, [activeFileTab])

  const columns = [
    // { headerName: 'Channel', field: 'channel' },
    // {
    //   headerName: 'Template Code',
    //   field: 'code',
    // },
    {
      headerName: 'Email/SMS',
      field: 'email',
    },

    {
      headerName: 'Parameters',
      renderCell({ parameters }, i) {
        const getFirstTwo = (parameters) => {
          let slice = parameters.slice(0, 2)
          return map(
            slice,
            ({ key = '', value = '' }) => `${key}: ${value}`
          )?.join(', ')
        }
        const title = map(
          parameters,
          ({ key = '', value = '' }) => `${key}: ${value}`
        )?.join(', ')
        return (
          <Tooltip title={parameters.length === 2 ? '' : title}>
            <span>
              {parameters.length === 2
                ? title
                : `${getFirstTwo(parameters)}...`}
            </span>
          </Tooltip>
        )
        // return `${params.parameters[i]?.key} ${params.parameters[i]?.value}`;
      },
    },
    // {
    //   headerName: 'Action',
    //   renderCell(params, i) {
    //     return (
    //       <Box>
    //         <IconButton onClick={() => enEditEntry(i)}>
    //           <EditIcon />
    //         </IconButton>
    //         <IconButton onClick={() => onDeleteEntry(i)}>
    //           <DeleteIcon />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ]

  const parameterColumns = [
    {
      headerName: 'Key',
      renderCell({ id }, i) {
        const keyError = errors?.parameters?.[i]?.key?.message
        return (
          <Stack sx={{ height: '62px' }}>
            <CoTextField
              key={id}
              size="small"
              control={control}
              name={`parameters.[${i}].key`}
            />
            {keyError && <FormHelperText error>{keyError}</FormHelperText>}
          </Stack>
        )
      },
    },
    {
      headerName: 'Value',
      renderCell({ id }, i) {
        const valueError = errors?.parameters?.[i]?.value?.message
        return (
          <Stack sx={{ height: '62px' }}>
            <CoTextField
              key={id}
              size="small"
              control={control}
              name={`parameters.[${i}].value`}
            />
            {valueError && <FormHelperText error>{valueError}</FormHelperText>}
          </Stack>
        )
      },
    },
    // {
    //   headerName: 'Action',
    //   renderCell({ id }, i) {
    //     return (
    //       <IconButton onClick={() => remove(id)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ]

  const getLanguage = watch('languages', [])

  // const getLanguage = useMemo(() => {
  //   return watch('languages', []);
  // }, [watch('languages')]);

  useEffect(() => {
    const initialEditorStates = {}
    getLanguage.forEach((lang) => {
      initialEditorStates[lang] = EditorState.createEmpty()
    })
    setEditorStates(initialEditorStates)
  }, [getLanguage])

  const onContentStateChange = (state, language) => {
    setEditorStates((prevStates) => ({
      ...prevStates,
      [language]: state,
    }))
  }

  const templateName = getValues('name')
  setTemplateName(templateName)

  const uploadFileCallback = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        resolve({ data: { link: reader.result } })
      })
    })
  }

  const getTemplateDetails = async () => {
    const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

    try {
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/templates/getById/${userDetails?.userId}/${templateId}`,
      })
      if (res.status === 1) {
        const enTemplate = find(res.templateDetails, { language: 'en' })
        const rawData = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(enTemplate?.content)
          )
        )
        reset({
          name: res?.name,
          channel: res?.channelName,
          campaignPriority: res?.campaignPriority,
        })
        setContentState(rawData)
      } else {
        Swal.fire({
          icon: 'warning',
          button: false,
          showConfirmButton: false,
          timer: 3000,
          text: 'Failed to fetch the details',
        })
      }
    } catch (error) {
    } finally {
    }
  }

  const fetchEmailConfiguration = async () => {
    const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

    try {
      const res = await request({
        method: 'get',
        url: `${config.API_URL}/api/settings/email/${userDetails?.accountId}/${userDetails?.userId}/en`,
      })
    } catch {
    } finally {
    }
  }

  useEffect(() => {
    if (templateId) {
      getTemplateDetails()
    }
    fetchEmailConfiguration()
  }, [])

  const headerHtml = useMemo(() => {
    return draftToHtml(convertToRaw(headerState.getCurrentContent()))
  }, [headerState])

  const contentHtml = useMemo(() => {
    return draftToHtml(convertToRaw(contentState.getCurrentContent()))
  }, [contentState])

  const footerHtml = useMemo(() => {
    return draftToHtml(convertToRaw(footerState.getCurrentContent()))
  }, [footerState])

  const convertAllToHtml = () => {
    const htmlContents = {}
    for (const [language, editorState] of Object.entries(editorStates)) {
      const rawContent = convertToRaw(editorState.getCurrentContent())
      htmlContents[language] = draftToHtml(rawContent)
    }
    return htmlContents
  }

  const htmlContents = convertAllToHtml()
  const translateText = async (lang) => {
    const htmlContents = convertAllToHtml()

    const payload = {
      text: htmlContents['en'],
      sourceLanguageCode: 'auto',
      targetLanguageCode: lang,
    }

    try {
      const response = await axios.post(
        'http://182.73.228.218:8077/language/translate',
        payload
      )
      console.log('translateText', response.data)

      const contentState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(response.data.translatedText)
        )
      )

      // const translatedText = response.data.translatedText;
      // const contentBlock = htmlToDraft(translatedText);
      // const contentState = ContentState.createFromBlockArray(
      //   contentBlock.contentBlocks
      // );
      // const newEditorState = EditorState.createWithContent(contentState);

      setEditorStates((prevStates) => ({
        ...prevStates,
        [lang]: contentState,
      }))
    } catch (error) {
      console.error(error)
    }
  }

  const handleFileChange = (file) => {
    setSelectedFile(file)
    // uploadFile(file);
  }

  const createTemplate = async (values) => {
    const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))

    const data = {
      ...values,
      userId: userDetails?.userId,
      accountId: userDetails?.accountId,
      createdBy: userDetails?.emailId,
      modifiedBy: userDetails?.emailId,
    }

    const htmlContents = convertAllToHtml()
    const templateDetails = Object.keys(htmlContents).map((key) => ({
      subject: '',
      attachment: '',
      content: htmlContents[key],
      language: key,
    }))

    const payload = {
      ...data,
      templateDetails: templateDetails,
    }
    setIsLoading(true)

    // const getContent = draftToHtml(
    //   convertToRaw(contentState.getCurrentContent())
    // );
    // const getHeader = draftToHtml(
    //   convertToRaw(headerState.getCurrentContent())
    // );
    // const getFooter = draftToHtml(
    //   convertToRaw(footerState.getCurrentContent())
    // );
    // const paramsPattern = /[^{}]+(?=})/g;
    // const contentParam = getContent.match(paramsPattern);
    // const headerParam = getHeader.match(paramsPattern);
    // const footerParam = getFooter.match(paramsPattern);

    // const contentParameters = map(contentParam, (p) => ({ key: p, value: '' }));
    // const headerParameters = map(headerParam, (p) => ({ key: p, value: '' }));
    // const footerParameters = map(footerParam, (p) => ({ key: p, value: '' }));

    // const parameters = [
    //   ...contentParameters,
    //   ...headerParameters,
    //   ...footerParameters,
    // ];

    // setParams(parameters);

    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/templates/create/${userDetails?.userId}`,
        data: payload,
      })
      setTemplateId(res?.data?.templateDetailsId)
      setChannel(res?.data?.channel)
      setTemplateRes(res?.data)
      setTemplateCode(res?.data?.code)
      if (res.status === 1) {
        const fileResponse = await handleFileUpload(res.data)
        if (fileResponse === 'success') {
          if (selectedFile) {
            await uploadFile(
              selectedFile,
              res?.data?.templateDetailsId,
              res?.data?.channel
            )
          } else {
            await onSaveEntries(
              res?.data?.code,
              res?.data?.channel,
              res?.data?.templateDetailsId
            )
          }
          setCurrentStep(3)
          setIsLoading(false)
          Swal.fire({
            icon: 'success',
            button: false,
            showConfirmButton: false,
            timer: 1000,
            text: 'Template added Successfully',
          })
        }
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'warning',
          button: false,
          showConfirmButton: false,
          timer: 3000,
          text: res.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async (values) => {
    if (Object.keys(dirtyFields).length > 0) {
      createTemplate(values)
    } else {
      setCurrentStep(3)
    }
  }

  const handleBack = () => {
    setCurrentStep(1)
    setDisableCampaign(true)
  }

  const { communicationChannel, campaignPriority, languages } = useMemo(() => {
    return {
      communicationChannel: getOptions('CommunicationChannel'),
      campaignPriority: getOptions('Campaign Priority'),
      languages: getOptions('Language'),
    }
  }, [getOptions])

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    }
  }

  const uploadFile = async (data, templateId, channel) => {
    const userDetails = JSON.parse(window.sessionStorage.getItem('userInfo'))
    const payload = new FormData()
    payload.append('channelName', channel.toLowerCase())
    payload.append('uploadFiles', data)
    payload.append('accountId', userDetails?.accountId)
    payload.append('createdBy', userDetails?.emailId)
    payload.append('templateDetailId', templateId)
    // payload.append('userId', '139')
    setIsLoading(true)
    try {
      const res = await request({
        method: 'post',
        url: `${config.API_URL}/api/file/upload/save`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: payload,
      })

      if (res.status === 200) {
        setIsLoading(false)
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 1000,
          text: 'Message Sent Successfully',
        })
        // setCurrentStep(3)
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'warning',
          button: false,
          showConfirmButton: false,
          timer: 3000,
          text: res.message,
        })
        return 'failure'
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  // const fileSubmit = () => {
  //   uploadFile(selectedFile)
  // }

  // const onSubmitManualEntry = (values) => {
  //   if (editIndex === -1) {
  //     setEntries([...entries, values]);
  //   } else {
  //     setEditIndex(-1);
  //     setEntries(
  //       Object.assign([], entries, {
  //         [editIndex]: values,
  //       })
  //     );
  //   }
  //   reset({
  //     channel: channel,
  //     code: templateName,
  //     email: '',
  //     parameters: params,
  //   });
  // };

  const onSaveEntries = async (templateCode, channelName, templateId) => {
    try {
      const payload = map(entries, (entry) => {
        const parameters = map(
          entry.parameters,
          ({ key, value }) => `"${key}": "${value}"`
        )?.join(',')
        return {
          channel: entry.channel,
          emailId: entry.email,
          // code: entry.code,
          code: templateCode,
          language: 'en',
          parameters: parameters ? `{${parameters}}` : undefined,
          campaignId,
        }
      })
      setIsLoading(true)
      const res = await request({
        method: 'post',
        url: `${
          config.API_URL
        }/api/file/upload/param?channelName=${channelName.toLowerCase()}&accountId=${
          userDetails?.accountId
        }&createdBy=${userDetails?.emailId}&templateDetailId=${templateId}`,
        data: payload,
      })
      if (res.status === 1) {
        setIsLoading(false)
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 1000,
          text: 'Message Sent Successfully',
        })
        // setCurrentStep(3)
      } else {
        setIsLoading(false)
        Swal.fire({
          icon: 'warning',
          button: false,
          showConfirmButton: false,
          timer: 3000,
          text: res.message,
        })
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onEntry = (values) => {
    if (editIndex === -1) {
      setEntries([...entries, values])
    } else {
      setEditIndex(-1)
      setEntries(
        Object.assign([], entries, {
          [editIndex]: values,
        })
      )
    }
    // reset({
    //   // channel: channel,
    //   code: templateName,
    //   email: '',
    //   parameters: params,
    // });
  }

  // const filesUpload = (filePayload) => {
  //   const formData = new FormData();
  //   Object.keys(filePayload).map((key) => {
  //     formData.append(key, filePayload[key]);
  //   });

  //   return formData;
  // };

  // const filesUpload = (fileDocuments, templateId) => {
  //   const formData = new FormData();
  //   for (const fileDocument of fileDocuments) {
  //     formData.append('docCat', 'knotify');
  //     formData.append('docType', 'file_attachement');
  //     formData.append('sourceSystem', 'kNotify');
  //     formData.append('fileName', fileDocument.name);
  //     formData.append('files', fileDocument);
  //     formData.append('fileLocation', '');
  //     formData.append('createDt', dayjs().format('YYYY/MM/DD'));
  //     formData.append('role', '');
  //     formData.append('createUserId', '');
  //     formData.append('sourceCaseId', templateId);
  //   }
  //   return formData;
  // };

  const filesUpload = (fileDocuments, templateId) => {
    const formData = new FormData()
    for (const fileDocument of fileDocuments) {
      formData.append('files', fileDocument)
    }
    formData.append('docCat', 'knotify')
    formData.append('docType', 'file_attachement')
    formData.append('sourceSystem', 'kNotify')
    formData.append('fileName', '')
    formData.append('fileLocation', '')
    formData.append('createDt', dayjs().format('YYYY/MM/DD'))
    formData.append('role', '')
    formData.append('createUserId', '')
    formData.append('sourceCaseId', templateId)
    return formData
  }

  const supportedTypes = ['application/pdf', 'image/png', 'image/jpeg']

  const handleFileInput = async (e) => {
    const file = e.target.files[0]
    if (file && supportedTypes.includes(file.type)) {
      setFileDocuments([...fileDocuments, file])
      setFileNames([...fileNames, file.name])
    } else {
      // console.error('Unsupported file type');
      Swal.fire({
        icon: 'warning',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: 'Unsupported file type!',
      })
    }
  }

  const handleFileUpload = async (data) => {
    if (fileDocuments.length === 0) {
      return 'success'
    }
    let formData = filesUpload(fileDocuments, data?.templateDetailsId)
    try {
      const res = await axios({
        method: 'post',
        url: 'http://182.73.228.218:8077/docControl/documentDetails/upload',
        // url: `${config.FILE_URL}/docControl/documentDetails/upload`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          button: false,
          showConfirmButton: false,
          timer: 2500,
          text: 'File Uploaded Successfully!',
        })
        return 'success'
      } else {
        Swal.fire({
          icon: 'error',
          button: false,
          showConfirmButton: false,
          timer: 2000,
          text: 'File Uploading failed!',
        })
        return 'failure'
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        button: false,
        showConfirmButton: false,
        timer: 2000,
        text: 'File Uploading failed!',
      })
      return 'failure'
    } finally {
    }
  }

  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const handleClick = () => {
    console.info('You clicked the Chip.')
  }

  const handleDelete = (indexToDelete) => {
    const fileNameArr = [...fileNames]
    fileNameArr.splice(indexToDelete, 1)
    setFileNames(fileNameArr)

    const fileDocArr = [...fileDocuments]
    fileDocArr.splice(indexToDelete, 1)
    setFileDocuments(fileDocArr)

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const CustomPDFButton = () => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <div onClick={openFileDialog} className="rdw-option-wrapper">
        <IconButton>
          <FileUploadIcon />
        </IconButton>
      </div>
      {/* {fileUploaded && (
        <Chip
          avatar={
            <Avatar
              sx={{
                color: 'white !important',
                backgroundColor: '#2e7d32 !important',
                fontSize: '5px',
                width: '21px !important',
                height: '21px !important',
              }}
            >
              ✔
            </Avatar>
          }
          label={fileName}
          variant="outlined"
          color="success"
          // onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={<DeleteIcon sx={{ color: '#FF4433 !important' }} />}
        />
      )} */}
      {fileNames?.map((file, index) => (
        <Chip
          key={index}
          avatar={
            <Avatar
              sx={{
                color: 'white !important',
                backgroundColor: '#2e7d32 !important',
                fontSize: '5px',
                width: '21px !important',
                height: '21px !important',
              }}
            >
              ✔
            </Avatar>
          }
          label={file}
          variant="outlined"
          color="success"
          // onClick={handleClick}
          onDelete={() => handleDelete(index)}
          deleteIcon={<DeleteIcon sx={{ color: '#FF4433 !important' }} />}
        />
      ))}
    </div>
  )

  return (
    <div>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <SpinningLoader
            circleColor={appConfig.colors.white}
            label={'Creating Template....'}
          />
        </Backdrop>
      )}
      <Tabs
        onChange={onTabChange}
        value={activeTab}
        // variant="fullWidth"
        variant="scrollable"
        centered
      >
        <Tab label="New Template" style={{ textTransform: 'capitalize' }} />
        <Tab
          label="Existing Template"
          style={{ textTransform: 'capitalize' }}
        />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={12} spacing={2} sx={{ paddingTop: '1rem' }}>
            <Grid item xs={12} lg={4}>
              <Field
                label="Template Name"
                titleVariant="h7"
                required
                error={errors?.name?.message}
                showError={errors?.name?.message}
              >
                <CoTextField
                  name="name"
                  control={control}
                  placeholder="Enter Template Name"
                  disabled={disableTemplate}
                />
              </Field>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                label="Communication Channel"
                titleVariant="h7"
                error={errors?.channel?.message}
                showError={errors?.channel?.message}
                required
              >
                <CoDropdown
                  name="channel"
                  options={communicationChannel}
                  control={control}
                  disabled={disableTemplate}
                />
              </Field>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Field label="Campaign Priority" titleVariant="h7">
                <CoDropdown
                  name="campaignPriority"
                  options={campaignPriority}
                  control={control}
                  disabled={disableTemplate}
                />
              </Field>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field label="Choose Language"></Field>
              {/* <Select
                multiple
                value={language}
                onChange={handleChange}
                input={<OutlinedInput />}
                fullWidth
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select> */}
              <Controller
                name="languages"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="languages-label"
                    id="languages"
                    multiple
                    fullWidth
                    value={field.value}
                    onChange={field.onChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              languages.find((lang) => lang.value === value)
                                ?.label
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {languages?.map((lg) => (
                      <MenuItem
                        key={lg?.value}
                        value={lg?.value}
                        disabled={lg?.value === 'en'}
                      >
                        {lg?.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            startIcon={<VisibilityOutlinedIcon />}
            sx={{ float: 'right', mt: '1rem' }}
          >
            Preview
          </Button>

          <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            scroll="paper"
            onClose={handleClose}
            aria-labelledby="document"
            aria-describedby="documentDescription"
            sx={{ paddingBottom: '50px' }}
          >
            <DialogTitle id="document">Preview</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute !important',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText
                id="documentDescription"
                ref={descriptionElementRef}
                tabIndex={-1}
                sx={{ border: '1px solid black', padding: '2rem' }}
              >
                <div
                  style={{
                    fontWeight: '900',
                    color: 'grey',
                  }}
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(headerHtml)}
                ></div>
                <div
                  style={{ fontSize: '18px' }}
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(contentHtml)}
                ></div>
                <div
                  style={{
                    marginTop: '2.5rem',
                    fontSize: '12px',
                    backgroundColor: '#6C6C6C',
                    color: 'white',
                    padding: '.8rem',
                  }}
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(footerHtml)}
                ></div>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <Grid container xs={12} mt={2} spacing={2}>
            <Grid item xs={6}>
              {getLanguage.map((item, index) => {
                return (
                  <Box mb={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <Field
                        label={lkpFlatten?.Language?.[item]}
                        titleVariant="h7"
                      />
                      {item !== 'en' && (
                        <Button
                          variant="text"
                          onClick={() => translateText(item)}
                        >
                          Translate
                        </Button>
                      )}
                    </Stack>
                    <Editor
                      // editorState={contentState}
                      editorState={editorStates[item]}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(state) => {
                        onContentStateChange(state, item)
                      }}
                      toolbarCustomButtons={
                        item === 'en' && [<CustomPDFButton />]
                      }
                      toolbar={{
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          previewImage: true,
                          inputAccept:
                            'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                          uploadCallback: uploadFileCallback,
                        },
                        fontFamily: {
                          options: [
                            'Arial',
                            'Georgia',
                            'Impact',
                            'Tahoma',
                            'Times New Roman',
                            'Verdana',
                            'Source Sans Pro',
                          ],
                        },
                      }}
                      toolbarStyle={{
                        border: '1px solid #ccc',
                        paddingRight: '10%',
                      }}
                      editorStyle={{
                        border: '1px solid #ccc',
                        height: '250px',
                        padding: '15px',
                      }}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileInput}
                      style={{ display: 'none' }}
                      accept="application/pdf,image/png,image/jpeg"
                    />
                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={6}>
              {/* <UploadFiles params={parameters} /> */}
              <Tabs
                onChange={onFileTabChange}
                value={activeFileTab}
                variant="scrollable"
                centered
              >
                <Tab
                  label="File Upload"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Create your File"
                  style={{ textTransform: 'capitalize' }}
                />
              </Tabs>
              <TabPanel value={activeFileTab} index={0}>
                <Grid item xs={12} sm={12} md={12}>
                  {/* <Field label="File Upload" titleVariant="h7" /> */}
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <FileUpload
                        onChange={(e) => {
                          field.onChange(e)
                          handleFileChange(e)
                        }}
                        selectedFile={field.value}
                      />
                    )}
                  />

                  {/* <Grid item xs={12} mt={1}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Button variant="outlined" onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={fileSubmit}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid> */}
                </Grid>
              </TabPanel>

              <TabPanel value={activeFileTab} index={1}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    sx={{ paddingTop: '1rem' }}
                  >
                    {/* <Grid item xs={4}>
            <Field
              label="Template Name"
              titleVariant="h7"
              required
              error={errors?.name?.message}
              showError={errors?.name?.message}
            >
              <CoTextField
                name="name"
                control={control}
                placeholder="Enter Template Name"
              />
            </Field>
          </Grid> */}

                    {/* <Grid item xs={4}>
            <Field
              label="Channel"
              titleVariant="h7"
              error={errors?.channel?.message}
              showError={errors?.channel?.message}
              required
            >
              <CoTextField
                name="channel"
                control={control}
                placeholder="Select Channel"
                disabled
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              label="Template Name"
              titleVariant="h7"
              required
              error={errors?.code?.message}
              showError={errors?.code?.message}
            >
              <CoTextField
                name="code"
                control={control}
                placeholder="Enter Template Name"
                disabled
              />
            </Field>
          </Grid> */}

                    <Grid item xs={12}>
                      <Field
                        label="Email"
                        titleVariant="h7"
                        required
                        error={errors?.email?.message}
                        showError={errors?.email?.message}
                      >
                        <CoTextField
                          name="email"
                          // options={campaignPriority}
                          placeholder="Enter Email"
                          control={control}
                        />
                      </Field>
                    </Grid>

                    {/* {!isEmail && (
              <Grid item xs={12}>
                <Field
                  label="Mobile Number"
                  titleVariant="h7"
                  required
                  error={errors?.email?.message}
                  showError={errors?.email?.message}
                >
                  <CoTextField
                    name="email"
                    // options={campaignPriority}
                    placeholder="Enter Mobile Number"
                    control={control}
                  />
                </Field>
              </Grid>
            )} */}

                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Typography variant="subtitle1">Parameters</Typography>
                        {/* <Button
                size="small"
                onClick={() => append({ key: '', value: '' })}
                sx={{ ml: 'auto', mb: '0.5rem' }}
                variant="outlined"
              >
                Add +
              </Button> */}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} sm={12} lg={12} xl={6}>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                          // marginLeft: '-16px',
                        }}
                      >
                        <TabularView
                          columns={parameterColumns}
                          data={fields}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12} xl={6}>
                      <Box
                        sx={{
                          border: '1px solid #c2c2b7',
                          borderRadius: '10px',
                          display: 'inline-block',
                          width: '100%',
                          // marginLeft: '-16px',
                        }}
                      >
                        <TabularView
                          columns={columns}
                          data={entries}
                          renderHeader={(title) => <strong>{title}</strong>}
                          sx={{
                            table: {
                              width: '100%',
                            },
                            th: {
                              borderBottom: '2px solid black',
                            },
                            td: {
                              border: 0,
                              padding: '5px 12px',
                            },
                            tr: {
                              borderBottom: '1px solid #c2c2b7',
                              padding: '10px 6px',
                              ':nth-child(even)': {
                                // backgroundColor: 'red',
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      display: 'flex',
                      marginTop: {
                        md: '1rem',
                      },
                    }}
                  >
                    <Button
                      sx={{ ml: 'auto', mb: '0.5rem' }}
                      variant="outlined"
                      onClick={handleSubmit(onEntry)}
                    >
                      {editIndex === -1 ? 'Add Entry' : 'Update Entry'}
                    </Button>
                  </Grid>
                </form>
              </TabPanel>
            </Grid>
          </Grid>
        </form>
        <Grid
          mt={5}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          gap={1}
        >
          <Button
            // disabled={campaignId !== -1}
            variant="outlined"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            Next
          </Button>
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}></TabPanel>
    </div>
  )
}

Template.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func,
}

Template.defaultProps = {
  setCurrentStep: noop,
}

export default Template
